import { Timeline, Text } from "@mantine/core";
import { api } from "@/lib/api-client";
import { useLingui } from "@lingui/react";
import {
  generateLogTitle,
  generateDescription,
  generateAdditionalInfo,
} from "./logFormatters";

const AppointmentLog = ({ appointmentId }: { appointmentId: number }) => {
  const { data } = api.appointments.useAppointmentLog(appointmentId);

  return (
    <div>
      <Timeline bulletSize={12} lineWidth={2} mt="md">
        {data?.logs.map((log, index) => (
          <Timeline.Item
            title={generateLogTitle(log)}
            __active={index === 0}
            lineVariant={index === 0 ? "dashed" : "solid"}
          >
            <Text size="sm" mt={8}>
              {generateDescription(log)}
            </Text>
            <Text size="xs" mt={4} c="dimmed">
              {generateAdditionalInfo(log)}
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

export default AppointmentLog;
