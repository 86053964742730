import { t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Divider, Flex, Popover, Text, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import { RiCalendarTodoLine, RiTimeLine } from "react-icons/ri";
import { GetBreakColor, GetBreakIcon } from "../Utils";
import { ShiftBlockContent } from "./DayWeekComponents/UserRows";

const ExistingShiftBlock = ({ scheduleType, shift }) => {
  useLingui();
  const [
    breakDetailsOpened,
    { close: closeBreakDetails, open: openBreakDetails },
  ] = useDisclosure(false);

  let fromTime = dayjs(shift.start).format("HH:mm");
  let toTime = dayjs(shift.end).format("HH:mm");
  let icon = <RiTimeLine />;
  if (scheduleType === "online") {
    fromTime = shift.formStart ? dayjs(shift.formStart).format("HH:mm") : "";
    toTime = shift.formEnd ? dayjs(shift.formEnd).format("HH:mm") : "";
    icon = <RiCalendarTodoLine />;
  }

  const breaks = shift.breaks;

  let text = `${fromTime} - ${toTime}`;
  if (fromTime === "" && toTime === "") {
    text = t`Ni naročanja`;
  }

  return (
    <ShiftBlockContent>
      {icon}
      <Text inline span style={{ whiteSpace: "pre-wrap" }}>
        {text}
      </Text>

      {breaks != null && breaks.length > 0 && (
        <Popover
          position={"right-start"}
          width={"200px"}
          shadow={"md"}
          opened={breakDetailsOpened}
        >
          <Popover.Target>
            <UnstyledButton
              style={{ color: GetBreakColor(breaks?.at(0)?.name) }}
              onMouseEnter={openBreakDetails}
              onMouseLeave={closeBreakDetails}
              className={"break-icon"}
            >
              {GetBreakIcon(breaks?.at(0)?.name)}
            </UnstyledButton>
          </Popover.Target>
          <Popover.Dropdown>
            <Flex direction={"column"}>
              {breaks?.map((breakItem) => (
                <Flex
                  key={breakItem.id}
                  direction={"row"}
                  style={{
                    padding: "15px 15px",
                    borderBottom: "1px solid #EAEBED",
                    color: GetBreakColor(breakItem.name),
                  }}
                  gap={"10px"}
                  align={"center"}
                >
                  {GetBreakIcon(breakItem.name)}
                  <Divider orientation={"vertical"} color={"#424b64"} />
                  <Text color={"#424B64"} size={"sm"}>
                    {`${dayjs(breakItem.from).format("HH:mm")} - ${dayjs(
                      breakItem.to,
                    ).format("HH:mm")}`}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Popover.Dropdown>
        </Popover>
      )}
    </ShiftBlockContent>
  );
};

export default ExistingShiftBlock;
