import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { GetOrganizationBusinessPremise } from "@/server-types";
import { cn } from "@/utils";
import { Edit, EllipsisVertical, MapPin, Trash } from "lucide-react";
import {
  Button,
  Card,
  CardBody,
  CircularProgress,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { api } from "@/lib/api-client";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export const BusinessPremiseCard = ({
  businessPremise,
  handleEditBusinessPremise,
}: {
  businessPremise: GetOrganizationBusinessPremise["response"][number];
  handleEditBusinessPremise: () => void;
}) => {
  const {
    mutateAsync: deleteBusinessPremise,
    isPending: isDeletingBusinessPremise,
  } = api.taxRegister.useDeleteBusinessPremise();

  const fullAddress = `${businessPremise.street ?? ""} ${businessPremise.houseNumber ?? ""} ${businessPremise.houseNumberAdditional ?? ""}, ${businessPremise.postalCode ?? ""} ${businessPremise.community ?? ""}`;

  const iconClasses =
    "text-xl text-default-500 pointer-events-none flex-shrink-0";

  return (
    <div className="relative">
      {isDeletingBusinessPremise && (
        <CircularProgress className="absolute left-2/4 top-2/4 -translate-x-1/2 -translate-y-1/2 transform" />
      )}

      <Card
        className="mb-4 w-full"
        shadow="sm"
        isDisabled={isDeletingBusinessPremise}
      >
        <CardBody>
          <div className="flex w-full justify-between">
            <div className="flex items-center justify-center gap-6">
              <p className="font-semibold text-gray-400">
                {businessPremise.businessPremiseId}
              </p>

              {businessPremise.locationLabel != null && (
                <div className="flex items-center gap-1 text-sm text-gray-500">
                  <MapPin size={"1rem"} />
                  <p>{businessPremise.locationLabel}</p>
                </div>
              )}

              {businessPremise.locationLabel == null && (
                <LimeAlert
                  color="danger"
                  isCompact
                  message={t`Prostor nima vezane lokacije`}
                />
              )}
            </div>

            <Dropdown isDisabled={isDeletingBusinessPremise}>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <EllipsisVertical />
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label={t`Akcije za poslovni prostor`}
                variant="faded"
                onAction={async (key) => {
                  switch (key) {
                    case "edit":
                      handleEditBusinessPremise();
                      break;
                    case "delete":
                      await deleteBusinessPremise({
                        businessPremiseId: businessPremise.id,
                      });
                      break;
                    default:
                      break;
                  }
                }}
              >
                <DropdownItem
                  key={"edit"}
                  startContent={<Edit className={iconClasses} />}
                >
                  <Trans>Uredi</Trans>
                </DropdownItem>
                <DropdownItem
                  key={"delete"}
                  color="danger"
                  startContent={
                    <Trash className={cn(iconClasses, "text-danger")} />
                  }
                >
                  <Trans>Izbriši</Trans>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          {fullAddress.trim().length > 1 && (
            <p className="mb-4 font-semibold">{fullAddress}</p>
          )}
          <div className="text-xs">
            <div className="flex gap-4">
              <p>VRSTA</p>
              <p>
                <Trans>
                  {businessPremise.typeLabel} {businessPremise.premiseType}
                </Trans>
              </p>
            </div>

            <div className="flex gap-4">
              <p>KATASTERSKA</p>
              <p>
                <Trans>{businessPremise.cadastralNumber}</Trans>
              </p>
            </div>

            <div className="flex gap-4">
              <p>STAVBA</p>
              <p>
                <Trans>{businessPremise.buildingNumber}</Trans>
              </p>
            </div>

            <div className="flex gap-4">
              <p>DEL STAVBE</p>
              <p>
                <Trans>{businessPremise.buildingSectionNumber}</Trans>
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
