import { LimeSelect } from "@/Components/LimeSelect";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { DetailField } from "@/server-types";
import { SUPPORTED_LANGUAGES } from "@/stores/usePreferredLanguageStore";
import { TextVariant } from "@/types/text-variants";
import { getLocalizedName } from "@/utils";
import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  NumberInput,
  Text,
  TextInput,
} from "@mantine/core";
import { isNaN } from "lodash";
import { useState } from "react";
import { BiChevronDown, BiChevronUp, BiTrash } from "react-icons/bi";
import { LuTrash2 } from "react-icons/lu";
import { RiAddCircleFill } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";

export type CustomFieldsProps = {
  details: DetailField[];
  setDetails: (details: DetailField[]) => void;
};

export const CustomFields = ({ details, setDetails }: CustomFieldsProps) => {
  const defaultDetailIds = ["name", "lastName", "email", "gsm"];
  const extraDetails = details.filter(
    (detail) => !defaultDetailIds.includes(detail.id),
  );

  const nameDetail = details.find((detail) => detail.id === "name")!;
  const lastNameDetail = details.find((detail) => detail.id === "lastName")!;
  const emailDetail = details.find((detail) => detail.id === "email")!;
  const gsmDetail = details.find((detail) => detail.id === "gsm")!;

  const handleUpdateDetail = (newDetail: DetailField) => {
    const existingDetailKey = Object.entries(details).find(
      ([key, value]) => value.id === newDetail.id,
    )?.[0];

    if (existingDetailKey) {
      setDetails(
        details.map((detail, index) => {
          if (index.toString() === existingDetailKey) {
            return newDetail;
          }
          return detail;
        }),
      );
    } else {
      setDetails([...details, newDetail]);
    }
  };

  const handleRemoveDetail = (detail: DetailField) => {
    setDetails(details.filter((d) => d.id !== detail.id));
  };

  return (
    <>
      <Flex direction={"column"} gap={"xs"} mt={"md"}>
        <Text variant={TextVariant.Subheading}>Basic Info</Text>
        <BuildCustomField
          detail={nameDetail}
          handleUpdateDetail={handleUpdateDetail}
          isDefaultField
        />
        <BuildCustomField
          detail={lastNameDetail}
          handleUpdateDetail={handleUpdateDetail}
          isDefaultField
        />
        <BuildCustomField
          detail={emailDetail}
          handleUpdateDetail={handleUpdateDetail}
          isDefaultField
        />
        <BuildCustomField
          detail={gsmDetail}
          handleUpdateDetail={handleUpdateDetail}
          isDefaultField
        />

        <Text variant={TextVariant.Subheading}>Custom fields</Text>
        {extraDetails.map((detail) => {
          return (
            <BuildCustomField
              key={detail.id}
              detail={detail}
              handleUpdateDetail={handleUpdateDetail}
              handleRemoveDetail={() => handleRemoveDetail(detail)}
            />
          );
        })}

        <Flex justify={"center"} my={"lg"}>
          <ActionIcon
            onClick={() => {
              handleUpdateDetail({
                id: uuidv4(),
                label: {},
                type: "text",
                required: false,
              });
            }}
          >
            <RiAddCircleFill size={"24px"} />
          </ActionIcon>
        </Flex>
      </Flex>
    </>
  );
};

const BuildCustomField = ({
  detail,
  isDefaultField,
  handleUpdateDetail,
  handleRemoveDetail,
}: {
  detail: CustomFieldsProps["details"][number];
  isDefaultField?: boolean;
  handleUpdateDetail: (newDetail: DetailField) => void;
  handleRemoveDetail?: () => void;
}) => {
  const [labelsShown, setLabelsShown] = useState(false);

  const updateDetail = (newDetail: DetailField) => {
    handleUpdateDetail(newDetail);
  };

  return (
    <>
      <Box
        pb={"md"}
        style={{
          borderLeft: "2px solid #000000",
        }}
        pl={"md"}
      >
        <Text variant={TextVariant.Subheading} mb={"xs"}>
          {getLocalizedName(
            Object.entries(detail.label).map(([language, value]) => ({
              language,
              name: value,
            })),
          )}

          {!isDefaultField && (
            <ActionIcon
              onClick={() => handleRemoveDetail?.()}
              color="red"
              variant="gradient"
              c={"white"}
              ml={"sm"}
            >
              <LuTrash2 />
            </ActionIcon>
          )}
        </Text>

        <Flex direction={"row"} gap={"md"} align={"center"}>
          {/* <TextInput
            disabled={true}
            value={getLocalizedName(
              Object.entries(detail.label).map(([language, value]) => ({
                language,
                name: value,
              }))
            )}
            onChange={(e) => updateDetail({ ...detail, id: e.target.value })}
            label={"Field"}
          /> */}
          <Flex align={"center"} gap={"md"}>
            <LimeSelect
              label={"Type"}
              data={[
                { label: "Text", value: "text" },
                { label: "Number", value: "number" },
                { label: "Phone", value: "phone" },
                { label: "Date", value: "date" },
                { label: "Time", value: "time" },
                { label: "TextArea", value: "textarea" },
                { label: "Radio", value: "radio" },
                { label: "Checkbox", value: "checkbox" },
                { label: "Select", value: "select" },
                // { label: "Multi-select", value: "multi-select" },
                { label: "Label", value: "label" },
              ]}
              value={detail.type}
              disabled={isDefaultField}
              allowDeselect={false}
              onChange={(value) =>
                updateDetail({
                  ...detail,
                  // @ts-expect-error - value is correct
                  type: value!,
                })
              }
            />

            {detail.type !== "label" && (
              <LimeSwitch
                label={"Required"}
                checked={detail.required}
                onChange={(e) =>
                  updateDetail({ ...detail, required: e.currentTarget.checked })
                }
              />
            )}

            {detail.type === "number" && (
              <>
                <NumberInput
                  label={"Min"}
                  value={detail.min}
                  onChange={(value) =>
                    updateDetail({
                      ...detail,
                      min: isNaN(value) ? 0 : (value as number),
                    })
                  }
                />
                <NumberInput
                  label={"Max"}
                  value={detail.max}
                  onChange={(value) =>
                    updateDetail({
                      ...detail,
                      max: isNaN(value) ? 0 : (value as number),
                    })
                  }
                />
              </>
            )}
          </Flex>
        </Flex>

        {!isDefaultField && (
          <>
            <Button
              onClick={() => {
                setLabelsShown(!labelsShown);
              }}
              variant="light"
              w={"fit-content"}
              leftSection={labelsShown ? <BiChevronDown /> : <BiChevronUp />}
            >
              Labels
            </Button>
            <Collapse in={labelsShown}>
              <Flex direction={"row"} gap={"md"} align={"center"} wrap={"wrap"}>
                {SUPPORTED_LANGUAGES.map((lang) => {
                  return (
                    <LimeTextInput
                      key={lang}
                      label={lang}
                      value={detail.label[lang]}
                      onChange={(e) => {
                        updateDetail({
                          ...detail,
                          label: {
                            ...detail.label,
                            [lang]: e.target.value,
                          },
                        });
                      }}
                    />
                  );
                })}
              </Flex>
            </Collapse>
          </>
        )}

        {(detail.type === "radio" ||
          detail.type === "checkbox" ||
          detail.type === "select" ||
          detail.type === "multi-select") && (
          <Box>
            {detail.options?.map((option) => {
              return (
                <Flex direction={"row"} gap={"md"} align={"center"}>
                  <ActionIcon
                    onClick={() => {
                      updateDetail({
                        ...detail,
                        options: detail.options?.filter(
                          (o) => o.value !== option.value,
                        ),
                      });
                    }}
                  >
                    <BiTrash />
                  </ActionIcon>

                  <TextInput
                    value={option.value}
                    onChange={(e) => {
                      updateDetail({
                        ...detail,
                        options: detail.options?.map((o) => {
                          if (o.value === option.value) {
                            return {
                              ...o,
                              value: e.target.value,
                            };
                          }
                          return o;
                        }),
                      });
                    }}
                    label={"Value"}
                    disabled
                  />
                  {SUPPORTED_LANGUAGES.map((lang) => {
                    return (
                      <TextInput
                        key={lang}
                        value={option.label[lang]}
                        onChange={(e) => {
                          updateDetail({
                            ...detail,
                            options: detail.options?.map((o) => {
                              if (o.value === option.value) {
                                return {
                                  ...o,
                                  label: {
                                    ...o.label,
                                    [lang]: e.target.value,
                                  },
                                };
                              }
                              return o;
                            }),
                          });
                        }}
                        label={lang}
                      />
                    );
                  })}
                </Flex>
              );
            })}

            <ActionIcon
              onClick={() => {
                updateDetail({
                  ...detail,
                  options: [
                    ...(detail.options || []),
                    {
                      value: uuidv4(),
                      label: {},
                    },
                  ],
                });
              }}
            >
              <RiAddCircleFill size={"24px"} />
            </ActionIcon>
          </Box>
        )}

        {detail.type === "label" && (
          <>
            <Flex direction={"row"} gap={"md"} align={"center"}>
              {SUPPORTED_LANGUAGES.map((lang) => {
                return (
                  <TextInput
                    key={lang}
                    value={detail.label[lang]}
                    onChange={(e) => {
                      updateDetail({
                        ...detail,
                        label: {
                          ...detail.label,
                          [lang]: e.target.value,
                        },
                      });
                    }}
                    label={lang}
                  />
                );
              })}
            </Flex>
          </>
        )}
      </Box>
      <Divider />
    </>
  );
};
