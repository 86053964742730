import { t } from "@lingui/core/macro";
import { TimeType } from "@/../../shared/types/time-type";
import { api } from "@/lib/api-client";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export type Timeoffs = {
  type: string;
  timeoffs: {
    id: number;
    timeFrom: string;
    timeTo: string;
    type: string;
    locationId: number;
    userId: number;
    note: string | null;
  }[];
};

export const useBreakModal = (
  date: string,
  location: number,
  user: number,
  lastSelectedTime: string,
  calendarData: {
    endTime: string;
    allShifts: {
      start: string;
      end: string;
      userId: number;
    }[];
  },
) => {
  const [timeoffs, setTimeoffs] = useState<Timeoffs[]>();

  const { data, isError, isPending } = api.timeoff.useGetTimeoff({
    dateFrom: date ? dayjs(date).format("YYYY-MM-DD HH:mm") : undefined,
    dateTo: date ? dayjs(date).format("YYYY-MM-DD HH:mm") : undefined,
    locationId: location,
    userId: user,
  });

  const { data: clientSettings, isPending: isClientSettingsPending } =
    api.client.useClientSettings();

  const { mutateAsync: putMutate } = api.timeoff.usePutTimeoff();

  useEffect(() => {
    if (isPending || isError) return;

    const timeoffsPerType = data.types.map((type) => {
      const timeoffsOfType = data.timeoffs.filter(
        (timeoff) => timeoff.type === type.type,
      );

      return {
        type: type.type,
        timeoffs: timeoffsOfType,
      };
    });

    setTimeoffs(timeoffsPerType);
  }, [data, isPending, isError]);

  const addTimeoff = async (
    type: Timeoffs["timeoffs"][0]["type"],
    locationId: number,
    userId: number,
    date: string,
  ) => {
    // generate random scheduleShiftBreakId that is not in the list
    if (!timeoffs) return;
    const selectedType = timeoffs.find((timeoff) => timeoff.type === type);

    const ids = selectedType?.timeoffs.map((break_) => break_.id);
    let id = Math.floor(Math.random() * 1000000);
    while (ids?.includes(id)) {
      id = Math.floor(Math.random() * 1000000);
    }

    setTimeoffs((prev) => {
      if (!prev) return;
      const newTimeoffs = structuredClone(prev);

      const timeFromDayObject = (() => {
        const selectedTimeObject = dayjs(
          `${date} ${dayjs(lastSelectedTime).format("HH:mm")}`,
        );

        if (type === "VACATION" || type === "SICK_LEAVE") {
          const shift = calendarData.allShifts.find(
            (shift) =>
              shift.userId === userId &&
              dayjs(shift.start, "YYYY-MM-DD HH:mm").isSame(
                selectedTimeObject,
                "day",
              ),
          );

          if (shift) {
            const shiftStart = dayjs(shift.start, "YYYY-MM-DD HH:mm");

            return selectedTimeObject
              .set("hour", shiftStart.hour())
              .set("minute", shiftStart.minute());
          }
        }

        return selectedTimeObject;
      })();

      const timeFrom = timeFromDayObject.format("YYYY-MM-DDTHH:mm:ss");
      const timeTo = (() => {
        const isLunch = type === "LUNCH";
        const endTime = isLunch
          ? timeFromDayObject
              .add(clientSettings?.maxLunchTimeMinutes || 30, "minutes")
              .format("HH:mm")
          : calendarData.endTime;

        return dayjs(`${date} ${endTime}`).format("YYYY-MM-DDTHH:mm:ss");
      })();

      newTimeoffs
        ?.find((timeoff) => timeoff.type === type)
        ?.timeoffs.push({
          id,
          type,
          locationId,
          userId,
          timeFrom,
          timeTo,
          note: null,
        });

      return newTimeoffs;
    });
  };

  const updateTimeoffTimes = (
    id: number,
    timeFrom: TimeType | "" | string,
    timeTo: TimeType | "" | string,
  ) => {
    setTimeoffs((prev) => {
      const newTimeoffs = structuredClone(prev);
      const timeoff = newTimeoffs
        ?.flatMap((timeoff) => timeoff.timeoffs)
        .find((timeoff) => timeoff.id === id);
      if (!timeoff) return;

      timeoff.timeFrom = timeFrom;
      timeoff.timeTo = timeTo;

      return newTimeoffs;
    });
  };

  const updateTimeoffNote = (id: number, note: string) => {
    setTimeoffs((prev) => {
      const newTimeoffs = structuredClone(prev);
      const timeoff = newTimeoffs
        ?.flatMap((timeoff) => timeoff.timeoffs)
        .find((timeoff) => timeoff.id === id);
      if (!timeoff) return;

      timeoff.note = note;

      return newTimeoffs;
    });
  };

  const saveTimeoffs = async (onClose: () => void) => {
    if (!timeoffs) return;

    await putMutate({
      timeoffs: timeoffs
        ?.flatMap((timeoff) => timeoff.timeoffs)
        .map((to) => ({
          ...to,
          timeFrom: dayjs(to.timeFrom).utc(true).toDate(),
          timeTo: dayjs(to.timeTo).utc(true).toDate(),
        })),
      locationId: Number(location),
      userId: user,
      date,
    });

    notifications.show({
      message: t`Pavze posodobljene`,
    });

    onClose();
  };

  const deleteTimeoff = (id: number, type: Timeoffs["timeoffs"][0]["type"]) => {
    setTimeoffs((prev) => {
      const newTimeoffs = structuredClone(prev);

      const indexToRemove = newTimeoffs
        ?.find((timeoff) => timeoff.type === type)
        ?.timeoffs.findIndex((timeoff) => timeoff.id === id);

      if (indexToRemove === undefined) return;

      newTimeoffs
        ?.find((timeoff) => timeoff.type === type)
        ?.timeoffs.splice(indexToRemove, 1);

      return newTimeoffs;
    });
  };

  return {
    timeoffs,
    data,
    isError,
    isLoading: isPending,
    addTimeoff,
    updateTimeoffTimes,
    saveTimeoffs,
    deleteTimeoff,
    updateTimeoffNote,
  };
};
