import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { LimeLoader } from "@/Components/LimeLoader";
import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Button, Flex, Text, UnstyledButton } from "@mantine/core";
import { useEffect } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { ActionButton } from "../Components/ActionButton";
import useManageResourceGroup from "../useManageResourceGroup";

export const ResourcesTab = () => {
  const { id } = useParams();

  const selectedResourceGroupId = id ? parseInt(id) : undefined;

  const {
    data: resources,
    isPending: isResourcesPending,
    isError: isResourcesError,
  } = api.resources.useGetResources({
    resourceGroupId: selectedResourceGroupId,
  });

  const { navigateToResource, handleBackButton, navigateToTab } =
    useManageResourceGroup();

  const handleResourceClick = (resourceId: number) => {
    navigateToResource({
      resourceGroupId: selectedResourceGroupId ?? -1,
      resourceId,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleBackButton({});
  };

  useEffect(() => {
    console.log(isResourcesError);
    if (isResourcesError) {
      handleBackButton({});
    }
  }, [handleBackButton, isResourcesError]);

  if (isResourcesPending) return <LimeLoader />;

  if (isResourcesError) {
    return (
      <Text>
        <Trans>Napaka</Trans>
      </Text>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction={"column"} align={"flex-end"} w={"100%"} pb={"xl"}>
        {resources.map((resource) => (
          <UnstyledButton
            key={resource.id}
            h={54}
            w={"100%"}
            style={{ borderBottom: `1px solid ${Color.Divider}` }}
            onClick={() => handleResourceClick(resource.id)}
            px={"md"}
          >
            <Flex justify={"space-between"} align={"center"}>
              <Text variant={TextVariant.Subheading}>{resource.label}</Text>
              <RiArrowRightSLine size={24} />
            </Flex>
          </UnstyledButton>
        ))}

        <Button
          variant="light"
          radius={20}
          w={"fit-content"}
          mt={"md"}
          size="xs"
          onClick={() => handleResourceClick(-1)}
          mr={"md"}
        >
          + Dodaj novo sredstvo
        </Button>
      </Flex>

      <ActionButton
        text={t`Shrani`}
        onBackButtonClick={() => {
          navigateToTab({
            currentTab: "resources",
            direction: "previous",
            resourceGroupId: selectedResourceGroupId,
          });
        }}
      />
    </form>
  );
};
