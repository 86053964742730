import { t } from "@lingui/core/macro";
import type {
  CreateStripeAccount,
  GetAnalytics,
  GetPaymentForOverview,
  GetPaymentSettings,
  GetPaymentsList,
  GetStripeAccount,
  GetStripeAccountRefreshURL,
  GetTaxPayments,
  PostPaymentRefund,
  UpdateGlobalPaymentSettings,
  UpdateServicesPaymentSettings,
} from "@/server-types";
import { Color } from "@/types/colors";
import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "..";
import { get, post, put } from "../request-helpers";

/**
 * GET /stripe/account
 */
export const useGetStripeAccount = () => {
  return useQuery({
    queryKey: ["getStripeAccount"],
    queryFn: () => get<GetStripeAccount>("/stripe/account"),
  });
};

/**
 * POST /stripe/account
 */
export const useCreateStripeAccount = () => {
  return useMutation({
    mutationFn: (body: CreateStripeAccount["body"]) =>
      post<CreateStripeAccount>("/stripe/account", body),
  });
};

/**
 * GET /stripe/paymentSettings
 */
export const useGetStripePaymentSettings = () => {
  return useQuery({
    queryKey: ["getStripePaymentSettings"],
    queryFn: () => get<GetPaymentSettings>("/stripe/paymentSettings"),
  });
};

/**
 * PUT /stripe/paymentSettings/services
 */
export const useUpdateStripePaymentSettingsServices = () => {
  return useMutation({
    mutationFn: (body: UpdateServicesPaymentSettings["body"]) =>
      put<UpdateServicesPaymentSettings>(
        "/stripe/paymentSettings/services",
        body,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getStripePaymentSettings"],
      });

      notifications.show({
        message: t`Nastavitve so bile uspešno posodobljene`,
        color: Color.Success,
      });
    },
  });
};

/**
 * PUT /stripe/paymentSettings/global
 */
export const useUpdateStripePaymentSettingsGlobal = () => {
  return useMutation({
    mutationFn: (body: UpdateGlobalPaymentSettings["body"]) =>
      put<UpdateGlobalPaymentSettings>("/stripe/paymentSettings/global", body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getStripePaymentSettings"],
      });

      notifications.show({
        message: t`Nastavitve so bile uspešno posodobljene`,
        color: Color.Success,
      });
    },
  });
};

/**
 * GET /stripe/account/refresh
 */
export const useGetStripeAccountOnboardingUrl = () => {
  return useQuery({
    queryKey: ["getStripeAccountOnboardingUrl"],
    queryFn: () => get<GetStripeAccountRefreshURL>("/stripe/account/refresh"),
    enabled: false,
    refetchOnWindowFocus: false,
  });
};

/**
 * GET /stripe/analytics
 */
export const useGetStripeAnalytics = (query: GetAnalytics["query"]) => {
  return useQuery({
    queryKey: ["getStripeAnalytics", query],
    queryFn: () => get<GetAnalytics>("/stripe/analytics", query),
  });
};

/**
 * GET /stripe/payments-overview
 */
export const useGetPaymentsList = (query: GetPaymentsList["query"]) => {
  return useQuery({
    queryKey: ["getPaymentsList", query],
    queryFn: () => get<GetPaymentsList>("/stripe/payments-overview", query),
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
  });
};

/**
 * GET /stripe/payments-overview/{userAppointmentId}
 */
export const useGetPaymentForOverview = (userAppointmentId: number) => {
  return useQuery({
    queryKey: ["getPaymentForOverview", userAppointmentId],
    queryFn: () =>
      get<GetPaymentForOverview>(
        `/stripe/payments-overview/${userAppointmentId}`,
      ),
  });
};

/**
 * GET /stripe/Tax/Export
 */
export const useGetTaxPayments = () => {
  return useMutation({
    mutationFn: (query: GetTaxPayments["query"]) =>
      get<GetTaxPayments>("/stripe/tax/export", query),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      notifications.show({
        message: error.response.data.error,
        color: "red",
      });
    },
  });
};

/**
 * POST /stripe/payment/{intentId}/refund
 */
export const usePostPaymentRefund = () => {
  return useMutation({
    mutationKey: ["postPaymentRefund"],
    mutationFn: ({
      intentId,
      body,
    }: {
      intentId: string;
      body: PostPaymentRefund["body"];
    }) => post<PostPaymentRefund>(`/stripe/payment/${intentId}/refund`, body),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      notifications.show({
        message: error.response.data.error,
        color: "red",
      });
    },
  });
};
