import { t } from "@lingui/core/macro";
import useAccountInfo from "@/hooks/useAccountInfo";
import { api } from "@/lib/api-client";
import { useGetServices } from "@/lib/api-client/paths/service";
import type { GetNotificationSettings, GetServices } from "@/server-types";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { count } from "sms-length";
import useLocations from "../../Locations/useLocations";
import { minutesToValueAndUnit, valueAndUnitToMinutes } from "../utils";

type Location = {
  address: string;
  gsm: string | null;
  label: string;
};

type NotificationFormValues = {
  notification_type: "SMS" | "EMAIL";
  notification_template: string;
  notification_subject: string;
  time_offset: number;
  trigger: GetNotificationSettings["response"]["triggers"][0];
  all_services: boolean;
  services: string[];
  notificationSettingId?: number;
  unit: "hours" | "days" | "minutes";
};

const initialValues: NotificationFormValues = {
  notification_type: "SMS",
  trigger: "onConfirmation",
  notification_subject: "",
  notification_template: "",
  time_offset: 0,
  all_services: true,
  services: [],
  unit: "minutes",
};

export const useNotificationSettingModal = (onClose: () => void) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    data: existingNotificationSettingData,
    isLoading: isExistingNotificationSettingDataLoading,
    isError: isExistingNotificationSettingDataError,
  } = api.notification.useGetNotificationSetting(
    Number(searchParams.get("id")),
  );

  const { mutateAsync: createNotificationSetting } =
    api.notification.useCreateNotificationSetting();
  const { mutateAsync: updateNotificationSetting } =
    api.notification.useUpdateNotificationSetting();

  const newSettingForm = useForm({
    initialValues: structuredClone(initialValues),
    validate: {
      notification_template: (value, values) => {
        if (
          value.includes("{rescheduleFromDateTime}") &&
          values.trigger !== "onReschedule"
        ) {
          return t` rescheduleFromDateTime se lahko uporabi le v primeru ko se obvestilo pošlje ob spremembi termina.`;
        }
        return null;
      },
    },
    onValuesChange() {
      newSettingForm.validate();
    },
  });

  useEffect(() => {
    if (
      location.pathname.includes("/new") ||
      location.pathname.includes("/edit")
    ) {
      if (
        location.pathname.includes("/new") &&
        searchParams.has("defaultTrigger")
      ) {
        newSettingForm.setFieldValue(
          "trigger",
          searchParams.get(
            "defaultTrigger",
          ) as GetNotificationSettings["response"]["triggers"][0],
        );
      }
      return;
    }
    newSettingForm.reset();
  }, [location.pathname]);

  useEffect(() => {
    if (
      isExistingNotificationSettingDataLoading ||
      !existingNotificationSettingData ||
      !location.pathname.includes("/edit") ||
      newSettingForm.values.notificationSettingId
    ) {
      return;
    }

    if (isExistingNotificationSettingDataError) {
      navigate("/dashboard/notifications/new");
    }

    const { notificationSetting } = existingNotificationSettingData;

    newSettingForm.setValues({
      ...notificationSetting,
      notification_template: notificationSetting.notification_template || "",
      notification_subject: notificationSetting.notification_subject || "",
      notification_type: notificationSetting.notification_type as
        | "SMS"
        | "EMAIL",
      time_offset: minutesToValueAndUnit(notificationSetting.time_offset).value,
      unit: minutesToValueAndUnit(notificationSetting.time_offset).unit,
    });
  }, [isExistingNotificationSettingDataLoading]);

  const handleCreateNewSetting = async () => {
    try {
      await createNotificationSetting({
        ...newSettingForm.values,
        services: newSettingForm.values.services.map(Number),
        time_offset: valueAndUnitToMinutes(
          newSettingForm.values.time_offset,
          newSettingForm.values.unit,
        ),
      });
      notifications.show({
        message: t`Uspešno ste ustvarili novo obvestilo`,
      });
      onClose();
    } catch (err) {
      notifications.show({ message: t`Prišlo je do napake`, color: "red" });
    }
  };

  const handleUpdateNewSetting = async () => {
    if (!newSettingForm.values.notificationSettingId) return;
    try {
      await updateNotificationSetting({
        body: {
          ...newSettingForm.values,
          services: newSettingForm.values.services.map(Number),
          time_offset: valueAndUnitToMinutes(
            newSettingForm.values.time_offset,
            newSettingForm.values.unit,
          ),
        },
        notificationSettingId: newSettingForm.values.notificationSettingId,
      });
      notifications.show({ message: t`Uspešno ste ustvarili novo obvestilo` });
      onClose();
    } catch (err) {
      notifications.show({ message: t`Prišlo je do napake`, color: "red" });
    }
  };

  const notificationContentRef = useRef<HTMLTextAreaElement>(null);

  const { accountInfo } = useAccountInfo((state) => state);
  const { locations } = useLocations();
  const { data: serviceData } = useGetServices({});
  const { data: users } = api.user.useUserList({
    clientId: accountInfo.clientId,
    includeDeleted: false,
  });

  const replaceSumniki = (string: string) => {
    return string
      .replace(/č/g, "c")
      .replace(/š/g, "s")
      .replace(/ž/g, "z")
      .replace(/Č/g, "C")
      .replace(/Š/g, "S")
      .replace(/Ž/g, "Z")
      .replace(/ć/g, "c")
      .replace(/đ/g, "dj")
      .replace(/Ć/g, "C")
      .replace(/Đ/g, "Dj");
  };

  const returnItemsThatContainSpecialCharacters = (
    items: (string | undefined)[],
  ) => {
    return items.filter(
      (item): item is string =>
        item !== undefined && count(item).characterPerMessage < 80,
    );
  };

  const calculateLongestLength = (items: (string | null | undefined)[]) => {
    return Math.max(0, ...items.map((item) => item?.length ?? 0));
  };

  const longestServiceNameLength = calculateLongestLength(
    serviceData?.services.map((service) => service.name) ?? [],
  );
  const longestLocationAddressLength = calculateLongestLength(
    locations.map((location) => (location as Location).address),
  );
  const longestLocationPhoneLength = calculateLongestLength(
    locations.map((location) => (location as Location).gsm),
  );
  const longestLocationNameLength = calculateLongestLength(
    locations.map((location) => (location as Location).label),
  );
  const longestUserNameLength = calculateLongestLength(
    users?.users.map((user) => `${user.name} ${user.lastName}`) ?? [],
  );
  const longestUserPhoneLength = calculateLongestLength(
    users?.users.map((user) => user?.phone) ?? [],
  );

  const getLongestServiceTagLocalizedName = (
    serviceData?: GetServices["response"],
  ) => {
    if (!serviceData) return 0;

    const tagLengths = serviceData.services.flatMap((service) =>
      service.service_tag.flatMap((tag) =>
        tag.tag.TagNameLocalized.map(
          (tagLocalizedName) => tagLocalizedName.name.length,
        ),
      ),
    );

    if (tagLengths.length === 0) return 0;

    const longest = Math.max(...tagLengths);

    return longest;
  };

  const longestServiceTagLength = serviceData
    ? getLongestServiceTagLocalizedName(serviceData)
    : 0;

  const tags = [
    {
      label: t`Ime podjetja`,
      value: "{businessName}",
      length: accountInfo.businessName.length,
      includesSpecialCharacter: returnItemsThatContainSpecialCharacters([
        accountInfo.businessName,
      ]),
    },
    { label: t`Ime stranke`, value: "{clientName}", length: 10 },
    { label: t`Priimek stranke`, value: "{clientLastName}", length: 15 },
    {
      label: t`Ime storitve`,
      value: "{serviceName}",
      length: longestServiceNameLength,
      includesSpecialCharacter: returnItemsThatContainSpecialCharacters(
        serviceData?.services.map((service) => service.name) ?? [],
      ),
    },
    { label: t`Datum`, value: "{day}", length: 6 },
    { label: t`Ime dneva`, value: "{dayName}", length: 12 },
    { label: t`Leto`, value: "{year}", length: 4 },
    { label: t`Čas`, value: "{time}", length: 5 },
    {
      label: t`Naslov lokacije`,
      value: "{locationAddress}",
      length: longestLocationAddressLength,
      includesSpecialCharacter: returnItemsThatContainSpecialCharacters(
        locations.map((location) => (location as Location).address),
      ),
    },
    {
      label: t`Ime lokacije`,
      value: "{locationName}",
      length: longestLocationNameLength,
    },
    {
      label: t`Telefonska številka lokacije`,
      value: "{locationPhone}",
      length: longestLocationPhoneLength,
    },
    {
      label: t`Povezava za prenaročanje`,
      value: "{rescheduleLink}",
      length: 23,
    },
    {
      label: t`Kategorija/e storitve`,
      value: "{tag}",
      length: longestServiceTagLength,
      includesSpecialCharacter: returnItemsThatContainSpecialCharacters(
        serviceData?.services
          .flatMap((service) => service.service_tag)
          .flatMap((tag) => tag.tag.TagNameLocalized.map((t) => t.name)) ?? [],
      ),
    },
    {
      label: t`Ime izvajalca`,
      value: "{employeeName}",
      length: longestUserNameLength,
    },
    {
      label: t`Telefonska številka izvajalca`,
      value: "{employeePhone}",
      length: longestUserPhoneLength,
    },
    {
      label: t`Datum in čas prvotnega termina`,
      value: "{rescheduleFromDateTime}",
      length: 16, // 10.09.2022 11:30
    },
  ];

  const insertStringsOfTagsLengthInContent = (content: string | undefined) => {
    if (!content) return "";
    let newContent = content;
    tags.forEach((tag) => {
      newContent = newContent.replaceAll(tag.value, "X".repeat(tag.length));
    });
    return newContent;
  };

  const handleTagClick = (tagValue: string) => {
    const cursorPos = notificationContentRef.current?.selectionStart ?? 0;
    newSettingForm.setFieldValue(
      "notification_template",
      newSettingForm.values.notification_template.slice(0, cursorPos) +
        tagValue +
        newSettingForm.values.notification_template.slice(cursorPos),
    );
  };

  const contentWithTags = insertStringsOfTagsLengthInContent(
    newSettingForm.values.notification_template,
  );
  const smsInfo = count(replaceSumniki(contentWithTags));

  return {
    newSettingForm,
    handleCreateNewSetting,
    tags,
    handleTagClick,
    notificationContentRef,
    smsInfo,
    services: serviceData?.services ?? [],
    handleUpdateNewSetting,
  };
};
