import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { getDurationsAndPrice } from "@/Utilities";
import { Color } from "@/types/colors";
import {
  ActionIcon,
  Button,
  Collapse,
  Combobox,
  Flex,
  NumberInput,
  Text,
  Tooltip,
  useCombobox,
  InputBase,
  Input,
} from "@mantine/core";
import { IoIosWarning } from "react-icons/io";
import StepperNumberInput from "./StepperNumberInput";

import useFeature from "@/hooks/useFeature";
import { api } from "@/lib/api-client";
import { useCalendarStore } from "@/pages/Dashboard/Calendar/store/useCalendarStore";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { TextVariant } from "@/types/text-variants";
import { useEffect, useState } from "react";
import { BsBuildingFillX } from "react-icons/bs";
import {
  LuCopySlash,
  LuPuzzle,
  LuTimerOff,
  LuUser,
  LuUsers,
  LuBox,
  LuBoxes,
  LuTrash,
} from "react-icons/lu";
import { useAppointmentModalStore } from "../useAppointmentModalStore_";

import { returnCurrencySymbol } from "../../../../../shared/utils/utils";
import { LimeSelect } from "@/Components/LimeSelect";

const SelectedServiceListItem = ({
  selectedService,
  selectedServices,
  setSelectedServices,
  service,
  index,
  isPreScheduled,
  startDuration,
  hasOnlyOneInput,
  timeOffDuration,
  endDuration,
}) => {
  const serviceCombobox = useCombobox({
    onDropdownClose: () => serviceCombobox.resetSelectedOption(),
  });
  const resourceCombobox = useCombobox({
    onDropdownClose: () => resourceCombobox.resetSelectedOption(),
  });
  const userCombobox = useCombobox({
    onDropdownClose: () => userCombobox.resetSelectedOption(),
  });

  const [selectMultipleUsers, setSelectMultipleUsers] = useState(false);
  const [selectMultipleResources, setSelectMultipleResources] = useState(true);

  const { appointmentData, requiredTags, setRequiredTags } =
    useAppointmentModalStore((s) => s);

  const {
    users,
    resources,
    services,
    doesHaveCombinations,
    selectedLocationId,
    calendarData,
    serviceTags,
  } = useCalendarStore((s) => s);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { data: validOptions, mutateAsync: checkValidOptions } =
    api.appointments.usePostValidOptions();

  const { isFeatureEnabled } = useFeature();

  const selectedResources = resources.filter((r) =>
    selectedService.resources?.includes(r.id),
  );
  const selectedUsers = users.filter((u) =>
    selectedService.users?.includes(u.userId),
  );

  useEffect(() => {
    if (
      !(
        doesHaveCombinations ||
        permissions.show_appointment_warnings ||
        permissions.restrict_appointment_overlaps ||
        permissions.restrict_appointment_services ||
        permissions.restrict_appointment_to_shifts
      )
    ) {
      return;
    }
    checkValidOptions({
      selectedServices: selectedServices.map((s) => ({
        ...s,
        duration:
          s.startDuration || 0 + timeOffDuration || 0 + endDuration || 0,
        timeOffDuration: s.timeOffDuration || undefined,
        timeOffStart: s.timeOffDuration ? s.startDuration : undefined,
      })),
      selectedServiceIndex: index,
      locationId: selectedLocationId,
      startTime: appointmentData.startTime,
    });
  }, [
    selectedService.users,
    selectedService.resources,
    selectedService.serviceId,
    selectedServices.length,
  ]);

  const usersDropdown = users
    .filter(
      (u) =>
        !validOptions ||
        validOptions.availableUsers.find(
          (usr) => u.userId === usr.userId && usr.isValid,
        ),
    )
    .map((user) => {
      const firstName = user.name?.toUpperCase;
      const lastName = user.lastName?.toUpperCase();

      const availableUserDetails = validOptions?.availableUsers?.find(
        (u) => u.userId === user.userId,
      );

      return {
        id: user.userId,
        value: user.userId.toString(),
        fullName: `${user.name} ${user.lastName}`,
        label: `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`,
        relationWarning: availableUserDetails?.relationWarning,
        shiftWarning: availableUserDetails?.shiftWarning,
        overlapWarning: availableUserDetails?.overlapWarning,
        isValid: availableUserDetails ? availableUserDetails.isValid : true,
      };
    });

  const servicesDropdown = services
    .filter((s) => !s.isDeleted)
    .filter(
      (s) =>
        !validOptions ||
        validOptions.availableServices.find(
          (ser) => s.serviceId === ser.serviceId && ser.isValid,
        ),
    )
    // sort by serviceTags sortOrder first and then service sortOrder inside
    .toSorted((a, b) => {
      const aTagOrder =
        serviceTags.find((t) => t.tagId === a.tagId)?.sortOrder || 99;
      const bTagOrder =
        serviceTags.find((t) => t.tagId === b.tagId)?.sortOrder || 99;

      if (aTagOrder !== bTagOrder) return aTagOrder - bTagOrder;

      const aServiceOrder = a.sortOrder;
      const bServiceOrder = b.sortOrder;

      return aServiceOrder - bServiceOrder;
    })
    .map((service) => {
      const availableServiceDetails = validOptions?.availableServices?.find(
        (s) => s.serviceId === service.serviceId,
      );

      return {
        id: service.serviceId,
        value: service.serviceId.toString(),
        label: service.name,
        relationWarning: availableServiceDetails?.relationWarning,
        shiftWarning: availableServiceDetails?.shiftWarning,
        overlapWarning: availableServiceDetails?.overlapWarning,
        combinationWarning:
          validOptions?.isCombination &&
          !validOptions?.tagsInCombination.includes(service.tagId),
        isValid: availableServiceDetails
          ? availableServiceDetails.isValid
          : true,
      };
    });

  console.log("--- SERVICES", services);
  console.log("--- SERVICE TAGS", serviceTags);
  console.log("--- SERVICES DROPDOWN", servicesDropdown);

  const resourcesDropdown = resources
    .filter(
      (rs) =>
        !validOptions ||
        validOptions.availableResources.find(
          (r) => rs.id === r.resourceId && r.isValid,
        ),
    )
    .map((r) => {
      const availableResourceDetails = validOptions?.availableResources?.find(
        (res) => res.resourceId === r.id,
      );

      return {
        id: r.id,
        value: r.id.toString(),
        label: r.label,
        relationWarning: availableResourceDetails?.relationWarning,
        shiftWarning: availableResourceDetails?.shiftWarning,
        overlapWarning: availableResourceDetails?.overlapWarning,
        capacityMax: r.capacityMax,
        isValid: availableResourceDetails
          ? availableResourceDetails.isValid
          : true,
      };
    });

  useEffect(() => {
    if (
      !!doesHaveCombinations ||
      !!permissions.show_appointment_warnings ||
      !!permissions.restrict_appointment_overlaps ||
      !!permissions.restrict_appointment_services ||
      !!permissions.restrict_appointment_to_shifts
    ) {
      if (!validOptions) return;
    }

    if (
      selectedService.users.length > 0 &&
      selectedService.resources.length > 0
    )
      return;

    if (selectedService.appointmentId) {
      return;
    }

    const newServices = [...selectedServices];
    if (selectedService.users.length === 0 && service.doesRequireUser) {
      const freeUser = usersDropdown.find((user) => user.isValid);

      if (freeUser) {
        newServices[index].users = [Number(freeUser.value)];
        setSelectedServices(newServices);
      }
    }

    if (
      selectedService.resources.length === 0 &&
      service.serviceResourceGroups.length > 0
    ) {
      const freeResource = resourcesDropdown.find(
        (resource) => resource.isValid,
      );

      if (freeResource) {
        newServices[index].resources = [freeResource.id];
      }
    }

    if (service.isAttendanceExplicit) {
      newServices[index].attendance = newServices[index].attendance || 1;
    } else {
      newServices[index].attendance = undefined;
    }

    setSelectedServices(newServices);
  }, [selectedService.serviceId]);

  // REQUIRED SERVICES
  // useEffect(() => {
  //   if (!validOptions) {
  //     return;
  //   }
  //   setRequiredTags(validOptions.requiredTags);
  // }, [validOptions]);

  const warnings = {
    serviceWarning: (
      <Tooltip label={t`Storitev se ne izvaja na tem sredstvu`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    scheduleError: (
      <Tooltip label={t`Sredstvo nima urnika`}>
        <div>
          <LuTimerOff color={Color.Error}></LuTimerOff>
        </div>
      </Tooltip>
    ),
    appointmentOverlapError: (
      <Tooltip label={t`Sredstvo je zasedeno`}>
        <div>
          <BsBuildingFillX color={Color.Error}></BsBuildingFillX>
        </div>
      </Tooltip>
    ),
    userServiceWarning: (
      <Tooltip label={t`Zaposleni ne izvaja te storitve`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    userScheduleError: (
      <Tooltip label={t`Zaposleni nima urnika`}>
        <div>
          <LuTimerOff color={Color.Error}></LuTimerOff>
        </div>
      </Tooltip>
    ),
    resourceRequired: (
      <Tooltip label={t`Storitev potrebuje sredstvo`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    userAppointmentOverlapError: (
      <Tooltip label={t`Zaposleni je zaseden`}>
        <div>
          <LuCopySlash color={Color.Error}></LuCopySlash>
        </div>
      </Tooltip>
    ),
    userRequired: (
      <Tooltip label={t`Storitev potrebuje zaposlenega`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    notEnoughResourcesWarning: (
      <Tooltip label={t`Za to število oseb potrebujete več sredstev`}>
        <div>
          <IoIosWarning color={Color.Error}></IoIosWarning>
        </div>
      </Tooltip>
    ),
    combinationWarning: (
      <Tooltip
        label={t`Storitev ni na voljo v kombinaciji z ostalimi izbranimi storitvami.`}
      >
        <div>
          <LuPuzzle color={Color.Error}></LuPuzzle>
        </div>
      </Tooltip>
    ),
  };

  const removeServiceFromLinked = () => {
    const newServices = [...selectedServices];
    if (selectedService.length <= 1) {
      return;
    }
    if (newServices[index].removed) {
      newServices[index].removed = false;
    } else {
      if (newServices[index].appointmentId) {
        newServices[index].removed = true;
      } else {
        newServices.splice(index, 1);
      }
    }

    setSelectedServices(newServices);
  };

  const toggleSelectMultipleUsers = (active) => {
    setSelectMultipleUsers(active);

    //  if multiple users selected, remove all users except the first one
    if (!active) {
      const newSelectedServices = [...selectedServices];
      newSelectedServices[index].users = [selectedService.users[0]];
      setSelectedServices(newSelectedServices);
    }
  };
  const toggleSelectMultipleResources = (active) => {
    setSelectMultipleResources(active);

    // if multiple resources selected, remove all resources except the first one
    if (!active) {
      const newSelectedServices = [...selectedServices];
      newSelectedServices[index].resources = selectedService.resources[0]
        ? [selectedService.resources[0]]
        : [];
      setSelectedServices(newSelectedServices);
    }
  };

  const notEnoughResourcesWarning = (() => {
    if (!service.isAttendanceExplicit) return false;
    if (!service.serviceResourceGroups.length) return false;

    const attendance = selectedService.attendance;
    const resourcesMaxCapacity = selectedService.resources.reduce(
      (acc, resource) => {
        const currResource = resources.find((r) => r.id === resource);

        if (!currResource) return acc;

        return acc + currResource.capacityMax;
      },
      0,
    );

    return attendance > resourcesMaxCapacity;
  })();

  const discountedPrice = (() => {
    if (!selectedService.discountAmount) return selectedService.price || 0;

    if (selectedService.discountType === "amount") {
      return selectedService.price - selectedService.discountAmount;
    }

    return (
      selectedService.price -
      (selectedService.price * selectedService.discountAmount) / 100
    );
  })();

  const discountInputError = (() => {
    if (!selectedService.discountAmount) return false;

    if (selectedService.discountType === "amount") {
      if (selectedService.discountAmount > selectedService.price) {
        return t`Popust ne sme biti večji od cene`;
      }

      if (selectedService.discountAmount < 0) {
        return t`Popust ne sme biti manjši od 0`;
      }
    }

    if (selectedService.discountType === "percentage") {
      if (selectedService.discountAmount > 100) {
        return t`Popust ne sme biti večji od 100`;
      }

      if (selectedService.discountAmount < 0) {
        return t`Popust ne sme biti manjši od 0`;
      }
    }
  })();

  return (
    <div
      style={{
        background: selectedService.removed
          ? "#ff000010"
          : "rgba(0, 0, 0, 0.02)",
        padding: "5px 7px",
        borderRadius: "10px",
      }}
    >
      <Flex
        key={selectedService.serviceId}
        justify={"space-between"}
        align={"start"}
        direction={"column"}
      >
        {/**
         * service combobox
         */}

        <Combobox
          store={serviceCombobox}
          withinPortal={false}
          position="bottom-start"
          disabled={!permissions.manage_appointments}
          onOptionSubmit={(val) => {
            val = Number(val);

            const newSelectedServices = [...selectedServices];

            const newService = services.find(
              (service) => service.serviceId === val,
            );
            const user = newService.users.find(
              (user) => user.userId === selectedService.userId,
            );

            const { startDuration, timeOffDuration, endDuration, price } =
              getDurationsAndPrice(newService, user);

            newSelectedServices[index].serviceId = val;
            newSelectedServices[index].price = price;
            newSelectedServices[index].startDuration = startDuration;
            newSelectedServices[index].timeOffDuration = timeOffDuration;
            newSelectedServices[index].endDuration = endDuration;

            setSelectedServices(newSelectedServices);
            serviceCombobox.closeDropdown();
          }}
        >
          <Combobox.Target>
            <InputBase
              component="button"
              type="button"
              pointer
              rightSection={<Combobox.Chevron />}
              rightSectionPointerEvents="none"
              onClick={() => serviceCombobox.toggleDropdown()}
              w={"100%"}
            >
              {selectedService.serviceId ? (
                service.name
              ) : (
                <Input.Placeholder>{t`Storitev še ni izbrana`}</Input.Placeholder>
              )}
            </InputBase>
          </Combobox.Target>
          <Combobox.Dropdown
            withinPortal={false}
            position="bottom-start"
            style={{ maxHeight: "200px", overflowY: "auto" }}
          >
            <Combobox.Options>
              {" "}
              {servicesDropdown.map((data, index) => {
                return (
                  <Combobox.Option
                    value={data.id}
                    key={data.id}
                    active={index === 0}
                  >
                    <Flex
                      gap={"15px"}
                      align={"center"}
                      justify={"space-between"}
                    >
                      <Text
                        fw={500}
                        style={{ whiteSpace: "pre" }}
                        c={
                          selectedService.serviceId === data.id
                            ? Color.Success
                            : Color.PrimaryText
                        }
                      >
                        {data.label}
                      </Text>
                      <Flex gap={5}>
                        {data.relationWarning
                          ? warnings.userServiceWarning
                          : ""}
                        {data.shiftWarning ? warnings.userScheduleError : ""}
                        {data.combinationWarning
                          ? warnings.combinationWarning
                          : ""}
                        {data.overlapWarning
                          ? warnings.userAppointmentOverlapError
                          : ""}
                      </Flex>
                    </Flex>
                  </Combobox.Option>
                );
              })}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>

        <Collapse in={!selectedService.removed} w={"100%"}>
          <Flex direction={"column"} gap={10} mt={10}>
            {/**
             * resource combobox
             */}
            {isFeatureEnabled("RESOURCES") && (
              <Combobox
                w={"100%"}
                store={resourceCombobox}
                withinPortal={false}
                position="bottom-start"
                disabled={!permissions.manage_appointments}
                onOptionSubmit={(val) => {
                  const newSelectedServices = [...selectedServices];
                  if (selectMultipleResources) {
                    let resources = newSelectedServices[index].resources || [];
                    if (!resources.includes(val)) {
                      resources.push(Number(val));
                    } else {
                      resources = resources.filter((r) => r !== val);
                    }
                    newSelectedServices[index].resources = resources;
                  } else {
                    newSelectedServices[index].resources = [Number(val)];
                    resourceCombobox.closeDropdown();
                  }
                  setSelectedServices(newSelectedServices);
                }}
              >
                <Combobox.Target>
                  <Flex align="center">
                    <InputBase
                      component="button"
                      type="button"
                      pointer
                      rightSection={<Combobox.Chevron />}
                      rightSectionPointerEvents="true"
                      error={
                        notEnoughResourcesWarning
                          ? t`Za to število oseb potrebujete več sredstev`
                          : undefined
                      }
                      onClick={() => resourceCombobox.toggleDropdown()}
                      w={"100%"}
                    >
                      {selectedResources.length > 0 ? (
                        `${selectedResources[0].label} ${
                          selectedResources.length > 1
                            ? `+ ${selectedResources.length - 1} ` + t`ostalih`
                            : ""
                        }`
                      ) : (
                        <Input.Placeholder>{t`Ni izbranih sredstev`}</Input.Placeholder>
                      )}
                    </InputBase>
                    <ActionIcon
                      onClick={() =>
                        toggleSelectMultipleResources(!selectMultipleResources)
                      }
                      title={
                        selectMultipleResources
                          ? t`Disable multi-select`
                          : t`Enable multi-select`
                      }
                    >
                      {selectMultipleResources ? <LuBoxes /> : <LuBox />}
                    </ActionIcon>
                  </Flex>
                </Combobox.Target>

                <Combobox.Dropdown
                  withinPortal={false}
                  position="bottom-start"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <Combobox.Options>
                    {resourcesDropdown.map((data) => (
                      <Combobox.Option
                        value={data.id}
                        key={data.id}
                        style={{
                          backgroundColor: selectedResources.some(
                            (res) => res.id === data.id,
                          )
                            ? "rgba(0, 128, 0, 0.1)"
                            : "transparent",
                          borderRadius: "5px",
                        }}
                      >
                        <Flex
                          align={"center"}
                          gap={10}
                          justify={"space-between"}
                        >
                          <Text>{data.label}</Text>
                          <Flex gap={5}>
                            {data.relationWarning && (
                              <IoIosWarning color={Color.Error} />
                            )}
                            {data.shiftWarning && (
                              <LuTimerOff color={Color.Error} />
                            )}
                            {data.overlapWarning && (
                              <BsBuildingFillX color={Color.Error} />
                            )}
                          </Flex>
                        </Flex>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>
            )}

            {/**
             * user combobox
             */}
            {(service.doesRequireUser || selectedService.users.length > 0) && (
              <Combobox
                w="100%"
                store={userCombobox}
                withinPortal={false}
                position="bottom-start"
                disabled={!permissions.manage_appointments}
                onOptionSubmit={(val) => {
                  const newSelectedServices = JSON.parse(
                    JSON.stringify(selectedServices),
                  );
                  if (selectMultipleUsers) {
                    let users = newSelectedServices[index].users || [];
                    const service = services.find(
                      (service) =>
                        service.serviceId === selectedService.serviceId,
                    );
                    if (service) {
                      if (!users.includes(val)) {
                        users.push(Number(val));
                      } else {
                        users = newSelectedServices[index].users.filter(
                          (r) => r !== val,
                        );
                      }
                      newSelectedServices[index].users = users;
                    }
                    setSelectedServices(newSelectedServices);
                  } else {
                    if (
                      newSelectedServices[index].users.includes(Number(val))
                    ) {
                      newSelectedServices[index].users = [];
                    } else {
                      newSelectedServices[index].users = [Number(val)];
                    }
                    setSelectedServices(newSelectedServices);
                    userCombobox.closeDropdown();
                  }
                }}
              >
                <Combobox.Target>
                  <Flex align="center">
                    <InputBase
                      component="button"
                      type="button"
                      pointer
                      rightSection={<Combobox.Chevron />}
                      rightSectionPointerEvents="none"
                      onClick={() => userCombobox.toggleDropdown()}
                      w={"100%"}
                    >
                      {selectedUsers.length > 0 ? (
                        `${selectedUsers[0].name} ${selectedUsers[0].lastName} ${
                          selectedUsers.length > 1
                            ? `+ ${selectedUsers.length - 1} ` + t`ostalih`
                            : ""
                        }`
                      ) : (
                        <Input.Placeholder>{t`Ni izbranih zaposlenih`}</Input.Placeholder>
                      )}
                    </InputBase>
                    <ActionIcon
                      onClick={() =>
                        toggleSelectMultipleUsers(!selectMultipleUsers)
                      }
                      title={
                        selectMultipleUsers
                          ? t`Disable multi-select`
                          : t`Enable multi-select`
                      }
                    >
                      {selectMultipleUsers ? <LuUsers /> : <LuUser />}
                    </ActionIcon>
                  </Flex>
                </Combobox.Target>
                <Combobox.Dropdown
                  withinPortal={false}
                  position="bottom-start"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <Combobox.Options>
                    {usersDropdown.map((data) => (
                      <Combobox.Option value={data.id} key={data.id}>
                        <Flex
                          align={"center"}
                          gap={10}
                          justify={"space-between"}
                        >
                          <Text>{data.fullName}</Text>
                          <Flex gap={5}>
                            {data.relationWarning && (
                              <IoIosWarning color={Color.Error} />
                            )}
                            {data.shiftWarning && (
                              <LuTimerOff color={Color.Error} />
                            )}
                            {data.overlapWarning && (
                              <BsBuildingFillX color={Color.Error} />
                            )}
                          </Flex>
                        </Flex>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>
            )}

            {service.isAttendanceExplicit ? (
              <Flex align={"center"} gap={20}>
                <Text variant={TextVariant.Caption}>
                  <Trans>Število oseb</Trans>
                </Text>
                <NumberInput
                  value={selectedService.attendance}
                  onChange={(val) => {
                    const newSelectedServices = [...selectedServices];
                    newSelectedServices[index].attendance = val;

                    setSelectedServices(newSelectedServices);
                  }}
                  step={1}
                  min={1}
                  size={"xs"}
                  styles={{
                    input: {
                      background: "rgba(140, 140, 140, 0.082)",
                      border: "none",
                      outline: "none",
                    },
                  }}
                ></NumberInput>
              </Flex>
            ) : null}

            <Flex mt="sm" gap={".5rem"} wrap={"wrap"} justify={"space-between"}>
              {!isPreScheduled && (
                <>
                  <StepperNumberInput
                    value={selectedService.price || 0}
                    onChange={(val) => {
                      const newSelectedServices = [...selectedServices];
                      newSelectedServices[index].price = val;

                      setSelectedServices(newSelectedServices, false, false);
                    }}
                    step={1}
                    min={0}
                    style={{ width: "100%" }}
                    label={t`Cena`}
                    size={"xs"}
                    unitText={returnCurrencySymbol({
                      currency: calendarData.currency || "EUR",
                    })}
                    disabled={
                      !!permissions.restrict_custom_price ||
                      isFeatureEnabled("TAX_REGISTER") ||
                      !permissions.manage_appointments
                    }
                  />
                  {isFeatureEnabled("TAX_REGISTER") && (
                    <StepperNumberInput
                      value={discountedPrice || 0}
                      step={1}
                      min={0}
                      style={{ width: "100%" }}
                      label={t`Cena s popustom`}
                      size={"xs"}
                      unitText={returnCurrencySymbol({
                        currency: calendarData.currency || "EUR",
                      })}
                      disabled={true}
                    />
                  )}

                  <Flex align={"end"} gap={"5px"} w={"100%"}>
                    <NumberInput
                      value={selectedService.discountAmount || 0}
                      onChange={(val) => {
                        const newSelectedServices = [...selectedServices];
                        newSelectedServices[index].discountAmount = val;

                        setSelectedServices(newSelectedServices, false, false);
                      }}
                      step={1}
                      min={0}
                      max={
                        selectedService.discountType === "amount"
                          ? selectedService.price
                          : 100
                      }
                      error={discountInputError}
                      style={{ width: "100%", flex: 1 }}
                      label={t`Popust`}
                      size={"xs"}
                      variant="filled"
                      unitText={returnCurrencySymbol({
                        currency: calendarData.currency || "EUR",
                      })}
                      disabled={
                        !!permissions.restrict_custom_price ||
                        !permissions.manage_appointments
                      }
                      styles={{
                        section: {
                          fontSize: "14px",
                          color: "#6b7280",
                          paddingRight: "10px",
                        },
                      }}
                    />

                    <LimeSelect
                      value={selectedService.discountType}
                      styles={{
                        label: {
                          fontSize: "10px",
                        },
                      }}
                      maw={"65px"}
                      variant="filled"
                      onChange={(val) => {
                        const newSelectedServices = [...selectedServices];
                        newSelectedServices[index].discountType = val;

                        setSelectedServices(newSelectedServices, false, false);
                      }}
                      data={[
                        {
                          value: "amount",
                          label: returnCurrencySymbol({
                            currency: calendarData.currency || "EUR",
                          }),
                        },
                        { value: "percentage", label: `%` },
                      ]}
                      size="xs"
                    />
                  </Flex>

                  {/* <StepperNumberInput
                    value={selectedService.discount || 0}
                    onChange={(val) => {
                      const newSelectedServices = [...selectedServices];
                      newSelectedServices[index].discount = val;

                      setSelectedServices(newSelectedServices, false, false);
                    }}
                    step={1}
                    min={0}
                    style={{ width: "100%" }}
                    label={t`Popust`}
                    size={"xs"}
                    unitText={returnCurrencySymbol({
                      currency: calendarData.currency || "EUR",
                    })}
                    disabled={!!permissions.restrict_custom_price}
                  /> */}
                </>
              )}

              {startDuration != null && (
                <StepperNumberInput
                  value={selectedService.startDuration}
                  onChange={(val) => {
                    const newSelectedServices = [...selectedServices];
                    newSelectedServices[index].startDuration = val;
                    setSelectedServices(
                      newSelectedServices,
                      service.isExtraTimeAllowed ? true : false,
                      false,
                    );
                  }}
                  step={5}
                  min={1}
                  label={hasOnlyOneInput ? t`Čas trajanja` : t`Začetni čas`}
                  unitText={"min"}
                  style={{ width: "100%" }}
                  size={"xs"}
                  disabled={
                    !!permissions.restrict_custom_duration ||
                    !permissions.manage_appointments
                  }
                />
              )}

              {timeOffDuration != null && (
                <StepperNumberInput
                  value={selectedService.timeOffDuration}
                  onChange={(val) => {
                    const newSelectedServices = [...selectedServices];
                    newSelectedServices[index].timeOffDuration = val;
                    setSelectedServices(
                      newSelectedServices,
                      service.isExtraTimeAllowed ? true : false,
                      false,
                    );
                  }}
                  step={5}
                  min={1}
                  label={t`Čas brez osebe`}
                  unitText={"min"}
                  style={{ width: "100%" }}
                  size={"xs"}
                  disabled={!!permissions.restrict_custom_duration}
                />
              )}

              {endDuration != null && (
                <StepperNumberInput
                  value={selectedService.endDuration}
                  onChange={(val) => {
                    const newSelectedServices = [...selectedServices];
                    newSelectedServices[index].endDuration = val;
                    setSelectedServices(
                      newSelectedServices,
                      service.isExtraTimeAllowed ? true : false,
                      false,
                    );
                  }}
                  step={5}
                  min={1}
                  label={hasOnlyOneInput ? t`Čas trajanja` : t`Končni čas`}
                  unitText={"min"}
                  style={{ width: "100%" }}
                  size={"xs"}
                  disabled={!!permissions.restrict_custom_duration}
                />
              )}
            </Flex>
            {isFeatureEnabled("SERVICE_BLOCK_BEFORE_AFTER") && (
              <Flex gap={"0.5rem"} justify={"space-between"} wrap={"wrap"}>
                <StepperNumberInput
                  value={selectedService.blockBeforeMins}
                  onChange={(val) => {
                    const newSelectedServices = [...selectedServices];
                    newSelectedServices[index].blockBeforeMins = val;
                    setSelectedServices(newSelectedServices);
                  }}
                  step={5}
                  min={0}
                  label={t`Blokiraj pred`}
                  unitText={"min"}
                  style={{ width: "100%" }}
                  size={"xs"}
                />

                <StepperNumberInput
                  value={selectedService.blockAfterMins}
                  onChange={(val) => {
                    const newSelectedServices = [...selectedServices];
                    newSelectedServices[index].blockAfterMins = val;
                    setSelectedServices(newSelectedServices);
                  }}
                  step={5}
                  min={0}
                  label={t`Blokiraj po`}
                  unitText={"min"}
                  style={{ width: "100%" }}
                  size={"xs"}
                />
              </Flex>
            )}
          </Flex>
        </Collapse>
        {selectedServices.length > 1 && (
          <Button
            onClick={removeServiceFromLinked}
            leftIcon={<LuTrash />}
            variant="outline"
            color="red"
            size="xs"
            w={"100%"}
            my={5}
            style={{ display: "block" }}
          >
            {selectedService.removed ? (
              <Trans>Razveljavi</Trans>
            ) : (
              <Trans>
                {" "}
                <LuTrash style={{ marginRight: "5px" }} />
                Odstrani
              </Trans>
            )}
          </Button>
        )}
      </Flex>
    </div>
  );
};

export default SelectedServiceListItem;
