import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { EasyNumberInput } from "@/Components/EasyNumberInput";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { LimeTextDisplay } from "@/Components/LimeTextDisplay";
import useFeature from "@/hooks/useFeature";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Collapse, Divider, Flex, SegmentedControl, Text } from "@mantine/core";
import type { UseFormReturnType } from "@mantine/form";
import { TimeStepButton } from "../Components/TimeStepButton";
import type { FormValues } from "../ManageService";

const generateTimeSteps = ({
  minDuration,
  maxDuration,
  maxSteps = 4,
}: {
  minDuration: number;
  maxDuration: number;
  maxSteps?: number;
}) => {
  // Calculate the maximum number of steps that fit within the range
  const possibleSteps = Math.floor((maxDuration - minDuration) / minDuration);

  // Limit the number of steps to the lesser of possibleSteps and maxSteps
  const stepsCount = Math.min(possibleSteps, maxSteps - 1); // Subtract 1 to include the maxDuration as a step

  // Generate time steps
  const timeSteps: number[] = [];
  for (let i = 0; i < stepsCount; i++) {
    timeSteps.push(minDuration * (i + 1));
  }
  // timeSteps.push(maxDuration); // Always include the maxDuration as the last step

  return timeSteps;
};

export const DurationTab = ({
  form,
}: {
  form: UseFormReturnType<FormValues>;
}) => {
  const { isFeatureEnabled } = useFeature();

  const isDynamicDurationEnabled = isFeatureEnabled("SERVICE_DYNAMIC_DURATION");
  const isBlockBeforeAfterEnabled = isFeatureEnabled(
    "SERVICE_BLOCK_BEFORE_AFTER",
  );
  const isTimeoffEnabled = isFeatureEnabled("TIMEOFF");

  const selectedType =
    isDynamicDurationEnabled && form.values.isExtraTimeAllowed
      ? "dynamic"
      : "fixed";

  const handsOffDurationLeft =
    form.values.baseDuration -
    (form.values.handsOffStart + form.values.handsOffDuration);

  return (
    <Flex direction={"column"} pb={100}>
      {isDynamicDurationEnabled ? (
        <>
          <SegmentedControl
            mx={"md"}
            h={40}
            data={[
              {
                label: t`Klasično trajanje`,
                value: "fixed",
              },
              {
                label: t`Dinamično trajanje`,
                value: "dynamic",
              },
            ]}
            value={selectedType}
            onChange={(value) => {
              form.setFieldValue("isExtraTimeAllowed", value === "dynamic");
            }}
          />
          <Divider my={"md"} />
        </>
      ) : (
        ""
      )}

      <Collapse in={selectedType === "dynamic"}>
        <Text variant={TextVariant.Caption} c={Color.SecondaryText} px={"md"}>
          <Trans>
            Določite lahko, da stranka izbere različne dolžine trajanja
            storitve.
          </Trans>
        </Text>
        <Divider my={"md"} />
      </Collapse>

      <EasyNumberInput
        {...form.getInputProps("baseDuration")}
        min={0}
        px={"md"}
        label={t`Trajanje storitve (min)`}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        data-identifier="base-duration"
      />
      <Collapse in={selectedType === "dynamic"}>
        <EasyNumberInput
          mt={"md"}
          {...form.getInputProps("extraTimeMax")}
          min={0}
          px={"md"}
          label={t`Max. čas trajanja (min)`}
        />
      </Collapse>

      <Divider my={"md"} />

      <Collapse in={selectedType === "dynamic"}>
        <EasyNumberInput
          {...form.getInputProps("extraTimeStep")}
          min={0}
          px={"md"}
          label={t`Časovni korak (min)`}
          // toolTip={t`Placeholder`}
        />
        <Flex mt={"xs"} px={"md"} gap={"xs"}>
          {generateTimeSteps({
            minDuration: form.values.baseDuration,
            maxDuration: form.values.extraTimeMax || form.values.baseDuration,
            maxSteps: 4,
          }).map((timeStep, i) => (
            <TimeStepButton
              key={i}
              text={`${timeStep.toString()} min`}
              onClick={() => form.setFieldValue("extraTimeStep", timeStep)}
              selected={form.values.extraTimeStep === timeStep}
            />
          ))}
        </Flex>
        <Divider my={"md"} />
      </Collapse>

      {isBlockBeforeAfterEnabled ? (
        <>
          <LimeSwitch
            {...form.getInputProps("blockTimeEnabled", {
              type: "checkbox",
            })}
            label={t`Blokiraj termin`}
            toolTip={t`Blokirajte, koliko časa pred ali po izvajanju termina se lahko stranke naročijo na novega.`}
            px={"md"}
            labelPosition="left"
            size="md"
          />

          <Collapse in={form.values.blockTimeEnabled}>
            <EasyNumberInput
              {...form.getInputProps("blockBefore")}
              min={0}
              px={"md"}
              label={t`Pred terminom (min)`}
              mt={"md"}
            />
            <EasyNumberInput
              {...form.getInputProps("blockAfter")}
              min={0}
              px={"md"}
              label={t`Po terminu (min)`}
              mt={"md"}
            />
            <Text px={"md"} variant={TextVariant.BodyEmphasized} mt={"14px"}>
              <Trans>Skupaj trajanje storitve</Trans>
            </Text>
            <LimeTextDisplay
              mt={"10px"}
              px={"md"}
              text={`${
                form.values.baseDuration +
                form.values.blockBefore +
                form.values.blockAfter
              } min`}
            />
          </Collapse>

          <Divider my={"md"} />
        </>
      ) : (
        ""
      )}

      {isTimeoffEnabled && (
        <>
          <LimeSwitch
            {...form.getInputProps("handsOffEnabled", {
              type: "checkbox",
            })}
            label={t`Trajanje storitve brez izvajalca`}
            toolTip={t`Določite, koliko časa zahteva storitev prisotnost izvajalca in koliko časa je ta lahko med izvajanjem te storitve prost ter tako na voljo za druge storitve.`}
            px={"md"}
            labelPosition="left"
            size="md"
          />

          <Collapse in={form.values.handsOffEnabled}>
            <EasyNumberInput
              {...form.getInputProps("handsOffStart")}
              min={0}
              px={"md"}
              label={t`Začetno trajanje z izvajalcem (min)`}
              mt={"md"}
              max={form.values.baseDuration - form.values.handsOffDuration}
            />
            <EasyNumberInput
              {...form.getInputProps("handsOffDuration")}
              min={0}
              px={"md"}
              label={t`Trajanje brez izvajalca (min)`}
              mt={"md"}
              max={form.values.baseDuration - form.values.handsOffStart}
            />
            <Collapse
              in={
                form.values.handsOffStart !== 0 ||
                form.values.handsOffDuration !== 0
              }
            >
              <Text px={"md"} variant={TextVariant.BodyEmphasized} mt={"14px"}>
                <Trans>Preostanek trajanja</Trans>
              </Text>
              <LimeTextDisplay
                mt={"10px"}
                px={"md"}
                text={`${handsOffDurationLeft} min`}
              />
            </Collapse>
          </Collapse>
        </>
      )}
    </Flex>
  );
};
