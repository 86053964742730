import { t } from "@lingui/core/macro";
import { GetAppointmentLog } from "@/server-types";

export const generateLogTitle = (
  log: GetAppointmentLog["response"]["logs"][0],
) => {
  if (log.table === "appointment") {
    if (log.action === "update") {
      return log.old_data.startTimeUtc !== log.new_data.startTimeUtc
        ? t`Termin prenaročen`
        : t`Termin posodobljen`;
    }
    if (log.action === "insert") return t`Termin ustvarjen`;
    //if (log.action === "delete") return t`Termin odstranjen`;
  } else if (log.table === "user_appointment") {
    if (log.action === "insert") return t`Stranka dodana`;
    if (log.action === "delete") return t`Stranka odstranjena`;
    if (log.action === "update") return t`Termin posodobljen`;
  }
  return t`Termin spremenjen`;
};

export const generateDescription = (
  log: GetAppointmentLog["response"]["logs"][0],
) => {
  const changes: string[] = [];
  if (log.table === "appointment" && log.action === "insert") {
    changes.push(
      t`Termin je bil dodan za storitev ${log.new_data.serviceNameLocalized} dne ${log.new_data.startTimeLocal}. Trajanje termina je ${log.new_data.duration} minut`,
    );
  }
  if (log.table === "appointment" && log.action === "update") {
    if (log.old_data.startTimeLocal !== log.new_data.startTimeLocal) {
      changes.push(
        t`Začetni čas termina je bil spremenjen iz ${log.old_data.startTimeLocal} na ${log.new_data.startTimeLocal}`,
      );
    }
    if (
      log.old_data.serviceNameLocalized !== log.new_data.serviceNameLocalized
    ) {
      changes.push(
        t`Storitev je bila spremenjena iz ${log.old_data.serviceNameLocalized} na ${log.new_data.serviceNameLocalized}`,
      );
    }
    if (log.old_data.duration !== log.new_data.duration) {
      changes.push(
        t`Trajanje termina je bilo spremenjeno iz ${log.old_data.duration} na ${log.new_data.duration}`,
      );
    }
  }

  if (log.table === "user_appointment") {
    if (log.action === "insert") {
      return t`Stranka ${log.new_data.customer.name} ${log.new_data.customer.lastName} je bila dodana na termin.`;
    }
    if (log.action === "delete") {
      return t`Stranka ${log.new_data.customer.name} ${log.new_data.customer.lastName} je bila odstranjena s termina.`;
    }
    if (log.action === "update") {
      if (log.old_data.price !== log.new_data.price) {
        changes.push(
          t`Cena termina je bila spremenjena iz ${log.old_data.price} na ${log.new_data.price}`,
        );
      }
      if (log.old_data.comment !== log.new_data.comment) {
        changes.push(
          t`Komentar termina za stranko ${log.new_data.customer.name} ${log.new_data.customer.lastName} je bil spremenjen na "${log.new_data.comment}"`,
        );
      }
      if (log.old_data.paymentStatus !== log.new_data.paymentStatus) {
        changes.push(
          t`Status plačila termina je bil spremenjen iz "${getPaymentStatusText(log.old_data.paymentStatus)}" na "${getPaymentStatusText(log.new_data.paymentStatus)}"`,
        );
      }
    }
  }

  return changes.join(". ") + ".";
};

export function getPaymentStatusText(status: string | null): string {
  switch (status) {
    case "canceled":
      return t`Preklicano`;
    case "requires_payment_method":
      return t`Plačilo zahtevano`;
    case "refunded":
      return t`Povrnjeno`;
    case "succeeded":
      return t`Plačilo uspešno`;
    default:
      return t`Neznan status`;
  }
}

export const generateAdditionalInfo = (
  log: GetAppointmentLog["response"]["logs"][0],
) => {
  return log.user?.name
    ? t`${log.timestampLocalized} ${log.user.name} ${log.user.lastName}`
    : t`${log.timestampLocalized} stranka`;
};
