import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useLingui } from "@lingui/react";
import { Divider, LoadingOverlay, Skeleton, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { RiArrowUpDownFill } from "react-icons/ri";
import {
  Bar,
  BarChart,
  LabelList,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { useTabGeneral } from "../hooks/useTabGeneral";

import { ContentType } from "recharts/types/component/Label";
import { AnalyticsCarousel } from "./AnalyticsCarousel";
import { BarGraph } from "./BarGraph";
import { BoxValues } from "./BoxValues/BoxValues";
import { LineGraph } from "./LineGraph";

import {
  formatCurrency,
  returnCurrencySymbol,
} from "../../../../../../shared/utils/utils";
import usePreferredLanguageStore from "@/stores/usePreferredLanguageStore";

export const TabGeneral = () => {
  const {
    dataPerDate,
    dataPerDay,
    helperDataPerDate,
    generalData,
    isLoading,
    currency,
  } = useTabGeneral();
  useLingui();

  const isDesktop = useMediaQuery("(min-width:768px)");

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);

  const customDayNameLabel = ({
    x,
    y,
    value,
  }: {
    x: number;
    y: number;
    value: string;
  }) => {
    return (
      <g>
        <text
          x={x}
          y={y}
          dy={-7}
          fill="#8c8c8c"
          fontSize={10}
          textAnchor="left"
          className="labelList"
        >
          {value}
        </text>
      </g>
    );
  };

  const customDayValueLabel = ({
    x,
    y,
    value,
  }: {
    x: number;
    y: number;
    value: number;
  }) => {
    return (
      <g>
        <text
          x={x}
          y={y}
          dy={20}
          dx={10}
          fill="white"
          fontSize={12}
          textAnchor="left"
          className=""
        >
          {formatCurrency({
            amount: value,
            currency,
            locale: preferredLanguage.userPreferredLanguage,
          })}
        </text>
      </g>
    );
  };

  const customDateTooltip = ({
    active,
    payload,
  }: {
    active: boolean;
    payload: { payload: { [key: string]: number } }[];
  }) => {
    if (!active || !payload || payload.length < 1) return;

    const [data] = payload;

    const dateData = data.payload;

    const { date } = dateData;

    const dateText = dayjs(date, "DD. MM.").format("DD. MMMM");

    const revenue =
      dateData[t`Izbrano obdobje`] ??
      (dateData[t`Prihodnje obdobje`] as number);
    const revenueLast = dateData[t`Preteklo obdobje`] as number;

    const topText = `${dateText}`;

    const percentChange = () => {
      if (!revenue || !revenueLast) return;

      const percent = (((revenue - revenueLast) / revenueLast) * 100)
        .toFixed(2)
        .replaceAll(".", ",");
      return (
        <div className="percent_change">
          <RiArrowUpDownFill></RiArrowUpDownFill>
          {percent} %
        </div>
      );
    };

    return (
      <div className="date_tooltip_wrap">
        <label className="date">{topText}</label>
        <Divider opacity={1} w={"100%"} color="#F0F0F0"></Divider>
        <div className="revenue_wrap">
          <label className="revenue">
            {formatCurrency({
              amount: revenue,
              currency,
              locale: preferredLanguage.userPreferredLanguage,
            })}
          </label>
          {percentChange()}
        </div>
      </div>
    );
  };

  const charts = [
    {
      label: t`Linearni prikaz prihodkov`,
      chart: (
        <LineGraph
          data={dataPerDate}
          helperData={helperDataPerDate}
          customTooltip={customDateTooltip}
          height={400}
          isCurrency={true}
          tooltipSuffix={returnCurrencySymbol({ currency })}
          lines={
            <>
              <Line
                type="bump"
                dataKey={t`Izbrano obdobje`}
                stroke="#548F68"
                dot={false}
                strokeWidth={3}
                animationEasing="ease-out"
              />
              <Line
                type="bump"
                dataKey={t`Preteklo obdobje`}
                stroke="#BFBFBF"
                dot={false}
                strokeDasharray={"5 5"}
                strokeWidth={1}
                animationEasing="ease-out"
              />
              <Line
                type="bump"
                dataKey={t`Prihodnje obdobje`}
                stroke="#7E96CD"
                dot={false}
                strokeDasharray={"5 5"}
                strokeWidth={3}
                animationEasing="ease-out"
                connectNulls={true}
              />
            </>
          }
        ></LineGraph>
      ),
    },
    {
      label: t`Stolpični prikaz prihodkov`,
      chart: (
        <BarGraph
          data={dataPerDate}
          helperData={helperDataPerDate}
          customTooltip={customDateTooltip}
          height={400}
          isCurrency={true}
          tooltipSuffix={returnCurrencySymbol({ currency })}
          bars={
            <>
              <Bar
                dataKey={t`Izbrano obdobje`}
                fill="#548F68"
                animationEasing="ease-out"
              />

              <Bar
                dataKey={t`Prihodnje obdobje`}
                fill="#7E96CD"
                animationEasing="ease-out"
                hide={!helperDataPerDate.firstFuture}
              />
            </>
          }
        ></BarGraph>
      ),
    },
  ];

  return (
    <Wrapper>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <div className="perDay">
        <div className="perDayInner">
          {dataPerDay ? (
            <ResponsiveContainer width={"100%"} height={400}>
              <BarChart
                data={dataPerDay}
                layout="vertical"
                margin={{ right: 0, top: 20 }}
              >
                <XAxis type="number" hide />
                <YAxis dataKey="dayId" type="category" hide />

                <Bar
                  dataKey="Prihodek"
                  fill="#548F68"
                  background={{ fill: "#9BC2A5" }}
                  barSize={30}
                  animationEasing="ease-in-out"
                >
                  <LabelList
                    dataKey={"dayName"}
                    position={"top"}
                    content={customDayNameLabel as ContentType}
                  ></LabelList>
                  <LabelList
                    dataKey={"Prihodek"}
                    fill="white"
                    position={"insideLeft"}
                    offset={10}
                    content={customDayValueLabel as ContentType}
                  ></LabelList>
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Skeleton h="100%" w="100%"></Skeleton>
          )}
        </div>
        <Text size={"12px"} fw={500} lh={"16px"} c="#8C8C8C" mt={10}>
          <Trans>Povprečni prihodki po dnevih</Trans>
        </Text>
      </div>
      <div className="perDate">
        <AnalyticsCarousel
          charts={charts}
          isLoaded={dataPerDate && dataPerDate.length > 0}
          indicatorPosition="under"
          gap={isDesktop ? 10 : 0}
        ></AnalyticsCarousel>
      </div>
      <div className="total">
        <Divider opacity={0.5}></Divider>
        <span>
          <Trans>Splošna analitika</Trans>
        </span>
        {generalData ? (
          <BoxValues data={generalData}></BoxValues>
        ) : (
          <Skeleton h="100%" w="100%"></Skeleton>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: "perDate" "total" "perDay";
  width: 100%;

  grid-template-columns: 100%;

  column-gap: 50px;
  row-gap: 50px;

  @media (min-width: 1000px) {
    grid-template-areas: "perDay perDate" "total total";

    grid-template-columns: 30% calc(70% - 50px);
    column-gap: 50px;
    row-gap: 25px;
  }

  @media (min-width: 1250px) {
    grid-template-columns: calc(25% - 50px) 75%;
  }

  @media (min-width: 1650px) {
    grid-template-columns: calc(20% - 50px) 80%;
  }

  .perDay {
    grid-area: perDay;

    padding: 18px;
    padding-right: 5px;

    background-color: #f5f5f5;
    border-radius: 10px;

    height: fit-content;

    .perDayInner {
      height: 420px;

      overflow-x: hidden;
      overflow-y: scroll;

      padding-right: 12px;
    }

    @media (min-width: 1000px) {
      .perDayInner {
        height: 300px;
      }
    }
  }

  .perDate {
    grid-area: perDate;

    width: 100%;

    @media (min-width: 1000px) {
      width: 100%;
    }
  }

  .total {
    grid-area: total;

    display: flex;
    flex-direction: column;

    gap: 26px;

    > span {
      font-size: 20px;
      font-weight: 500;

      color: #262626;
    }
  }

  .date_tooltip_wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;

    background-color: white;
    padding: 5px 10px;
    box-shadow: 0px 5px 20px 0px rgba(166, 166, 166, 0.3);

    border-radius: 10px;

    .date {
      font-size: 12px;
      font-weight: 500;

      color: #8c8c8c;
    }

    .revenue_wrap {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .revenue {
        font-size: 14px;
        font-weight: 500;

        color: #262626;
      }

      .percent_change {
        display: flex;
        align-items: center;
        gap: 2px;
        color: #7e96cd;

        font-size: 12px;
      }
    }
  }
`;
