import { useLingui } from "@lingui/react";
import { Flex, HoverCard, Text } from "@mantine/core";
import { RiCalendar2Line, RiUserLine } from "react-icons/ri";
import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";
import { HoverContent } from "./AppointmentBlock/HoverContent";

import { TextVariant } from "@/types/text-variants";
const MonthAppointmentBlock = ({ appointment }) => {
  const {
    setSelectedAppointmentId,
    setHideModalTabs,
    services,
    users,
    resources,
  } = useCalendarStore((state) => state);

  const { serviceColor, customers, isPreScheduled } = appointment;

  return (
    <Wrapper color={serviceColor}>
      <HoverCard
        position={"right-start"}
        zIndex={55}
        radius={1}
        disabled={window.innerWidth < 768}
      >
        <HoverCard.Target>
          <BlockWrapper
            color={serviceColor}
            isShown={true}
            isPreScheduled={isPreScheduled}
            onClick={(e) => {
              e.stopPropagation();

              setHideModalTabs(true);
              setSelectedAppointmentId(appointment.appointmentId);
            }}
            isPreScheduledFull={
              isPreScheduled &&
              appointment.customers.length >= appointment.numberOfPeople
            }
          >
            <div className={"content"}>
              <div className={"customers"}>
                {!isPreScheduled &&
                  customers?.map((customer, index) => {
                    return (
                      <Flex align={"center"} key={index}>
                        {customer.bookedFromForm ? (
                          <RiCalendar2Line />
                        ) : (
                          <RiUserLine style={{ fontWeight: 500 }} />
                        )}
                        <Text variant={TextVariant.Caption}>
                          {customer?.name ?? ""} {customer?.lastName ?? ""}
                        </Text>
                      </Flex>
                    );
                  })}
                {isPreScheduled ? (
                  <Flex align={"center"} gap={"3px"}>
                    <Text variant={TextVariant.Caption} fw={600}>
                      {appointment.customers.length}/
                      {appointment.numberOfPeople}
                    </Text>
                    <Text variant={TextVariant.Caption} fw={500}>
                      {appointment.serviceName}
                    </Text>
                  </Flex>
                ) : null}
              </div>
            </div>
          </BlockWrapper>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <HoverContent
            appointment={appointment}
            viewCustomersPermission={appointment.permission}
            resources={resources}
            services={services}
            users={users}
          />
        </HoverCard.Dropdown>
      </HoverCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .mantine-HoverCard-dropdown {
    border-left: 6px solid ${({ color }) => color} !important;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }
`;

const BlockWrapper = styled.div`
  height: 25px;
  width: 100%;
  background: white;
  position: relative;
  padding-left: 6px;
  cursor: pointer;

  ${({ isPreScheduled, color, isPreScheduledFull }) =>
    isPreScheduled &&
    `
    border-width: 2px 2px 2px 0px;
    border-style: ${isPreScheduledFull ? "solid" : "dashed"};
    border-color: ${color};
    border-radius: 12px;
  `};

  .content {
    font-size: 0.7rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
    pointer-events: none;

    .customers {
      padding-top: ${(props) => (props.isPreScheduled ? "3px" : "5px")};
      display: flex;
      flex-direction: column;
      gap: 5px;

      .customer {
        display: flex;
        align-items: center;

        font-size: 0.8rem;

        svg {
          flex-shrink: 0;
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    border-left: ${(props) =>
      props.isShown ? "5px solid " + props.color : ""};

    background: ${(props) => (props.isShown ? `${props.color}70` : "#eaebea")};
    ${({ isPreScheduled, color }) =>
      isPreScheduled &&
      `
        background: ${color}10;
  `};

    z-index: 1;
  }
`;

export default MonthAppointmentBlock;
