import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { SUPPORTED_LANGUAGES } from "@/stores/usePreferredLanguageStore";
import { i18n } from "@lingui/core";
import { Box, Button, Divider, Drawer, Flex, Text } from "@mantine/core";
import { ChangeEvent, useEffect, useState } from "react";
import { LimeInput } from "./NextBase/LimeInput";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";

export type LocalizedArray = {
  language: (typeof SUPPORTED_LANGUAGES)[number];
  value: string;
}[];

export const LanguageSettingDrawer = ({
  localized,
  opened,
  save,
  cancel,
  inputLabel,
}: {
  localized: LocalizedArray;
  opened: boolean;
  save: (localized: LocalizedArray) => void;
  cancel: () => void;
  inputLabel: string;
}) => {
  const [tempLocalized, setTempLocalized] = useState(localized);

  useEffect(() => {
    setTempLocalized(localized);
  }, [localized]);

  const firstLanguage = i18n.locale;
  const sortedLanguages = tempLocalized.sort((a, b) =>
    a.language === firstLanguage ? -1 : b.language === firstLanguage ? 1 : 0,
  );

  return (
    <Drawer
      opened={opened}
      position="bottom"
      onClose={cancel}
      title={t`Nastavitve prevodov`}
      overlayProps={{ backgroundOpacity: 0.2, blur: 1 }}
      styles={{
        content: {
          borderTopLeftRadius: "var(--mantine-radius-lg)",
          borderTopRightRadius: "var(--mantine-radius-lg)",
        },
        body: {
          paddingBottom: 0,
        },
      }}
      removeScrollProps={{
        allowPinchZoom: true,
      }}
      size={"450px"}
    >
      <Flex direction={"column"} justify={"space-between"} h={"370px"}>
        <div className="mt-4 flex flex-col gap-4 overflow-y-auto">
          {sortedLanguages?.map((languageRow, index) => (
            <LimeInput
              variant="bordered"
              key={index}
              label={
                <Text>
                  <Text
                    span
                    c={Color.PrimaryText}
                    variant={TextVariant.BodyEmphasized}
                  >
                    {inputLabel}
                  </Text>{" "}
                  <Text
                    span
                    c={Color.SecondaryText}
                    variant={TextVariant.Caption}
                  >
                    ({languageRow.language.toUpperCase()})
                  </Text>
                </Text>
              }
              value={languageRow.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;

                const newObject = tempLocalized.map((localized) =>
                  localized.language === languageRow.language
                    ? { ...localized, value: value }
                    : localized,
                );

                setTempLocalized(newObject);
              }}
            />
          ))}
        </div>

        <div>
          <Divider my={"md"} />

          <div className="flex items-center justify-center gap-4">
            <Button variant="light" onClick={cancel}>
              <Trans>Prekliči</Trans>
            </Button>
            <Button
              onClick={() => {
                save(tempLocalized);
              }}
            >
              <Trans>Shrani</Trans>
            </Button>
          </div>
        </div>
      </Flex>
    </Drawer>
  );
};
