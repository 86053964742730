import { t } from "@lingui/core/macro";
import { GetNotificationSettings } from "../../../../../../server/src/types";

export const TRIGGERS = () => {
  const triggersArray: {
    trigger: GetNotificationSettings["response"]["triggers"][0];

    label: string;
  }[] = [
    { trigger: "onConfirmation", label: t`Ob potrditvi` },
    {
      label: t`Ob odpovedi`,
      trigger: "onCancellation",
    },
    {
      label: t`Ob spremembi termina`,
      trigger: "onReschedule",
    },
    {
      label: t`Pred terminom`,
      trigger: "cronBeforeAppointment",
    },
    {
      label: t`Po terminu`,
      trigger: "cronAfterAppointment",
    },
  ];

  return triggersArray;
};
