import { t } from "@lingui/core/macro";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { Loader } from "@mantine/core";
import React, { useEffect } from "react";
import { MdOutlineAdd } from "react-icons/md";
import LocationScheduleList from "./Components/LocationScheduleList";
import ScheduleViewWrapper from "./Components/ScheduleViewWrapper";
import { useScheduleStore } from "./Stores/useScheduleStore";

const Schedule = () => {
  const [isEditingLocationSchedule, setIsEditingLocationSchedule] =
    React.useState(null);

  const { schedules, getSchedules, selectedScheduleId, selectSchedule } =
    useScheduleStore((state) => state);

  const selectedSchedule = useScheduleStore((state) =>
    state.schedules?.find(
      (schedule) => schedule.scheduleId === state.selectedScheduleId,
    ),
  );

  useEffect(() => {
    getSchedules().catch((err) => console.log(err));
  }, []);

  if (schedules == null) {
    return <Loader />;
  }

  const isLocationSchedulesPage = selectedScheduleId == null;

  return (
    <>
      {selectedScheduleId == null && (
        <PageHeader
          title={selectedSchedule == null && t`Urniki osebja`}
          actionButton={
            isLocationSchedulesPage
              ? {
                  onClick: () => {
                    setIsEditingLocationSchedule({}); // open modal
                  },
                  icon: <MdOutlineAdd size={"1.3rem"} />,
                  label: t`Dodaj urnik`,
                }
              : null
          }
          subPageHeader={
            !isLocationSchedulesPage
              ? {
                  onBackButtonClick: () => selectSchedule(null),
                  title: selectedSchedule?.name,
                }
              : null
          }
        />
      )}

      {selectedScheduleId == null && (
        <LocationScheduleList
          isEditingLocationSchedule={isEditingLocationSchedule}
          setIsEditingLocationSchedule={setIsEditingLocationSchedule}
        />
      )}
      {selectedScheduleId != null && (
        <ScheduleViewWrapper selectedSchedule={selectedSchedule} />
      )}
    </>
  );
};

export default Schedule;
