import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const Integrations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabIndex =
    location.pathname.split("/").findIndex((p) => p === "integrations") + 1;
  const activeTab = location.pathname.split("/")[tabIndex] || "general";

  return (
    <>
      <LimePageHeader title={t`Integracije`} />

      <LimePageTabs
        activeTab={activeTab}
        onChangeTab={async (tab) => {
          if (tab === "general") {
            navigate("/dashboard/integrations");
            return;
          }

          navigate(`/dashboard/integrations/${tab}`);
        }}
        tabs={[
          {
            label: t`Splošno`,
            value: "general",
          },
          {
            label: t`Facebook Conversion API`,
            value: "fbcapi",
            leftSection: (
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
              >
                <title>Facebook</title>
                <path d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z" />
              </svg>
            ),
          },
        ]}
        rightSection={<></>}
      />

      <Outlet />
    </>
  );
};
