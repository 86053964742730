import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { api } from "@/lib/api-client";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export type POSAuthorizationForm = {
  locationId: number | undefined;
  electronicDeviceId: string | undefined;
};

export const POSAuthorizationDialog = ({
  handleSuccessfulAuthorization,
  locationId,
  businessPremiseId,
  authorizedUserId,
}: {
  handleSuccessfulAuthorization: ({
    businessPremiseId,
    electronicDeviceId,
  }: {
    businessPremiseId?: string;
    electronicDeviceId?: string;
  }) => void;
  locationId: number | undefined;
  businessPremiseId?: string;
  authorizedUserId?: number;
}) => {
  const navigate = useNavigate();

  const { data: locations, isFetching: isLocationsFetching } =
    api.location.useGetLocations({
      page: 1,
      perPage: 100,
      sortBy: "labelDesc",
    });

  const form = useForm<POSAuthorizationForm>({
    initialValues: {
      locationId: undefined,
      electronicDeviceId: undefined,
    },

    onValuesChange: (values, previous) => {
      const didLocationChange = values.locationId !== previous.locationId;
      if (didLocationChange) {
        form.setFieldValue("electronicDeviceId", undefined);
      }
    },

    validate: {
      locationId: (value) => {
        if (locations?.locations?.length === 0) {
          return null;
        }

        if ((locations?.locations || []).length > 1 && !value) {
          return t`Izberite lokacijo`;
        }
      },
      electronicDeviceId: (value) => {
        if (electronicDevices?.length === 0)
          return t`Lokacija nima elektronske naprave`;
        if ((electronicDevices || []).length > 1 && !value)
          return t`Izberite elektronsko napravo`;
      },
    },
  });

  const {
    data: electronicDevices,
    isFetching: isElectronicDevicesFetching,
    processedErrorMessage: processedElectronicDevicesErrorMessage,
  } = api.taxRegister.useGetOrganizationElectronicDevice({
    locationId: form.getValues().locationId || undefined,
    userId: authorizedUserId,
    enabled:
      (form.getValues().locationId != null &&
        (locations?.locations || []).length > 1) ||
      (locations?.locations || []).length === 1,
  });

  const { data: organization, isFetching: isOrganizationFetching } =
    api.taxRegister.useGetOrganization({});

  useEffect(() => {
    form.setFieldValue("locationId", locationId);
  }, [locationId]);

  useEffect(() => {
    if (!electronicDevices || electronicDevices.length === 0) return;

    form.setFieldValue("electronicDeviceId", electronicDevices[0].id);
  }, [electronicDevices]);

  useEffect(() => {
    if (
      isLocationsFetching ||
      isElectronicDevicesFetching ||
      !electronicDevices ||
      !locations ||
      !organization
    )
      return;

    const location = locationId
      ? locations?.locations.find((l) => l.locationId === locationId)
      : locations?.locations[0];

    if (
      (locationId || locations.locations.length === 1) &&
      electronicDevices.length === 1
    ) {
      handleSuccessfulAuthorization({
        businessPremiseId:
          location?.BusinessPremise.find(
            (lbp) => lbp.organizationId === organization.id,
          )?.businessPremiseId ?? undefined,
        electronicDeviceId: electronicDevices[0].id ?? undefined,
      });

      return;
    }

    if (!locationId && locations.locations.length === 1) {
      form.setFieldValue("locationId", location?.locationId);
    }
  }, [
    electronicDevices,
    locations,
    organization,
    locationId,
    isLocationsFetching,
    isElectronicDevicesFetching,
  ]);

  const handleAuthorizeModalClose = (open: boolean) => {
    if (!open) {
      navigate("/dashboard/");
    }
  };
  const handleSubmit = async (data: POSAuthorizationForm) => {
    const location = locations?.locations.find(
      (location) => location.locationId == data.locationId,
    );
    handleSuccessfulAuthorization({
      businessPremiseId:
        location?.BusinessPremise.find(
          (lbp) => lbp.organizationId === organization?.id,
        )?.businessPremiseId ?? undefined,
      electronicDeviceId: data.electronicDeviceId ?? undefined,
    });
  };

  return (
    <Modal
      isOpen={!locationId || !businessPremiseId}
      onOpenChange={handleAuthorizeModalClose}
    >
      <ModalContent>
        {(onClose) => (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <ModalHeader>
              <Trans>Avtorizacija</Trans>
            </ModalHeader>

            <ModalBody className="flex flex-col items-center justify-center gap-4">
              <LimeAlert
                color="danger"
                className="w-full"
                message={processedElectronicDevicesErrorMessage}
              />

              {(locations?.locations || []).length > 1 && !locationId && (
                <LimeSelect
                  label={t`Lokacija`}
                  items={
                    locations?.locations.map((location) => ({
                      key: location.locationId.toString(),
                      label: location.label,
                    })) || []
                  }
                  disabledKeys={locations?.locations
                    .filter(
                      (l) =>
                        !l.BusinessPremise.some(
                          (lbp) => lbp.organizationId === organization?.id,
                        ),
                    )
                    .map((l) => l.locationId.toString())}
                  isLoading={isLocationsFetching || isOrganizationFetching}
                  {...form.getInputProps("locationId")}
                />
              )}

              {(electronicDevices || []).length <= 1 &&
                isElectronicDevicesFetching && (
                  <p>
                    <Trans>Nalaganje...</Trans>
                  </p>
                )}

              {(electronicDevices || []).length === 0 &&
                !isElectronicDevicesFetching &&
                form.getValues().locationId != null && (
                  <p className="text-sm font-semibold text-danger-400">
                    <Trans>Ta lokacija nima elektronske naprave</Trans>
                  </p>
                )}

              {(electronicDevices || []).length > 1 && (
                <LimeSelect
                  label={t`Elektronska naprava`}
                  items={
                    electronicDevices?.map((electronicDevice) => ({
                      key: electronicDevice.id,
                      label: electronicDevice.electronicDeviceId,
                    })) || []
                  }
                  isLoading={isElectronicDevicesFetching}
                  {...form.getInputProps("electronicDeviceId")}
                />
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                isDisabled={
                  isElectronicDevicesFetching ||
                  isLocationsFetching ||
                  processedElectronicDevicesErrorMessage != null
                }
                color="primary"
              >
                <Trans>Potrdi</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};
