import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { UseFormReturnType } from "@mantine/form";
import { z } from "zod";
import { api } from "@/lib/api-client";
import dayjs from "dayjs";
import { manageDeliveryOrderSchema } from "./utils";
import { i18n } from "@lingui/core";
import { formatCurrency } from "../../../../../../shared/utils/utils";
import { Button, Divider } from "@nextui-org/react";
import { ProductCard } from "./shared";

export const OverviewTab = ({
  form,
  netPriceTotalWithoutTotalDiscount,
  grossPriceTotalWithoutTotalDiscount,
  handleSubmit,
  isEditing,
}: {
  form: UseFormReturnType<z.infer<typeof manageDeliveryOrderSchema>>;
  netPriceTotalWithoutTotalDiscount: number;
  grossPriceTotalWithoutTotalDiscount: number;
  handleSubmit: (values: z.infer<typeof manageDeliveryOrderSchema>) => void;
  isEditing: boolean;
}) => {
  const { data: locationData, isFetching: areLocationsLoading } =
    api.location.useGetLocations({
      perPage: 100,
      page: 1,
      sortBy: "labelAsc",
    });

  const { data: products } = api.product.useGetProducts({
    productType: "PURCHASE",
  });

  const isLoading = areLocationsLoading || !locationData;

  if (isLoading) return null;

  const location = locationData?.locations.find(
    (l) => l.locationId === Number(form.getValues().locationId),
  );

  const locationLabel = location?.label;

  const { locale } = i18n;

  const netPriceTotalWithoutTotalDiscountFormatted = formatCurrency({
    amount: netPriceTotalWithoutTotalDiscount,
    currency: location?.paymentCurrency || "EUR",
    locale,
  });

  const grossPriceTotalWithoutTotalDiscountFormatted = formatCurrency({
    amount: grossPriceTotalWithoutTotalDiscount,
    currency: location?.paymentCurrency || "EUR",
    locale,
  });

  const taxValue =
    grossPriceTotalWithoutTotalDiscount - netPriceTotalWithoutTotalDiscount;
  const taxValueFormatted = formatCurrency({
    amount: taxValue,
    currency: location?.paymentCurrency || "EUR",
    locale,
  });

  return (
    <form
      className="mt-4 flex w-full flex-1 flex-col gap-4 px-4 md:w-full md:max-w-[450px] md:px-8"
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <div className="mb-4 flex w-full items-center justify-center">
        <p className="text-lg font-medium">
          <Trans>Povzetek dobavnice</Trans>
        </p>
      </div>

      <section className="flex flex-col gap-4">
        <InvoiceDetails
          details={[
            { label: t`Lokacija`, value: locationLabel || "" },
            { label: t`Številka dobavnice`, value: form.getValues().number },
            {
              label: t`Datum dobavnice`,
              value: form.getValues().date
                ? dayjs(form.getValues().date)
                    .toDate()
                    .toLocaleDateString(locale)
                : "",
            },
            {
              label: t`Popust`,
              value: `${form.getValues().totalDiscountPercentage}%`,
            },
            {
              label: t`Neto cena skupaj`,
              value: netPriceTotalWithoutTotalDiscountFormatted,
            },
            {
              label: t`Bruto cena skupaj`,
              value: grossPriceTotalWithoutTotalDiscountFormatted,
            },
            {
              label: t`Vrednost davka`,
              value: taxValueFormatted,
            },
          ]}
        />
      </section>

      <Divider />

      <p className="font-medium">
        <Trans>Nabavni artikli</Trans>
      </p>

      {form.getValues().products.map((product, index) => (
        <ProductCard
          key={product.productId}
          index={index}
          product={product}
          products={products}
          locale={locale}
          currency={location?.paymentCurrency || "EUR"}
        />
      ))}

      <Button type="submit" color="primary">
        <Trans>{isEditing ? "Posodobi" : "Prevzami"}</Trans>
      </Button>
    </form>
  );
};

const InvoiceDetails = ({
  details,
}: {
  details: {
    label: string;
    value: string;
  }[];
}) => {
  return (
    <>
      {details.map(({ label, value }) => (
        <div className="flex justify-between">
          <p className="text-sm font-medium">{label}</p>
          <p>{value}</p>
        </div>
      ))}
    </>
  );
};
