import { Trans } from "@lingui/react/macro";
import { convertMinutesToHHmm } from "@/utils/date-time-utils";
import { Flex, Text, Tooltip } from "@mantine/core";
import styled from "styled-components";

const UserBlock = ({ user, viewType }) => {
  const shouldTruncateWorkerName = user.name.length > 13;

  const renderUserName = () => (
    <UserName lh={"16px"} c={"#424B64"}>
      {user.name} {user.lastName}
    </UserName>
  );

  return (
    <Wrapper>
      <Flex gap={"10px"} h={"100%"} align={"center"} justify={"center"}>
        {user.color && <ColorCircle color={user.color} />}
        <Flex
          align={user?.color == null ? "center" : "flex-start"}
          justify={"center"}
          direction={"column"}
        >
          {shouldTruncateWorkerName ? (
            <Tooltip label={`${user.name} ${user.lastName}`}>
              {renderUserName()}
            </Tooltip>
          ) : (
            renderUserName()
          )}
          <Text size={"12px"} lh={"16px"} c={"#969BA9"}>
            <Trans>Št. ur v</Trans>{" "}
            {viewType === "day" ? <Trans>dnevu</Trans> : <Trans>tednu</Trans>}:{" "}
            {convertMinutesToHHmm(user.workTimeSum)}
          </Text>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const UserName = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px; /* Adjust this value based on your design */
`;

const ColorCircle = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  flex-shrink: 0;
  background: ${(props) => props.color};
`;

export default UserBlock;
