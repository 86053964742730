import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import dayjs from "dayjs";
import { useMemo } from "react";
import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";
import { isInPast } from "../../utils";
import useDrag from "../hooks/useDrag";
import AppointmentsColumn from "./AppointmentsColumn";
import ShiftBlock from "./ShiftBlock";
import { TimeoffColumn } from "./TimeoffColumn";

const UserColumn = ({ user, date, isLastChild, timeoffs, usersInOrder }) => {
  const {
    setSelectedAppointmentId,
    setLastSelectedUserId,
    setLastSelectedTime,
    setLastSelectedDate,
    setLastSelectedResourceId,
    setNewBreakData,
  } = useCalendarStore((state) => state);

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { calendarHeight, slotHeight, allShifts } = useCalendarStore(
    (state) => state.calendarData,
  );

  const shifts = allShifts?.filter(
    (shift) =>
      shift.userId === user.userId &&
      dayjs(date).isBetween(shift.start, shift.end, "day", "[]"),
  );

  const isDateInPast = useMemo(() => isInPast(date), [date]);

  const { calculateTimeForYOffset } = useDrag();

  return (
    <Wrapper className={"userColumn"}>
      <EventColumn
        isLastChild={isLastChild}
        className="eventColumn"
        isInPast={isDateInPast}
        slotHeight={slotHeight}
        height={calendarHeight + 1}
        permission={permissions.manage_appointments}
        onClick={(e) => {
          e.stopPropagation();
          setLastSelectedUserId(user.userId);
          setLastSelectedResourceId(null);
          setSelectedAppointmentId(-1);

          const { currentTarget } = e;
          const rect = currentTarget.getBoundingClientRect();
          const yOffset = e.clientY - rect.top;

          const time = calculateTimeForYOffset(yOffset);
          const splitTime = time.split(":");
          const dateTime = dayjs(date)
            .set("hour", splitTime[0])
            .set("minute", splitTime[1])
            .format("YYYY-MM-DDTHH:mm");

          setLastSelectedTime(dateTime);
          setLastSelectedDate(date);

          setNewBreakData({
            userId: user.userId,
            date: date,
            startTime: time,
          });
        }}
      >
        <AppointmentsColumn
          date={date}
          userId={user.userId}
          usersInOrder={usersInOrder}
        ></AppointmentsColumn>

        {shifts.map((shift) => (
          <ShiftBlock shift={shift} date={date}></ShiftBlock>
        ))}

        {timeoffs && (
          <TimeoffColumn timeoffs={timeoffs} date={date}></TimeoffColumn>
        )}
        <InPastOverlay
          slotHeight={slotHeight}
          isInPast={isDateInPast}
        ></InPastOverlay>
      </EventColumn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  position: relative;

  min-width: 80px;
`;

const InPastOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, transparent 1px, transparent 1px),
    linear-gradient(#fafafa 1px, #fafafa 1px);
  background-size: 100% ${(props) => props.slotHeight}px;

  pointer-events: none;
  opacity: ${(props) => (props.isInPast ? 1 : 0)};

  z-index: -4;
`;

const EventColumn = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  cursor: ${(props) => (props.permission ? "pointer" : "default")};

  position: relative;
  border-right: ${(props) =>
    !props.isLastChild ? "2px dashed #EAEBED" : "none"};
  background-image: linear-gradient(to right, transparent 0px, transparent 1px),
    linear-gradient(lightgrey 1px, transparent 1px);
  background-size: 100% ${(props) => props.slotHeight}px;
`;

export default UserColumn;
