import { t } from "@lingui/core/macro";
import { TIME_UNITS } from "./constants/TimeUnits";

export const minutesToValueAndUnit: (minutesRaw: number) => {
  value: number;
  unit: "hours" | "minutes" | "days";
  unitLabel: string;
} = (minutesRaw: number) => {
  const minutes = Math.abs(minutesRaw);

  for (let i = 0; i < TIME_UNITS().length; i++) {
    const unit = TIME_UNITS()[i];

    if (minutes % unit.rating === 0) {
      return {
        value: minutes / unit.rating,
        unit: unit.unit,
        unitLabel: unit.label,
      };
    }
  }

  return {
    value: minutes,
    unit: "minutes",
    unitLabel: t`Minut`,
  };
};

export const valueAndUnitToMinutes = (
  value: number,
  unit: "hours" | "days" | "minutes",
) => {
  console.log("value", value);
  console.log("unit", unit);

  const unitObject = TIME_UNITS().find((u) => u.unit === unit);

  if (!unitObject) return value;

  return value * unitObject.rating;
};
