import { assignZIndexes } from "@/utils/appointment-utils";
import { useDroppable } from "@dnd-kit/core";
import { useMemo } from "react";
import styled from "styled-components";
import { useCalendarStore } from "../../store/useCalendarStore";
import AppointmentBlock from "./AppointmentBlock/AppointmentBlock";
import dayjs from "dayjs";

const AppointmentsColumn = ({ date, userId, usersInOrder }) => {
  const allAppointments = useCalendarStore(
    (state) => state.calendarData.allAppointments,
  );

  const previousUserId = usersInOrder[usersInOrder.indexOf(userId) - 1];

  const filteredAppointments = useMemo(
    () =>
      (
        allAppointments.filter(
          (appointment) =>
            dayjs(date).isBetween(
              dayjs(`${appointment.date}T${appointment.startTime}`),
              dayjs(`${appointment.date}T${appointment.startTime}`).add(
                appointment.duration,
                "minutes",
              ),
              "day",
              "[]",
            ) && appointment.userIds.includes(userId),
        ) || []
      ).map((a) => {
        //calculate number of next consecutive resources
        const nextAppResources = a.userIds.slice(a.userIds.indexOf(userId));
        const nextResourcesInOrder = usersInOrder.slice(
          usersInOrder.indexOf(userId),
        );

        let numberOfNextUsersInOrder = 0;

        for (
          let i = 0;
          i < Math.min(nextAppResources.length, nextResourcesInOrder.length);
          i++
        ) {
          if (nextAppResources[i] === nextResourcesInOrder[i]) {
            numberOfNextUsersInOrder++;
          } else {
            break;
          }
        }

        return {
          ...a,
          isOnPreviousUser: a.userIds?.includes(previousUserId),
          numberOfNextUsers: numberOfNextUsersInOrder,
        };
      }),
    [allAppointments, date, userId],
  );

  const appointments = useMemo(
    () => assignZIndexes(filteredAppointments),
    [filteredAppointments],
  );

  const { setNodeRef } = useDroppable({
    id: `appointmentsColumn-${date}-${userId}`,
    data: { date, userId },
  });

  return (
    <Wrapper ref={setNodeRef}>
      {appointments
        .filter((a) => !a.isOnPreviousUser)
        .map((appointment, _) => {
          return (
            <AppointmentBlock
              appointment={appointment}
              key={appointment.appointmentId}
              user={userId}
              usersInOrder={usersInOrder}
              columnDate={date}
            ></AppointmentBlock>
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;

  &.hovering {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export default AppointmentsColumn;
