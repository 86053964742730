import { DatePicker, DatePickerProps } from "@nextui-org/react";
import { MantineFormValues } from "./shared.types";
import React from "react";
import { cn } from "@/utils";
import { isMobileSafari } from "react-device-detect";

type Input = MantineFormValues & DatePickerProps;

export const LimeDatePicker = React.forwardRef(
  ({ error, ...props }: Input, ref: React.Ref<HTMLInputElement>) => (
    <DatePicker
      ref={ref}
      errorMessage={error}
      isInvalid={!!error}
      {...props}
      classNames={{
        ...props.classNames,
        input: cn(props.classNames?.input, {
          "text-[16px]": isMobileSafari,
        }),
      }}
    />
  ),
);
LimeDatePicker.displayName = "LimeDatePicker";
