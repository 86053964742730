import { t } from "@lingui/core/macro";
import { Color } from "@/types/colors";
import { Divider, Flex, Skeleton, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useMemo, useState } from "react";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { TextVariant } from "@/types/text-variants";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import classes from "./Graphs.module.css";

export const UsersLineGraphAppointments = ({
  data,
  users,
}: {
  data: { date: string; users: { userId: number; appointments: number }[] }[];
  users: {
    userId: number;
    name: string | null;
    lastName: string | null;
    color: string;
  }[];
}) => {
  const isDesktop = useMediaQuery("(min-width:768px)");

  const [totalSelected, setTotalSelected] = useState(0);

  const customTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          <Text variant={TextVariant.CaptionEmphasized} c={Color.SecondaryText}>
            {label}
          </Text>
          <Divider opacity={1} w={"100%"} color="#F0F0F0"></Divider>
          <div className={classes.content}>
            {payload.map((pl, index) => {
              const user = users.find((u) => u.userId === pl.name);

              if (!user && pl.name !== "avg") return null;

              const label = user
                ? `${user.name} ${user.lastName}`
                : t`Povprečje`;

              const value: number = (pl.value as number) || 0;

              return (
                <div className={classes.row} key={index}>
                  <Text
                    c={pl.stroke}
                    className={classes.rowName}
                    variant={TextVariant.Body}
                  >
                    {label}:
                  </Text>
                  <Text variant={TextVariant.Caption}>{value.toFixed(2)}</Text>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  const dataForChart = useMemo(() => {
    return data.map((d) => {
      const usersForDate: Record<string, number> = {};
      let totalAppointments = 0;

      d.users.forEach((u) => {
        usersForDate[u.userId] = u.appointments;
        totalAppointments += u.appointments;
      });

      usersForDate["avg"] = totalAppointments / d.users.length;

      return { date: d.date, ...usersForDate };
    });
  }, [data]);

  return (
    <>
      {!isDesktop ? (
        <Flex direction={"column"} mb={20}>
          <Flex
            color={Color.PrimaryText}
            mih={24 * 1.55}
            align={"center"}
            gap={5}
          >
            <Text size={"20px"} fw={600} c={Color.PrimaryText} mih={20 * 1.55}>
              {totalSelected}
            </Text>
          </Flex>
          <Text size={"14px"} fw={400} c={Color.SecondaryText} mih={14 * 1.55}>
            Skupaj za izbran dan
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      <ResponsiveContainer width={"100%"} height={400}>
        {dataForChart ? (
          <LineChart
            data={dataForChart}
            margin={{ top: 5, left: 0 }}
            style={{
              fontSize: "12px",
            }}
            onMouseMove={(e) => {
              if (!e.activePayload) return;
              const filteredPayload = e.activePayload.filter(
                (pl) => pl.dataKey !== "avg",
              );
              const totalIncome = filteredPayload.reduce(
                (acc, curr) => acc + curr.value,
                0,
              );

              setTotalSelected(totalIncome);
            }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke="#F0F0F0"
            />
            <XAxis dataKey="date" padding={{ left: 10 }} stroke="#8C8C8C" />
            <YAxis
              tickLine={false}
              stroke="#8C8C8C"
              padding={{ bottom: 5 }}
              width={25}
            />
            <Tooltip content={customTooltip} />

            {users.map((u) => (
              <Line
                key={u.userId}
                type="bump"
                dataKey={u.userId}
                stroke={u.color}
                dot={false}
                strokeWidth={2}
                animationEasing="ease-out"
              />
            ))}

            <Line
              type="bump"
              dataKey="avg"
              stroke={Color.Disabled}
              dot={false}
              strokeWidth={2}
              strokeDasharray={"10 8"}
              animationEasing="ease-out"
            ></Line>
          </LineChart>
        ) : (
          <Skeleton height={400} />
        )}
      </ResponsiveContainer>
    </>
  );
};
