import { api } from "@/lib/api-client";
import {
  Button,
  Divider,
  NumberInput,
  Switch,
  Table,
  Text,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useEffect } from "react";
import type { GetClientFeatures } from "../../../../../../../../server/src/types";
import { TextVariant } from "@/types/text-variants";
import useAccountInfo from "@/hooks/useAccountInfo";

const featuresOrder = [
  "SERVICE_BLOCK_BEFORE_AFTER",
  "SERVICE_ADVANCED_PRICING",
  "SERVICE_DYNAMIC_DURATION",
  "TIMEOFF",
  "RESOURCES",
  "RESOURCES_HIERARCHY",
  "PAYMENTS",
  "PRESCHEDULED",
  "USER_ROLES",
  "C_NOTIFICATION",
  "CHANGELOG",
  "BULK_NOTIF",
  "2FA",
  "INTEGRATION_FACEBOOK_CONVERSION_API",
];

const tempDisabledFeatures = ["RESOURCES_HIERARCHY"];

const FeaturesTab = ({
  selectedClientId,
  refetchPrice,
}: {
  selectedClientId: number;
  refetchPrice: () => void;
}) => {
  const [features, setFeatures] = useState<
    GetClientFeatures["response"]["features"]
  >([]);

  const { data } = api.admin.useClientFeatures({ clientId: selectedClientId });

  const { accountInfo } = useAccountInfo((state) => state);

  const { mutateAsync: saveFeatures } =
    api.admin.useSaveClientFeatures(selectedClientId);

  const handleSaveFeatures = async () => {
    try {
      if (!features) return;

      const payload = features.map((f) => ({
        featureId: f.featureId,
        priceCents: f.priceCents * 100,
        enabled: f.enabled,
      }));

      await saveFeatures(payload);

      refetchPrice();

      notifications.show({
        message: "Posodobljeno",
      });
    } catch (e) {
      notifications.show({
        message: "Napaka pri shranjevanju",
        color: "red",
      });
    }
  };

  useEffect(() => {
    if (features.length > 0) return;
    if (!data) return;

    setFeatures(
      data.features
        .filter((f) => !tempDisabledFeatures.includes(f.featureId))
        .map((f) => ({
          ...f,
          priceCents: f.priceCents / 100,
        })),
    );
  }, [data]);

  const featuresIncludedInTier = features
    .filter((f) => f.isInPricingTier)
    .sort((a, b) => {
      if (featuresOrder.indexOf(a.featureId) === -1) return 1;
      if (featuresOrder.indexOf(b.featureId) === -1) return -1;

      return (
        featuresOrder.indexOf(a.featureId) - featuresOrder.indexOf(b.featureId)
      );
    });

  const featuresNotIncludedInTier = features
    .filter((f) => !f.isInPricingTier)
    .sort((a, b) => {
      if (featuresOrder.indexOf(a.featureId) === -1) return 1;
      if (featuresOrder.indexOf(b.featureId) === -1) return -1;

      return (
        featuresOrder.indexOf(a.featureId) - featuresOrder.indexOf(b.featureId)
      );
    });

  return (
    <div className="flex flex-col gap-5 p-5">
      <div className="flex flex-col gap-2">
        <Text variant={TextVariant.Subheading}>Vključeni v paketu</Text>
        <div className="flex flex-col gap-2">
          {featuresIncludedInTier.map((feature, i) => (
            <div className={"flex gap-2"} key={i}>
              <Switch
                checked={feature.enabled}
                onChange={(e) => {
                  setFeatures((prev) => {
                    if (!prev) return prev;

                    const newFeatures = [...prev];
                    const id = newFeatures.findIndex(
                      (f) => f.featureId === feature.featureId,
                    );

                    newFeatures[id].enabled = e.target.checked;
                    return newFeatures;
                  });
                }}
                label={feature.name}
              ></Switch>
            </div>
          ))}
        </div>
      </div>

      <Divider />

      <Text variant={TextVariant.Subheading}>Ostali</Text>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Feature</Table.Th>
            <Table.Th>Cena</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {featuresNotIncludedInTier.map((feature, i) => (
            <Table.Tr key={i}>
              <Table.Td className="max-w-20">
                <Switch
                  checked={feature.enabled}
                  onChange={(e) => {
                    setFeatures((prev) => {
                      if (!prev) return prev;

                      const newFeatures = [...prev];
                      const id = newFeatures.findIndex(
                        (f) => f.featureId === feature.featureId,
                      );

                      newFeatures[id].enabled = e.target.checked;
                      return newFeatures;
                    });
                  }}
                  label={feature.name}
                ></Switch>
              </Table.Td>
              <Table.Td className="max-w-10">
                <NumberInput
                  value={feature.priceCents}
                  onChange={(value) => {
                    setFeatures((prev) => {
                      if (!prev) return prev;

                      const newFeatures = [...prev];
                      const id = newFeatures.findIndex(
                        (f) => f.featureId === feature.featureId,
                      );

                      newFeatures[id].priceCents = Number(value);
                      return newFeatures;
                    });
                  }}
                ></NumberInput>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Button onClick={handleSaveFeatures} w={"fit-content"} mt={20}>
        Shrani
      </Button>
    </div>
  );
};

export default FeaturesTab;
