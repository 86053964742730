import { t } from "@lingui/core/macro";
import { notifications } from "@mantine/notifications";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import * as paths from "./paths";

export const api = paths;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000,
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(800 * 2 * attemptIndex, 5000),
    },
  },
  queryCache: new QueryCache({
    onError(error, query) {
      if (query.queryKey.includes("getOrganization")) return;
      notifyAndThrow(error);
    },
  }),
});

function notifyAndThrow(err: unknown) {
  const message =
    (err instanceof AxiosError && err.response?.data?.error) ||
    (err instanceof Error && err.message) ||
    t`Prišlo je do sistemske napake`;

  notifications.show({
    title: t`Napaka`,
    message,
    color: "red",
  });
}
