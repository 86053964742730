import { Trans } from "@lingui/react/macro";
import { Flex, Text, Tooltip } from "@mantine/core";
import CalendarDate from "../../../../../Components/CalendarDate";
import { useScheduleStore } from "../../Stores/useScheduleStore";
import { Column } from "../DayWeekSchedule";

const DateRows = ({ dates, disabledDates }) => {
  const { setScheduleDate, setScheduleViewType, selectedScheduleData } =
    useScheduleStore((state) => state);

  return (
    <div className={"date-row"}>
      <div className={"columns date-column"}>
        <Column noRightBorder maxWidth={"300px"} className={"desktop"} />
        {dates.map((date) => {
          const isHoliday = selectedScheduleData.holidays.find((h) => {
            return h.date === date?.format("YYYY-MM-DD");
          });

          return (
            <Column key={date?.format("YYYY-MM-DD")} noRightBorder>
              <Flex justify={"center"} align={"center"} direction={"column"}>
                {isHoliday && (
                  <Tooltip
                    label={isHoliday.name}
                    color="#7e96cd"
                    withArrow
                    withinPortal
                  >
                    <div className="holiday_span">{isHoliday.name}</div>
                  </Tooltip>
                )}
                <CalendarDate
                  isDisabled={disabledDates.includes(date)}
                  date={date}
                  onClick={() => {
                    setScheduleDate(date);
                    setScheduleViewType("day");
                  }}
                />
              </Flex>
            </Column>
          );
        })}
      </div>
      <div className={"user-shift-text"}>
        <Text
          style={{
            fontFamily: "Inter",
            fontWeight: "500",
          }}
        >
          <Trans>Urnik zaposlenih</Trans>
        </Text>
      </div>
    </div>
  );
};

export default DateRows;
