import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { LimeSelect } from "@/Components/LimeSelect";
import { LimeTextInput } from "@/Components/LimeTextInput";
import useFeature from "@/hooks/useFeature";
import { api } from "@/lib/api-client";
import { PostResource } from "@/server-types";
import { Button, Divider, Flex, Loader } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useLocations from "../Locations/useLocations";
import useManageResourceGroup from "./useManageResourceGroup";

type Form = {
  label: string;
  locationId: string | undefined;
  parentResourceId: string | undefined;
};

export const ManageResource = () => {
  const { id: resourceGroupId, resourceId } = useParams();

  const { data: resource } = api.resources.useGetResource(
    resourceId ? parseInt(resourceId) : undefined,
  );

  const { isFeatureEnabled } = useFeature();

  // const { data: resourceGroups, isPending: isResourceGroupsPending } =
  //   api.resourceGroups.useGetResourceGroups({});

  const { data: resources, isPending: isResourcesPending } =
    api.resources.useGetResources({
      locationId: resource?.locationId,
    });

  const { handleBackButton } = useManageResourceGroup();

  const { mutateAsync: postResource, isPending: isPostResourcePending } =
    api.resources.usePostResource();

  const {
    locations,
  }: {
    locations: {
      locationId: number;
      label: string;
    }[];
  } = useLocations();

  const form = useForm<Form>({
    initialValues: {
      label: "",
      locationId: undefined,
      parentResourceId: undefined,
    },

    validate: {
      locationId: (value) => {
        if (!value) return t`Izberite lokacijo`;
      },
    },
  });

  useEffect(() => {
    if (!resource || !locations || !resources) return;

    form.initialize({
      label: resource.label,
      locationId: resource.locationId.toString(),
      parentResourceId: resource.parentResourceId?.toString(),
    });
  }, [resources, locations, resource]);

  const handleSubmit = async (values: typeof form.values) => {
    if (!resourceGroupId) return;

    const data: PostResource["body"] = {
      label: values.label,
      locationId: parseInt(values.locationId!),
      parentResourceId: values.parentResourceId
        ? parseInt(values.parentResourceId)
        : undefined,
      isEnabled: resource?.isEnabled ?? true,
      resourceGroupId: parseInt(resourceGroupId),
      id: !resourceId || resourceId === "-1" ? undefined : parseInt(resourceId),
    };

    try {
      await postResource(data);

      notifications.show({
        message: t`Sredstvo uspešno shranjeno`,
        color: "green",
      });

      handleBackButton({
        resourceId,
        tab: "resources",
        resourceGroupId: parseInt(resourceGroupId),
      });
    } catch (e) {
      const message = e instanceof AxiosError ? e.response?.data.error : null;

      notifications.show({
        title: t`Napaka pri shranjevanju sredstva`,
        message,
        color: "red",
      });
    }
  };

  return (
    <form
      style={{ display: "contents" }}
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <Flex direction={"column"} gap={"md"} mt={"md"}>
        <LimeTextInput
          {...form.getInputProps("label")}
          label={t`Ime sredstva`}
          required
          px={"md"}
        />
        <Divider my={1} />
        <LimeSelect
          {...form.getInputProps("locationId")}
          value={form.values.locationId?.toString()}
          label={t`Lokacija`}
          data={locations.map((location) => ({
            value: location.locationId.toString(),
            label: location.label,
          }))}
          required
          allowDeselect={false}
          disabled={locations.length === 0}
          leftSection={locations.length === 0 ? <Loader size={"1rem"} /> : null}
          px={"md"}
        />
        <Divider my={1} />
        {isFeatureEnabled("RESOURCES_HIERARCHY") && (
          <LimeSelect
            {...form.getInputProps("parentResourceId")}
            value={form.values.parentResourceId?.toString()}
            label={t`Nadkategorija sredstva`}
            data={resources
              ?.filter(
                (resource) => resource.id !== parseInt(resourceId ?? "-1"),
              )
              .map((resourceGroup) => ({
                value: resourceGroup.id.toString(),
                label: resourceGroup.label,
              }))}
            allowDeselect={true}
            disabled={isResourcesPending}
            leftSection={isResourcesPending ? <Loader size={"1rem"} /> : null}
            px={"md"}
          />
        )}
        <Divider my={1} />
      </Flex>

      <Flex
        align={"center"}
        justify={"center"}
        gap={"sm"}
        pos={"fixed"}
        bottom={"20px"}
        left={0}
        w={"100%"}
      >
        <Button
          variant="outline"
          onClick={() =>
            handleBackButton({
              resourceId,
              tab: "resources",
              resourceGroupId: parseInt(resourceGroupId ?? "-1"),
            })
          }
        >
          <Trans>Prekliči</Trans>
        </Button>
        <Button type={"submit"} loading={isPostResourcePending}>
          <Trans>Shrani</Trans>
        </Button>
      </Flex>
    </form>
  );
};
