import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { BiTrash } from "react-icons/bi";
import { create } from "zustand";
import {
  ConvertResponseScheduleToSchedule,
  ConvertScheduleToResponseSchedule,
  GetStartAndEndDate,
} from "../Utils";
import {
  apiCreateBulkShifts,
  apiCreateSchedule,
  apiDeleteSchedule,
  apiDeleteScheduleForUser,
  apiGetSchedule,
  apiGetSchedules,
  apiUpdateSchedule,
} from "../api/apiSchedule";
import "dayjs/locale/sl";
import { Box, Flex, Text } from "@mantine/core";

export const useScheduleStore = create((set, get) => ({
  isLoadingSchedule: false,

  schedules: null,
  locations: [],

  selectedScheduleId: null,
  selectedScheduleData: null,

  selectedScheduleDate: dayjs().locale("sl"),
  setScheduleDate: (date) => {
    set({ selectedScheduleDate: date });

    if (get().selectedScheduleId !== null) {
      get().getSchedule(get().selectedScheduleId, date);
    }
  },

  selectedScheduleViewType: "week" || "month" || "day",
  setScheduleViewType: (type) => {
    set({ selectedScheduleViewType: type });

    if (get().selectedScheduleId !== null) {
      get().getSchedule(get().selectedScheduleId);
    }
  },

  selectedUserId: null,

  selectedModalDate: null,

  scheduleModalOpened: false,

  toggleScheduleModal: ({ userId, date, opened }) => {
    if (opened) {
      set({
        selectedUserId: userId,
        selectedModalDate: date,
      });
    } else {
      set({
        selectedUserId: null,
        selectedModalDate: null,
      });
    }

    set({ scheduleModalOpened: opened });
  },

  selectSchedule: (id) => {
    // set view type to day if on mobile
    let newScheduleViewType = get().selectedScheduleViewType;
    if (window.innerWidth < 768) {
      newScheduleViewType = "day";
    }

    set({
      selectedScheduleId: id,
      selectedScheduleViewType: newScheduleViewType,
    });

    let newDate;
    if (id == null) newDate = null;
    else {
      const schedule = get().schedules.find(
        (schedule) => schedule.scheduleId === id,
      );
      const today = dayjs().locale("sl");

      if (schedule == null) return;

      // check if date is before, in or after schedule
      if (today.isBefore(schedule.date_from)) {
        newDate = dayjs(schedule.date_from).locale("sl");
      } else if (today.isAfter(schedule.date_to)) {
        newDate = dayjs(schedule.date_to).locale("sl");
      } else {
        newDate = today;
      }
    }

    get().setScheduleDate(newDate);
  },

  createShift: async (data) => {
    try {
      set({ isLoadingSchedule: true });

      const response = await apiCreateBulkShifts(
        get().selectedScheduleId,
        data,
      );

      if (response.data.created === 0 && response.data.deleted === 0) {
        notifications.show({
          title: <Trans>Napaka</Trans>,
          message: (
            <Trans>Ustvarjen ali posodobljen ni bil noben delovni dan</Trans>
          ),
          color: "red",
        });

        return response;
      }

      notifications.show({
        title: <Trans>Uspešno</Trans>,
        message: <Trans>Spremembe urnika so bile uspešno shranjene</Trans>,
        color: "green",
      });

      get().getSchedule(get().selectedScheduleId);
      get().toggleScheduleModal({ opened: false });
      return response;
    } catch (error) {
      console.log("ERROR", error.response.data.details);

      modals.open({
        title: t`Prišlo je do napake pri ustvarjanju urnika`,
        children: (
          <Flex direction={"column"} gap={"xs"}>
            <Text>
              Naslednji delovni dnevi pri uporabnikih imajo prekoračen čas
              malice
            </Text>

            {error.response.data.details.map((detail) => {
              return (
                <Box style={{ border: "1px solid gray" }} mb={"xs"}>
                  <Text>{detail.date}</Text>
                  <br />
                  <Text>{detail.userName}</Text>
                </Box>
              );
            })}
          </Flex>
        ),
      });
    } finally {
      set({ isLoadingSchedule: false });
    }
  },

  getSchedules: async () => {
    const response = await apiGetSchedules();

    const schedules = response.data.schedules.map((schedule) => {
      return ConvertResponseScheduleToSchedule(schedule);
    });

    set({ schedules: schedules, locations: response.data.locations });
  },

  getSchedule: async (id, withDate) => {
    set({ isLoadingSchedule: true, selectedScheduleData: null });

    const { startDate, endDate } = GetStartAndEndDate(
      withDate ?? get().selectedScheduleDate,
      get().selectedScheduleViewType,
    );

    let response;

    try {
      response = await apiGetSchedule({
        id: id,
        startDate: startDate,
        endDate: endDate,
      });

      if (response.status === 200) {
        // check if selected date is in range of schedule
        let newDate = get().selectedScheduleDate;
        const selectedSchedule = get().schedules.find(
          (schedule) => schedule.scheduleId === get().selectedScheduleId,
        );
        if (
          (selectedSchedule && newDate < selectedSchedule.date_from) ||
          newDate > selectedSchedule.date_to
        ) {
          newDate = dayjs(selectedSchedule.date_from).utc(false);
        }

        set({
          selectedScheduleData: {
            ...response.data,
            date_from: dayjs(response.data.date_from),
            date_to: dayjs(response.data.date_to),
          },
          selectedScheduleDate: newDate,
        });
      }
    } catch (error) {
      console.warn(error);
    } finally {
      set({ isLoadingSchedule: false });
    }

    return response;
  },

  createSchedule: async (schedule) => {
    const response = await apiCreateSchedule(
      ConvertScheduleToResponseSchedule(schedule),
    );

    if (response.status === 200) {
      set((state) => ({
        schedules: [
          ...state.schedules,
          ConvertResponseScheduleToSchedule(response.data.schedule),
        ],
      }));
      get().selectSchedule(response.data.schedule.scheduleId);

      notifications.show({
        title: <Trans>Uspešno</Trans>,
        message: <Trans>Urnik je bil uspešno ustvarjen</Trans>,
        color: "green",
      });
    } else {
      notifications.show({
        title: <Trans>Napaka</Trans>,
        message: <Trans>Prišlo je do napake pri ustvarjanju urnika</Trans>,
        color: "red",
      });
    }

    return response;
  },

  updateSchedule: async (schedule) => {
    const response = await apiUpdateSchedule(
      schedule.scheduleId,
      ConvertScheduleToResponseSchedule(schedule),
    );

    if (response.status === 200) {
      const responseSchedule = ConvertResponseScheduleToSchedule(
        response.data.schedule,
      );

      set((state) => ({
        schedules: state.schedules.map((s) => {
          if (s.scheduleId === schedule.scheduleId) return responseSchedule;
          else return s;
        }),
      }));

      notifications.show({
        title: <Trans>Uspešno</Trans>,
        message: <Trans>Urnik je bil uspešno posodobljen</Trans>,
        color: "green",
      });
    } else {
      notifications.show({
        title: <Trans>Napaka</Trans>,
        message: <Trans>Prišlo je do napake pri ustvarjanju urnika</Trans>,
        color: "red",
      });
    }

    return response;
  },

  deleteSchedule: async (scheduleId) => {
    const response = await apiDeleteSchedule(scheduleId);

    if (response.status === 200 && response.data.schedule === 1) {
      set((state) => ({
        schedules: state.schedules.filter(
          (schedule) => schedule.scheduleId !== scheduleId,
        ),
      }));

      notifications.show({
        title: "",
        message: <Trans>Urnik je bil uspešno izbrisan!</Trans>,
        color: "green",
        icon: <BiTrash />,
      });

      modals.closeAll();
    } else {
      notifications.show({
        title: <Trans>Napaka</Trans>,
        message: <Trans>Prišlo je do napake pri brisanju urnika</Trans>,
        color: "red",
      });
    }

    return response;
  },

  deleteShiftsForUser: async (userId) => {
    const response = await apiDeleteScheduleForUser(
      userId,
      get().selectedScheduleId,
    );

    if (response.status === 200) {
      get().toggleScheduleModal({ opened: false });
    } else {
      notifications.show({
        title: <Trans>Napaka</Trans>,
        message: <Trans>Prišlo je do napake pri brisanju urnika</Trans>,
        color: "red",
      });
    }

    get().getSchedule(get().selectedScheduleId);
    return response;
  },
}));
