import { useLingui } from "@lingui/react";
import { ActionIcon, Flex } from "@mantine/core";
import { IoMdAddCircleOutline } from "react-icons/io";
import styled from "styled-components";
import SelectedServiceListItem from "./SelectedServiceListItem";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";

const SelectedServiceList = ({
  selectedServices,
  setSelectedServices,
  services,
  isPreScheduled,
  isNewAppointment,
}) => {
  useLingui();

  const { permissions } = useUserPermissionsStore();

  return (
    <Wrapper>
      <ServiceList shown={true}>
        {selectedServices.length > 0 &&
          selectedServices.map((selectedService, index) => {
            const service = services.find(
              (service) => service.serviceId === selectedService.serviceId,
            );

            if (service == null) return null;

            const { startDuration, timeOffDuration, endDuration } =
              selectedService;

            const hasOnlyOneInput =
              timeOffDuration == null &&
              (startDuration == null || endDuration == null);

            return (
              <SelectedServiceListItem
                key={index}
                selectedService={selectedService}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                service={service}
                index={index}
                isNewAppointment={isNewAppointment}
                isPreScheduled={isPreScheduled}
                startDuration={startDuration}
                hasOnlyOneInput={hasOnlyOneInput}
                timeOffDuration={timeOffDuration}
                endDuration={endDuration}
              ></SelectedServiceListItem>
            );
          })}
        {!isPreScheduled && permissions.manage_appointments ? (
          <Flex justify={"center"}>
            <ActionIcon
              onClick={() => {
                const newServices = [
                  ...selectedServices,
                  {
                    ...structuredClone(selectedServices.at(-1)),
                    appointmentId: undefined,
                    removed: false,
                  },
                ];

                setSelectedServices(newServices);
              }}
            >
              <IoMdAddCircleOutline
                size="24px"
                color={"#61906f"}
              ></IoMdAddCircleOutline>
            </ActionIcon>
          </Flex>
        ) : null}
      </ServiceList>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ServiceList = styled.div`
  border-bottom: 1px solid #eaebed;
  padding: ${(props) => (props.shown ? "10px 0" : "0")};
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  .list-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    .arrow-down {
      color: #868e96;
      font-size: 2rem;
    }
  }

  .list-options {
    z-index: 1000;
    width: 250px;
    min-height: 100px;
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 7px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
    padding-bottom: 0;

    @media (max-width: 768px) {
      width: 86vw;
    }

    .list-option {
      width: 100%;
      padding: 10px 10px;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }
  }
`;

export default SelectedServiceList;
