import { t } from "@lingui/core/macro";
import { Box } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { api } from "@/lib/api-client";
import {
  getKeyValue,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { i18n } from "@lingui/core";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export const GiftCardDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const giftCardId = id ? Number(id) : undefined;

  const { data, isFetching, processedErrorMessage } =
    api.giftCard.useGetGiftCard(giftCardId);

  const isEditing = giftCardId !== -1;

  const tableColumns: { key: string; label: string }[] = [
    {
      key: "amount",
      label: t`Vrednost transakcije`,
    },
    {
      key: "date",
      label: t`Datum`,
    },
    {
      key: "source",
      label: t`Vir`,
    },
  ];

  const { locale } = i18n;

  return (
    <>
      <LimePageHeader
        title={isEditing ? t`Uredi darilni bon` : t`Dodaj nov darilni bon`}
        subPage={{
          title: `Darilni bon`,
          onBackButtonClick: () => navigate(`/dashboard/gift-card`),
        }}
      />

      <Box maw={"550px"} mx={"auto"} mt="md">
        <LimeAlert color="danger" message={processedErrorMessage} />

        <Table
          classNames={{
            wrapper: "p-0",
          }}
          shadow="none"
        >
          <TableHeader columns={tableColumns}>
            {(column) => (
              <TableColumn key={column.key}>{column.label}</TableColumn>
            )}
          </TableHeader>
          <TableBody
            isLoading={isFetching}
            emptyContent={t`Ni transakcij`}
            items={
              data?.transactions.map((transaction) => {
                const userFullName = (() => {
                  if (!transaction.giftCardTransactionCreatedBy) return "/";

                  const firstName =
                    transaction.giftCardTransactionCreatedBy.name || "/";
                  const lastName =
                    transaction.giftCardTransactionCreatedBy.lastName || "/";
                  return `${firstName} ${lastName}`;
                })();

                return {
                  id: transaction.id,
                  amount: transaction.transactionAmountFormatted,
                  date: new Date(transaction.transactionDate).toLocaleString(
                    locale,
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    },
                  ),
                  source: userFullName,
                };
              }) || []
            }
          >
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
