import { cn } from "@/utils";
import { Alert, AlertProps, Input } from "@nextui-org/react";
import React from "react";

type Input = AlertProps & {
  message?: string;
  children?: React.ReactNode;
};

export const LimeAlert = React.forwardRef(
  (
    { error, message, classNames = {}, children, ...props }: Input,
    ref: React.Ref<HTMLInputElement>,
  ) =>
    message || props.title || props.description ? (
      <div className={props.className}>
        <Alert
          {...props}
          classNames={{
            ...classNames,
          }}
          title={props.title ?? message}
        >
          {children}
        </Alert>
      </div>
    ) : undefined,
);
LimeAlert.displayName = "LimeAlert";
