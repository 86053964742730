import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Color } from "@/types/colors";
import { convertMinutesToHHmm } from "@/utils/date-time-utils";
import { Divider, Flex, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  LegendProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import { TextVariant } from "@/types/text-variants";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import classes from "./Graphs.module.css";

export const UsersBarGraphWorkingHours = ({
  data,
}: {
  data: { date: string; shiftTime: number; appointmentsTime: number }[];
  users: {
    userId: number;
    name: string | null;
    lastName: string | null;
    color: string;
  }[];
}) => {
  const isDesktop = useMediaQuery("(min-width:768px)");

  const [totalSelected, setTotalSelected] = useState(0);

  const customTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const shiftTimes = (payload.find((pl) => pl.dataKey === "shiftTime")
        ?.value || 0) as number;

      const appointmentTimes = (payload.find(
        (pl) => pl.dataKey === "appointmentsTime",
      )?.value || 0) as number;

      return (
        <div className={classes.tooltip}>
          <Text variant={TextVariant.CaptionEmphasized} c={Color.SecondaryText}>
            {label}
          </Text>
          <Divider opacity={1} w={"100%"} color="#F0F0F0"></Divider>
          <div className={classes.content}>
            <div className={classes.row}>
              <Text className={classes.rowName} variant={TextVariant.Body}>
                <Trans>Delovni čas</Trans>:
              </Text>
              <Text variant={TextVariant.Caption}>
                {convertMinutesToHHmm(Math.trunc(shiftTimes * 60))}
              </Text>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.row}>
              <Text className={classes.rowName} variant={TextVariant.Body}>
                <Trans>Čas izvajanja storitev</Trans>:
              </Text>
              <Text variant={TextVariant.Caption}>
                {convertMinutesToHHmm(Math.trunc(appointmentTimes * 60))}
              </Text>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const CustomLegend: React.FC<LegendProps> = ({ payload }: LegendProps) => {
    return (
      <div className={classes.legend}>
        {payload?.map((entry, index) => (
          <div key={`item-${index}`} className={classes.item}>
            <div
              className={classes.itemColor}
              style={{ background: entry.color }}
            ></div>
            <Text variant={TextVariant.Caption} c={Color.SecondaryText}>
              {entry.value === "shiftTime" && t`Delovni čas`}
              {entry.value === "appointmentsTime" && t`Čas izvajanja storitev`}
            </Text>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {!isDesktop ? (
        <Flex direction={"column"} mb={20}>
          <Flex
            color={Color.PrimaryText}
            mih={24 * 1.55}
            align={"center"}
            gap={5}
          >
            <Text size={"20px"} fw={600} c={Color.PrimaryText} mih={20 * 1.55}>
              {convertMinutesToHHmm(Math.trunc(totalSelected * 60))}
            </Text>
          </Flex>
          <Text size={"14px"} fw={400} c={Color.SecondaryText} mih={14 * 1.55}>
            Skupaj delovnih ur za izbran dan
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      <ResponsiveContainer width={"100%"} height={400}>
        {data && data.length > 0 ? (
          <BarChart
            data={data}
            margin={{ top: 0 }}
            style={{
              fontSize: "12px",
            }}
            onMouseMove={(e) => {
              if (!e.activePayload) return;
              const total =
                e.activePayload.find((pl) => pl.dataKey === "shiftTime")
                  ?.value || 0;

              setTotalSelected(total);
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="date"
              padding={{ left: 30 }}
              stroke="#8C8C8C"
              xAxisId={"one"}
            />
            <XAxis
              dataKey="date"
              padding={{ left: 30 }}
              stroke="#8C8C8C"
              xAxisId={"two"}
              hide
            />
            <YAxis tickLine={false} stroke="#8C8C8C" width={40} />
            <Tooltip content={customTooltip} />
            <Legend
              align={"left"}
              iconType="plainline"
              wrapperStyle={{
                left: 25 - 5,
                width: `calc(100% - ${25 - 5}px)`,
              }}
              content={<CustomLegend></CustomLegend>}
            />
            <Bar
              type="linear"
              dataKey={"shiftTime"}
              fill="#9BC2A5"
              animationEasing="ease-out"
              radius={[5, 5, 0, 0]}
              xAxisId={"one"}
            />
            <Bar
              type="linear"
              dataKey={"appointmentsTime"}
              fill="#548F68"
              animationEasing="ease-out"
              radius={[5, 5, 0, 0]}
              xAxisId={"two"}
            />
          </BarChart>
        ) : (
          <></>
        )}
      </ResponsiveContainer>
    </>
  );
};
