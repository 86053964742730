import { Trans } from "@lingui/react/macro";
import { api } from "@/lib/api-client";
import { TextVariant } from "@/types/text-variants";
import { i18n } from "@lingui/core";
import { Flex, Table, Text } from "@mantine/core";
import { Route, Routes, useNavigate } from "react-router-dom";
import { UpsertTemplate } from "./UpsertTemplate";

import classes from "./TemplatesTab.module.css";

export const TemplatesTab = () => {
  return (
    <Flex justify={"center"}>
      <Routes>
        <Route path="/" element={<TemplatesList></TemplatesList>}></Route>
        <Route
          path="/create"
          element={<UpsertTemplate></UpsertTemplate>}
        ></Route>
        <Route path="/:id" element={<UpsertTemplate></UpsertTemplate>}></Route>
      </Routes>
    </Flex>
  );
};

const TemplatesList = () => {
  const navigate = useNavigate();
  const { data: templatesList } = api.message.useGetMessageTemplate({});

  const locale = i18n.locale;

  if (!templatesList) return null;

  return (
    <Table highlightOnHover highlightOnHoverColor="red">
      <Table.Tr>
        <Table.Th style={{ width: "fit-content" }}>
          <Text variant={TextVariant.BodyEmphasized} w={"fit-content"}>
            <Trans>Kanal</Trans>
          </Text>
        </Table.Th>
        <Table.Th>
          <Text variant={TextVariant.BodyEmphasized}>
            <Trans>Zadeva</Trans>
          </Text>
        </Table.Th>
        <Table.Th>
          <Text variant={TextVariant.BodyEmphasized}>
            <Trans>Sporočilo</Trans>
          </Text>
        </Table.Th>
      </Table.Tr>
      {templatesList.templates.map((t) => {
        const defaultLanguageIndex = t.MessageTemplateLocalized.findIndex(
          (name) => name.language === locale,
        );
        return (
          <Table.Tr
            className={classes.templateRow}
            styles={{ tr: { cursor: "pointer", borderBottom: "none" } }}
            onClick={() =>
              navigate(`/dashboard/notifications/templates/${t.id}`)
            }
          >
            <Table.Td style={{ width: "fit-content" }}>{t.channel}</Table.Td>
            <Table.Td>
              {t.MessageTemplateLocalized[defaultLanguageIndex].subject}
            </Table.Td>
            <Table.Td maw={"300px"}>
              {t.MessageTemplateLocalized[defaultLanguageIndex].message}
            </Table.Td>
          </Table.Tr>
        );
      })}
    </Table>
  );
};
