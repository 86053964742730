import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { z } from "zod";
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form";
import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { LimeSelect, SingleSelect } from "@/Components/NextBase/LimeSelect";
import { LimeDatePicker } from "@/Components/NextBase/LimeDatePicker";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { Plus } from "lucide-react";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { api } from "@/lib/api-client";
import { notifications } from "@mantine/notifications";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { manageDeliveryOrderSchema } from "./utils";
import { CreateLocationDialog } from "../../TaxRegister/Setup/CreateLocationDialog";
import { countries } from "countries-list";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

export const SupplierTab = ({
  form,
  handleNextStep,
  fieldsToValidate,
}: {
  form: UseFormReturnType<z.infer<typeof manageDeliveryOrderSchema>>;
  handleNextStep: () => void;
  fieldsToValidate: (keyof z.infer<typeof manageDeliveryOrderSchema>)[];
}) => {
  const { data: suppliersData, refetch: refetchSuppliers } =
    api.supplier.useGetSuppliers({});

  const { data: locationsData, refetch: refetchLocations } =
    api.location.useGetLocations({
      perPage: 100,
      page: 1,
      sortBy: "labelAsc",
    });

  const {
    isOpen: isSupplierModalOpened,
    onOpen: openSupplierModal,
    onClose: closeSupplierModal,
  } = useDisclosure();

  const {
    isOpen: isCreateLocationModalOpen,
    onOpen: openCreateLocationModal,
    onClose: closeCreateLocationModal,
  } = useDisclosure();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const hasErrors = fieldsToValidate.some(
      (field) => form.validateField(field).hasError,
    );
    if (!hasErrors) {
      handleNextStep();
    }
  };

  const locationSelectItems: SingleSelect["items"] =
    locationsData?.locations.map((location) => ({
      key: location.locationId.toString(),
      label: location.label,
      sectionName: t`Obstoječe lokacije`,
    })) || [];
  locationSelectItems.unshift({
    key: "NEW",
    label: t`Ustvari novo lokacijo`,
    startContent: <Plus size={16} />,
    sectionName: t`Nova lokacija`,
  });

  const supplierSelectItems: SingleSelect["items"] =
    suppliersData?.suppliers.map((supplier) => ({
      key: supplier.id.toString(),
      label: supplier.name,
    })) || [];
  supplierSelectItems.unshift({
    key: "NEW",
    label: t`Dodaj dobavitelja`,
    startContent: <Plus size={16} />,
    sectionName: t`Nov dobavitelj`,
  });

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex w-full flex-col items-center"
      >
        <div className="mt-2 flex w-full flex-1 flex-col gap-4 px-4 md:w-full md:max-w-[450px]">
          <div className="flex items-center gap-4">
            <LimeSelect
              {...form.getInputProps("locationId")}
              items={locationSelectItems}
              label={t`Lokacija`}
              onChange={(val: string) => {
                if (val === "NEW") {
                  openCreateLocationModal();
                  return;
                }

                form.setFieldValue("locationId", Number(val));
              }}
            ></LimeSelect>
          </div>

          <LimeSelect
            {...form.getInputProps("supplier")}
            items={supplierSelectItems}
            label={t`Dobavitelj`}
            onChange={(val: string) => {
              if (val === "NEW") {
                openSupplierModal();
                return;
              }

              form.setFieldValue("supplier", Number(val));
            }}
          ></LimeSelect>

          <Divider />

          <LimeInput
            {...form.getInputProps("number")}
            label={t`Številka dobavnice`}
          ></LimeInput>

          <LimeDatePicker
            label={t`Datum dobavnice`}
            {...form.getInputProps("date")}
          ></LimeDatePicker>

          <Divider />

          <LimeInput
            {...form.getInputProps("totalDiscountPercentage")}
            endContent={"%"}
            label={t`Popust na dobavnico`}
          ></LimeInput>
        </div>
        <div className="fixed bottom-0 w-full bg-background p-4 md:max-w-[450px]">
          <Button type="submit" color="primary" className="w-full">
            Naprej
          </Button>
        </div>
      </form>

      <NewSupplierModal
        opened={isSupplierModalOpened}
        onClose={async (supplierId) => {
          if (supplierId != null) {
            await refetchSuppliers();
            form.setFieldValue("supplier", supplierId);
          }

          closeSupplierModal();
        }}
      ></NewSupplierModal>

      <CreateLocationDialog
        isOpen={isCreateLocationModalOpen}
        handleClose={async (locationId) => {
          if (locationId != null) {
            await refetchLocations();
            form.setFieldValue("locationId", locationId);
          }

          closeCreateLocationModal();
        }}
      />
    </>
  );
};

const supplierSchema = z.object({
  taxNumber: z.string().min(1),
  name: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  zipCode: z.string().min(1),
  country: z.string().min(1),
  isTaxSubject: z.boolean(),
});

const NewSupplierModal = ({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: (supplierId?: number) => void;
}) => {
  const {
    mutateAsync: createSupplier,
    processedErrorMessage: createSupplierErrorMessage,
    isPending: isCreatingSupplier,
  } = api.supplier.usePostSupplier();

  const form = useForm({
    initialValues: {
      taxNumber: "",
      name: "",
      address: "",
      city: "",
      zipCode: "",
      country: "",
      isTaxSubject: false,
    },
    validate: zodResolver(supplierSchema),
  });

  const handleSubmit = async (values: z.infer<typeof supplierSchema>) => {
    const response = await createSupplier(values);

    notifications.show({
      message: t`Dobavitelj je uspešno ustvarjen`,
      color: "green",
    });
    onClose(response.supplier.id);
  };

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      scrollBehavior="outside"
      isDismissable={!isCreatingSupplier}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ModalContent>
          <ModalHeader>
            <Trans>Dodaj novega dobavitelja</Trans>
          </ModalHeader>
          <ModalBody>
            <LimeInput
              label={t`Davčna številka `}
              {...form.getInputProps("taxNumber")}
              isRequired
            ></LimeInput>

            <LimeInput
              label={t`Ime `}
              {...form.getInputProps("name")}
              isRequired
            ></LimeInput>

            <LimeInput
              label={t`Naslov `}
              {...form.getInputProps("address")}
              isRequired
            ></LimeInput>

            <div className="flex gap-4">
              <LimeInput
                label={t`Kraj `}
                {...form.getInputProps("city")}
                isRequired
              ></LimeInput>
              <LimeInput
                label={t`Poštna številka `}
                {...form.getInputProps("zipCode")}
                isRequired
              ></LimeInput>
            </div>

            <LimeSelect
              label={t`Država`}
              items={Object.entries(countries).map(([code, data]) => ({
                key: code,
                label: data.name,
                startContent: getUnicodeFlagIcon(code),
              }))}
              {...form.getInputProps("country")}
              isRequired
            />

            <LimeSwitch
              title={t`Davčni zavezanec`}
              {...form.getInputProps("isTaxSubject")}
            ></LimeSwitch>

            <LimeAlert message={createSupplierErrorMessage} color="danger" />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onPress={() => onClose()}
              isDisabled={isCreatingSupplier}
            >
              <Trans>Prekliči</Trans>
            </Button>
            <Button
              type="submit"
              color="primary"
              isLoading={isCreatingSupplier}
            >
              <Trans>Dodaj</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
