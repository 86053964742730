import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { api } from "@/lib/api-client";
import { getStartTimeOfServiceInLinked } from "@/pages/Dashboard/Calendar/utils";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { getFinalPriceCents } from "@/utils/price-utils";
import { Checkbox, Divider, Flex, Space, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { RiUserLine } from "react-icons/ri";
import { isValidPhoneNumber } from "react-phone-number-input";
import { create } from "zustand";
import {
  fullPhoneNumberToNumberAndCountryCode,
  getDurationsAndPrice,
} from "../../Utilities";
import {
  apiAddCustomerToPreScheduledAppointment,
  apiCreateBreak,
  apiUpdateCustomerOnPreScheduledAppointment,
} from "../../pages/Dashboard/Calendar/api/apiCalendar";
import { useCalendarStore } from "../../pages/Dashboard/Calendar/store/useCalendarStore";
import { apiGetAppointment, apiUpdateAppointment } from "./apiAppointment";

export const useAppointmentModalStore = create((set, get) => ({
  resetData: () => {
    set({
      isFetchingData: false,
      appointmentData: null,
      selectedServices: [],
      confirmedServiceSelection: false,
      selectedUserAppointments: [],
    });
  },

  currentAppointmentGsm: null,
  setCurrentAppointmentGsm: (currentAppointmentGsm) =>
    set({ currentAppointmentGsm }),

  requiredTags: [],
  setRequiredTags: (requiredTags) => set({ requiredTags }),

  isTagCombinationValid: false,
  setIsTagCombinationValid: (isTagCombinationValid) =>
    set({ isTagCombinationValid }),

  appointmentId: null,
  setAppointmentId: (appointmentId) => {
    set({ appointmentId });
    if (appointmentId === -1) {
      get().initializeNewAppointmentData();
    } else {
      get().fetchAppointmentData(appointmentId);
    }
  },

  initializeNewAppointmentData: () => {
    const calendarStoreState = useCalendarStore.getState();
    const newAppointmentData = {
      appointmentId: null,
      customers: [],
      duration: 30,
      locationId: calendarStoreState.selectedLocationId,
      serviceColor: "#000000",
      serviceId: null,
      serviceName: "",
      startTime:
        calendarStoreState.lastSelectedTime ??
        dayjs().format("YYYY-MM-DDTHH:mm"),
      userId: calendarStoreState.lastSelectedUserId ?? null,
      resources: calendarStoreState.lastSelectedResourceId
        ? [calendarStoreState.lastSelectedResourceId]
        : [],
      invoiceId: null,
    };

    set({
      appointmentData: newAppointmentData,
      initialAppointmentData: newAppointmentData,
      selectedServices: [],
      confirmedServiceSelection: false,
      selectedUserAppointments: [],
      isFetchingData: false,
    });
  },

  isFetchingData: false,
  setIsFetchingData: (isFetchingData) => set({ isFetchingData }),

  appointmentData: null,
  initialAppointmentData: null,
  setInitialAppointmentData: (appointmentData) =>
    set({ initialAppointmentData: appointmentData }),
  setAppointmentData: (appointmentData) => set({ appointmentData }),
  modifyAppointmentData: (appointmentData) =>
    set((state) => ({
      appointmentData: {
        ...state.appointmentData,
        ...appointmentData,
      },
    })),

  selectedServices: [],
  setSelectedServices: (
    selectedServices,
    overridePrice = true,
    overrideDuration = true,
  ) => {
    const { services } = useCalendarStore.getState();

    if (!get().appointmentData) return;

    const appointmentStart = dayjs(get().appointmentData.startTime);

    const servicesWithPrices = selectedServices.map((service, index) => {
      const serviceStart = getStartTimeOfServiceInLinked({
        selectedServices,
        index,
        startTime: appointmentStart,
      });

      const currService = services.find(
        (s) => s.serviceId === service.serviceId,
      );

      const userId = service.users[0];

      const user = currService.users.find((u) => u.userId === userId);

      const {
        startDuration,
        timeOffDuration,
        endDuration,
        price: userPrice,
      } = user
        ? getDurationsAndPrice(currService, user)
        : {
            startDuration: service.startDuration,
            timeOffDuration: service.timeOffDuration,
            endDuration: service.endDuration,
            price: currService.price,
          };

      const baseDuration =
        startDuration || 0 + timeOffDuration || 0 + endDuration || 0;

      const duration = overrideDuration
        ? startDuration || 0 + timeOffDuration || 0 + endDuration || 0
        : service.startDuration ||
          0 + service.endDuration ||
          0 + service.timeOffDuration ||
          0;

      const priceBase =
        (userPrice ? userPrice * 100 : null) || currService.priceBaseCents;

      const priceCents = (() => {
        const tariffs = currService.pricingSchedules
          .filter((tariff) => {
            const localDate = serviceStart.locale("en");
            const dayOfWeek = localDate.format("dddd").toLowerCase();
            const isSameDay = tariff.dayOfWeek === dayOfWeek;
            if (!isSameDay) return false;

            const startDay = localDate.startOf("day");
            const scheduleStart = startDay.add(
              dayjs(tariff.fromTime).utc(true).valueOf(),
            );

            const scheduleEnd = startDay.add(
              dayjs(tariff.toTime).utc(true).valueOf(),
            );

            const slotDateEnd = serviceStart.add(duration, "minutes");

            const isBetween =
              slotDateEnd.isAfter(scheduleStart) &&
              serviceStart.isBefore(scheduleEnd);
            return isBetween;
          })
          .map((tariff) => {
            const slotStart = serviceStart;
            const slotEnd = serviceStart.add(duration, "minutes");

            const localDate = serviceStart.locale("en");
            const startDay = localDate.startOf("day");
            const scheduleStart = startDay.add(
              dayjs(tariff.fromTime).utc(true).valueOf(),
            );
            const scheduleEnd = startDay.add(
              dayjs(tariff.toTime).utc(true).valueOf(),
            );

            const overlapStart = dayjs.max(slotStart, scheduleStart);
            const overlapEnd = dayjs.min(slotEnd, scheduleEnd);

            if (!overlapStart || !overlapEnd) {
              return { priceCents: tariff.priceCents, durationInTariff: 0 };
            }

            const durationInTariff = overlapEnd.diff(overlapStart, "minute");

            return { priceCents: tariff.priceCents, durationInTariff };
          });

        const priceCentsTariff = tariffs.reduce(
          (acc, { priceCents: priceCentsTariff, durationInTariff }) =>
            acc + priceCentsTariff * (durationInTariff / baseDuration),
          0,
        );

        const attendanceDefault = service.attendance ?? 1;
        const priceCentsBracket = (() => {
          if (currService.pricingMultiplier === "perPerson") {
            return currService.pricingBrackets.find(
              ({ fromCount, toCount }) =>
                attendanceDefault >= fromCount && attendanceDefault <= toCount,
            )?.priceCents;
          }

          if (currService.pricingMultiplier === "perResource") {
            return currService.pricingBrackets.find(
              ({ fromCount, toCount }) =>
                service.resourceCount?.length >= fromCount &&
                service.resourceCount?.length <= toCount,
            )?.priceCents;
          }
        })();

        return getFinalPriceCents({
          pricingStrategy: currService.pricingStrategy,
          priceCents:
            priceBase *
            (currService.isExtraTimeAllowed ? duration / baseDuration : 1),
          priceCentsTariff,
          priceCentsBracket,

          overrides: {
            pricingMultiplier: currService.pricingMultiplier,
            attendance: attendanceDefault,
            resourceCount: service.resources.length,
          },
        });
      })();

      return {
        ...service,
        price: overridePrice ? priceCents / 100 : service.price,
        startDuration: overrideDuration ? startDuration : service.startDuration,
        timeOffDuration: overrideDuration
          ? timeOffDuration
          : service.timeOffDuration,
        endDuration: overrideDuration ? endDuration : service.endDuration,
      };
    });

    set({ selectedServices: servicesWithPrices });
  },

  confirmedServiceSelection: false,
  setConfirmedServiceSelection: (confirmedServiceSelection) =>
    set({ confirmedServiceSelection }),
  handleConfirmServiceSelection: (confirmed, _services) => {
    const calendarStoreState = useCalendarStore.getState();
    const { services } = useCalendarStore.getState();

    const newSelectedServices = _services ?? [...get().selectedServices];
    newSelectedServices.forEach((ser) => {
      const service = calendarStoreState.services.find(
        (service) => service.serviceId === ser.serviceId,
      );

      const foundService = services.find(
        (service) => service.serviceId === ser.serviceId,
      );

      ser.userId = calendarStoreState.lastSelectedUserId;
      ser.users = calendarStoreState.lastSelectedUserId
        ? [calendarStoreState.lastSelectedUserId]
        : [];

      if (service) {
        const user = service.users.find((user) => user.userId === ser.users[0]);

        const { startDuration, timeOffDuration, endDuration, price } =
          getDurationsAndPrice(service, user);

        ser.price = price;
        ser.startDuration = startDuration;
        ser.timeOffDuration = timeOffDuration;
        ser.endDuration = endDuration;
        ser.blockBeforeMins = service.blockBeforeMins;
        ser.blockAfterMins = service.blockAfterMins;
        ser.formPaymentRequired = service.formPaymentRequired;
        ser.discountType = "amount";
      }

      const resources = calendarStoreState.resources;
      const lastSelectedResourceId = calendarStoreState.lastSelectedResourceId;
      const foundRequiredResources = foundService.serviceResourceGroups
        .map((serviceResourceGroup) => {
          const lastSelectedResource = resources.find(
            (resource) =>
              resource.id === lastSelectedResourceId &&
              resource.resourceGroupId === serviceResourceGroup &&
              resource.isAvailable,
          );

          if (lastSelectedResource) {
            return lastSelectedResource.id;
          }

          const foundResource = resources.find(
            (resource) =>
              resource.resourceGroupId === serviceResourceGroup &&
              resource.isAvailable,
          );

          return foundResource ? foundResource.id : null;
        })
        .filter(Boolean);

      ser.resources = foundRequiredResources;

      //  ser.resources = calendarStoreState.lastSelectedResourceId
      //   ? [calendarStoreState.lastSelectedResourceId]
      //  : [];
    });
    get().setSelectedServices(newSelectedServices);

    get().setConfirmedServiceSelection(confirmed);

    if (confirmed) {
      calendarStoreState.setHideModalTabs(true);
    }
  },

  selectedUserAppointments: [], // IDs
  setSelectedUserAppointments: (selectedUserAppointments) =>
    set({ selectedUserAppointments }),
  modifySelectedUserAppointments: (selectedUserAppointments) =>
    set((state) => ({
      selectedUserAppointments: [
        ...state.selectedUserAppointments,
        ...selectedUserAppointments,
      ],
    })),

  rescheduleSendNotifications: true,
  rescheduleAllLinked: true,
  wirelesslySubmitAppointmentForm: () => {
    const dateTimeChanged =
      get().appointmentData.startTime !==
      get().initialAppointmentData?.startTime;

    set({ rescheduleSendNotifications: true });
    set({ rescheduleAllLinked: true });

    if (
      useCalendarStore.getState().selectedAppointmentId !== -1 &&
      dateTimeChanged
    ) {
      modals.openConfirmModal({
        title: t`Prestavitev termina`,
        children: (
          <>
            <Text variant={TextVariant.Body}>
              Termin bo prestavljen na{" "}
              <Text variant={TextVariant.BodyEmphasized} inline span>
                {dayjs(get().appointmentData.startTime).format("DD.MM.YYYY")}
              </Text>{" "}
              ob{" "}
              <Text variant={TextVariant.BodyEmphasized} inline span>
                {dayjs(get().appointmentData.startTime).format("HH:mm")}
              </Text>
              .
            </Text>

            <Space h={"md"} />

            <Text variant={TextVariant.Body}>
              Prestavljate termin za naslednje osebe:
            </Text>
            {get().appointmentData.customers.map((customer) => (
              <Flex key={customer.id} py={"xs"} align={"center"} gap={"xs"}>
                <RiUserLine size={".9rem"} />
                <Text variant={TextVariant.Body}>
                  {customer.name} {customer.lastName}
                </Text>
              </Flex>
            ))}

            <Divider mb={"md"} />

            {useCalendarStore.getState().hasRescheduleNotification && (
              <Checkbox
                label={t`Pošlji obvestila?`}
                defaultChecked={true}
                onChange={(event) =>
                  set({ rescheduleSendNotifications: event.target.checked })
                }
              />
            )}

            {get().appointmentData.linkedAppointmentId && (
              <>
                <Divider mb={"xs"} mt={"md"} />
                <Flex direction={"column"} mt={"md"} gap={"sm"}>
                  <Text variant={TextVariant.BodyEmphasized}>
                    Termin je povezan z ostalimi.
                  </Text>
                  <Checkbox
                    label={t`Prestavi vse povezane termine?`}
                    value={get().rescheduleAllLinked}
                    onChange={(event) =>
                      set({ rescheduleAllLinked: event.target.checked })
                    }
                  />
                </Flex>
              </>
            )}
          </>
        ),
        labels: {
          confirm: t`Prestavi`,
          cancel: t`Prekliči`,
        },
        onConfirm: async () => {
          const { appointmentId, startTime, subjectId } = get().appointmentData;

          document.getElementById("appointment-form-submit").click();

          modals.closeAll();
          await useCalendarStore.getState().refreshCalendar();
        },
        onCancel: () => {},
        centered: true,
      });
    } else {
      document.getElementById("appointment-form-submit").click();
    }
  },
  // appointment-form-submit
  isCreatingAppointment: false,
  handleSubmitAppointmentForm: async (values) => {
    if (get().isCreatingAppointment) {
      return;
    }

    set({ isCreatingAppointment: true });

    if (get().appointmentData.isPreScheduled) {
      const isAddingCustomer = get().selectedUserAppointments.length === 0;

      let response;
      let notificationText;

      const data = { ...values };

      if (data.gsm && data.gsm !== "" && isValidPhoneNumber(data.gsm)) {
        const { gsm, countryCode } = fullPhoneNumberToNumberAndCountryCode(
          data.gsm,
        );

        data.countryCode = countryCode;
        data.gsm = gsm;
      }

      if (isAddingCustomer) {
        response = await apiAddCustomerToPreScheduledAppointment(
          get().appointmentData.appointmentParts[0].appointmentId,
          data,
        ).catch((err) => {
          console.error(err);
          notifications.show({
            message: err.message,
            color: "red",
          });
        });

        notificationText = <Trans>Uspešno ste dodali osebo na termin</Trans>;
      } else {
        response = await apiUpdateCustomerOnPreScheduledAppointment(
          get().appointmentData.appointmentParts[0].appointmentId,
          get().selectedUserAppointments[0],
          values,
        ).catch((err) => {
          console.error(err);
          notifications.show({
            message: err.message,
            color: "red",
          });
        });

        notificationText = (
          <Trans>Uspešno ste posodobili osebo na terminu</Trans>
        );
      }

      if (response.status !== 200) {
        console.error("Napaka");

        notifications.show({
          message: <Trans>Napaka</Trans>,
          color: "red",
        });

        return;
      }

      notifications.show({
        message: notificationText,
        variant: "success",
        color: "green",
      });

      get().setSelectedUserAppointments([]);
      // refresh the appointment
      get().fetchAppointmentData(get().appointmentId);

      set({ isCreatingAppointment: false });

      return;
    }

    const isNewAppointment = get().appointmentId === -1;

    const customerGsm = values.gsm;
    if (customerGsm && isValidPhoneNumber(customerGsm)) {
      const { gsm, countryCode } =
        fullPhoneNumberToNumberAndCountryCode(customerGsm);

      values.gsm = gsm;
      values.countryCode = countryCode;
    }

    const dataForApi = {
      customerData: {
        ...values,
        // birthday: values.birthday
        //   ? dayjs(values.birthday).format("YYYY-MM-DD")
        //   : null,
      },
      appointments: [],
      repeatData: {
        isRepeating: values.isRepeating,
        repeatInterval: values.repeatInterval,
        repeatDuration: values.repeatDuration,
        repeatType: values.repeatType,
      },
      payOnlyFirstAppointment: values.payOnlyFirstAppointment,
    };

    delete dataForApi.customerData.isRepeating;
    delete dataForApi.customerData.repeatDuration;
    delete dataForApi.customerData.repeatType;

    get().selectedServices.forEach((selectedService) => {
      const service = useCalendarStore
        .getState()
        .services.find(
          (service) => service.serviceId === selectedService.serviceId,
        );

      let duration = selectedService.startDuration;
      if (duration == null) {
        duration = selectedService.endDuration;
      }

      dataForApi.appointments.push({
        serviceId: service.serviceId,
        duration: selectedService.timeOffDuration
          ? selectedService.startDuration +
            selectedService.endDuration +
            selectedService.timeOffDuration
          : duration,
        locationId: get().appointmentData.locationId,
        userId: selectedService.userId,
        users: selectedService.users || [],
        price: selectedService.price,
        timeOffStart: selectedService.startDuration,
        timeOffDuration: selectedService.timeOffDuration,
        resources: selectedService.resources || [],
        appointmentId: selectedService.appointmentId,
        removed: selectedService.removed,
        blockBeforeMins: selectedService.blockBeforeMins,
        blockAfterMins: selectedService.blockAfterMins,
        attendance: selectedService.attendance,
        discountType: selectedService.discountType,
        discountAmount:
          selectedService.discountType == "amount"
            ? selectedService.discountAmount * 100
            : selectedService.discountAmount,
      });
    });

    if (
      dataForApi.gsm &&
      dataForApi.gsm !== "" &&
      isValidPhoneNumber(dataForApi.gsm)
    ) {
      const { gsm, countryCode } = fullPhoneNumberToNumberAndCountryCode(
        values.gsm,
      );
      dataForApi.countryCode = countryCode;
      dataForApi.gsm = gsm;
    }

    const dayObject = dayjs(get().appointmentData.startTime);

    // dataForApi.services = selectedServices;
    // dataForApi.startTime = appointmentData.startTime;
    // dataForApi.duration = appointmentData.duration;
    dataForApi.locationId = get().appointmentData.locationId;
    dataForApi.userId = get().appointmentData.userId;
    dataForApi.time = dayObject.format("HH:mm");
    dataForApi.date = dayObject.format("YYYY-MM-DD");
    dataForApi.sendNotifications = get().rescheduleSendNotifications;
    dataForApi.customerId =
      get().appointmentData?.customers[0]?.customerId || undefined;

    if (isNewAppointment) {
      // create new appointment

      const dataForPost = {
        appointmentParts: get().selectedServices.map((selectedService) => {
          return {
            serviceId: selectedService.serviceId,
            timeOffStart: selectedService.startDuration || undefined,
            timeOffDuration: selectedService.timeOffDuration || undefined,
            duration:
              (selectedService.startDuration || 0) +
              (selectedService.endDuration || 0) +
              (selectedService.timeOffDuration || 0),
            resources: selectedService.resources || [],
            users: selectedService.users || [],
            blockBeforeMins: selectedService.blockBeforeMins,
            blockAfterMins: selectedService.blockAfterMins,
            attendance: selectedService.attendance,
            price: selectedService.price,
            discountType: selectedService.discountType,
            discountAmount:
              selectedService.discountAmount *
              (selectedService.discountType == "amount" ? 100 : 1),
          };
        }),
        locationId: get().appointmentData.locationId,
        language: values.createPaymentLocale || "sl",
        notifications: values.sendNotifications,
        repeat: values.isRepeating
          ? {
              interval: values.repeatInterval,
              type: values.repeatType,
              count: values.repeatDuration,
            }
          : undefined,
        startTime: dayObject.format("YYYY-MM-DDTHH:mm"),
        customer: {
          name: values.name,
          lastName: values.lastName,
          countryCode: values.countryCode,
          email: values.email,
          gsm: values.gsm,
          customerId: values.customerId || undefined,
          comment: values.comment,
        },
      };

      const paymentDetails = values.createPayment
        ? {
            paymentChannel: values.createPaymentChannel,
            onlyFirstAppointment: values.payOnlyFirstAppointment,
            paymentTimeoutMinutes: values.createPaymentTimeoutMinutes,
          }
        : undefined;

      try {
        const response = await api.appointments.usePostAppointment({
          ...dataForPost,
          paymentDetails,
          throwIfRestricted: true,
        });
        set({ isCreatingAppointment: false });

        notifications.show({
          message: t`Uspešno ste ustvarili termin`,
          variant: "success",
          color: "green",
        });
        useCalendarStore.getState().closeModals();
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response.data.canOverride) {
            const items = e.response.data.warnings;

            modals.openConfirmModal({
              title: t`Nekateri termini niso veljavni`,
              children: (
                <Flex direction={"column"}>
                  {items.map((item) => (
                    <Flex
                      key={item.dateTime}
                      c={item.isRestricted ? Color.Error : undefined}
                    >
                      <Text>{item.dateTime}</Text>
                    </Flex>
                  ))}
                </Flex>
              ),
              labels: {
                confirm: t`Ustvari veljavne termine`,
                cancel: t`Prekliči ustvarjanje`,
              },
              onConfirm: async () => {
                try {
                  await api.appointments.usePostAppointment({
                    ...dataForPost,
                    throwIfRestricted: false,
                  });
                  notifications.show({
                    message: t`Uspešno ste ustvarili termin`,
                    variant: "success",
                    color: "green",
                  });
                  useCalendarStore.getState().closeModals();
                } catch (e) {
                  notifications.show({
                    message: t`Napaka pri ustvarjanju termina`,
                    color: "red",
                  });
                }
              },
            });
          }
        }
        notifications.show({
          message: t`Napaka pri ustvarjanju termina`,
          color: "red",
        });
      }
    } else {
      try {
        await apiUpdateAppointment(get().appointmentId, {
          ...dataForApi,
        });

        notifications.show({
          message: <Trans>Uspešno ste posodobili termin</Trans>,
          variant: "success",
        });
        useCalendarStore.getState().closeModals();
      } catch (e) {
        notifications.show({
          title: t`Napaka pri posodabljanju`,
          message: e.response?.data?.error || "",
          color: "red",
        });
      }
    }

    set({ isCreatingAppointment: false });
  },

  fetchAppointmentData: async (apptId) => {
    if (!apptId && !get().appointmentId) return;

    set({ isFetchingData: true });
    try {
      const res = await apiGetAppointment(apptId ?? get().appointmentId);
      if (res.status !== 200) {
        throw new Error("Failed to fetch appointment data");
      }
      const fetchedAppointment = res.data.appointment;
      const selectedServices = fetchedAppointment.appointmentParts.map(
        (appPart) => {
          const discountType =
            appPart.customers?.at(0)?.discountType || "amount";
          return {
            serviceId: appPart.serviceId,
            users: appPart.users || [],
            startDuration: appPart.timeOffDuration
              ? appPart.timeOffStart
              : appPart.duration,
            timeOffDuration: appPart.timeOffDuration || null,
            endDuration: appPart.timeOffDuration
              ? appPart.duration -
                appPart.timeOffDuration -
                appPart.timeOffStart
              : null,
            price: appPart.customers?.at(0)?.price,
            resources: appPart.resources || [],
            appointmentId: appPart.appointmentId,
            blockBeforeMins: appPart.blockBeforeMins,
            blockAfterMins: appPart.blockAfterMins,
            attendance: appPart.attendance,
            discountType,
            discountAmount:
              appPart.customers?.at(0)?.discountAmount /
              (discountType === "amount" ? 100 : 1),
            invoiceId: appPart.customers?.at(0)?.invoiceId,
          };
        },
      );

      set({
        initialAppointmentData: res.data.appointment,
        appointmentData: {
          ...res.data.appointment,
          customers: fetchedAppointment.appointmentParts.flatMap(
            (appPart) => appPart.customers,
          ),
        },
        selectedServices: selectedServices,
        isFetchingData: false,
      });

      // Pre-select the customer for non-pre-scheduled appointments
      if (!res.data.appointment.isPreScheduled) {
        set({
          selectedUserAppointments: [
            fetchedAppointment.appointmentParts[0].customers[0]
              .userAppointmentId,
          ],
        });
      }
    } catch (error) {
      set({ isFetchingData: false });
    }
  },

  createBreak: async (data) => {
    const calendarStoreState = useCalendarStore.getState();
    const { calendarData, newBreakData } = calendarStoreState;
    const { allShifts, breakTypes } = calendarData;

    const breakType = breakTypes.find(
      (breakType) =>
        breakType.scheduleShiftBreakTypeId ==
        newBreakData?.scheduleShiftBreakTypeId,
    );

    if (!breakType) {
      return;
    }

    const response = await apiCreateBreak(data).catch((err) => {
      notifications.show({
        title: <Trans>Napaka pri dodajanju pavze</Trans>,
        message: err?.response?.data?.error,
        color: "red",
      });
    });

    if (response == null || response.status !== 200) {
      return false;
    }

    notifications.show({
      title: <Trans>Pavza dodana</Trans>,
      message: <Trans>Pavza je bila uspešno dodana</Trans>,
      color: "green",
    });

    return true;
  },
}));
