import { Trans } from "@lingui/react/macro";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { useDraggable } from "@dnd-kit/core";
import { useLingui } from "@lingui/react";
import { Flex, Popover, Space, Text } from "@mantine/core";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import React, { useEffect } from "react";
import { BsCurrencyEuro } from "react-icons/bs";
import { PiWarningFill } from "react-icons/pi";
import { RiCalendar2Line, RiChat1Line, RiUserLine } from "react-icons/ri";
import styled from "styled-components";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { getStatusIcon } from "../../../utils";
import useDrag from "../../hooks/useDrag";

import classes from "./AppointmentBlock.module.css";
import LimeAvatar from "../LimeAvatar";
import {
  returnHeightOfEventBlock,
  returnYoffsetForEvent,
} from "@/utils/appointment-utils";
import { HoverContent } from "./HoverContent";

const AppointmentBlock = ({
  appointment,
  resource,
  user = null,
  usersInOrder = null,
  resourcesInOrder,
  columnDate,
}) => {
  const {
    appointmentId,
    formattedData,
    serviceColor,
    customers,
    serviceId,
    isPreScheduled,
    overlapIndex,
    permission,
    managePermission,
    numberOfNextResources,
    numberOfNextUsers,
    blockBeforeMins,
    attendance,
    isTimeoffBlockingResource,
    hoverDate: appointmentDate,
  } = appointment;

  const { i18n } = useLingui();

  const {
    calendarView,
    setSelectedAppointmentId,
    viewMode,
    setHideModalTabs,
    services,
    users,
    resources,
    calendarMode,
    setLastClickYOffset,
    lastClickYOffset,
  } = useCalendarStore((state) => state);

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const { startTime, endTime, date } = formattedData;

  const service = useCalendarStore((state) =>
    state.services.find((service) => service.serviceId === serviceId),
  );

  const { calendarData, draggingId } = useCalendarStore((state) => state);

  const { calculateTimeForDragYOffset } = useDrag();

  const isShown = appointment.filterEnabled;

  const avatars = (
    <Flex gap={5} maw={"50%"} mah={"30px"}>
      {calendarMode === "resources"
        ? appointment.userIds.map((userId, index) => {
            const user = users.find((u) => u.userId === userId);
            if (!user) return null;
            return (
              <LimeAvatar
                size={25}
                user={{ name: user.name, image: user.imageUrl }}
                key={index}
              ></LimeAvatar>
            );
          })
        : appointment.resources.map((resource, index) => {
            const foundResource = resources.find((r) => r.id === resource);
            if (!foundResource) return null;

            return (
              <LimeAvatar
                key={foundResource.id}
                user={{
                  name: foundResource.label,
                  image: foundResource.imageUrl,
                }}
              ></LimeAvatar>
            );
          })}
    </Flex>
  );

  const locationStart = dayjs(`${columnDate}T${calendarData.startTime}`);
  const locationEnd = dayjs(`${columnDate}T${calendarData.endTime}`);
  const appointmentStart = dayjs(`${appointment.date}T${startTime}`);

  const isAppointmentOverMultipleDays =
    appointmentStart
      .add(appointment.duration, "minutes")
      .format("YYYY-MM-DD") !== appointmentStart.format("YYYY-MM-DD");

  const appointmentStartInDay = (() => {
    if (dayjs(`${appointment.date}T${startTime}`).isBefore(locationStart)) {
      return locationStart;
    }

    return dayjs(`${appointment.date}T${startTime}`);
  })();

  const yOffset = returnYoffsetForEvent({
    calendarStartTime: calendarData.startTime,
    startTimeEvent: appointmentStartInDay,
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable-${appointmentId}-${user || resource}-${columnDate}`,
    data: {
      appointment,
      currentResourceId: resource,
      currentResources: resourcesInOrder?.slice(
        resourcesInOrder.indexOf(resource),
        resourcesInOrder.indexOf(resource) + numberOfNextResources,
      ),
      currentUsers: usersInOrder?.slice(
        usersInOrder.indexOf(user),
        usersInOrder.indexOf(user) + numberOfNextUsers,
      ),
      currentUserId: user,
      isOverMultipleResources: numberOfNextResources > 1,
      isOverMultipleUsers: numberOfNextUsers > 1,
      yOffset,
    },
    disabled: !managePermission,
  });

  if (draggingId === appointmentId && !transform) {
    return null;
  }

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y + lastClickYOffset}px, 0)`,
        zIndex: 10,
      }
    : undefined;

  let width = 90;
  let xOffset = 0;

  if (overlapIndex === -1) {
    width = 80;
  }
  if (overlapIndex > 0) {
    width = 100 - overlapIndex * 10;
    xOffset = overlapIndex * 10;
  }

  if (numberOfNextResources > 1) {
    width = 100 * numberOfNextResources - overlapIndex * 10;
  }
  if (numberOfNextUsers > 1) {
    width = 100 * numberOfNextUsers - overlapIndex * 10;
  }

  // set localized service name
  const serviceName =
    services.find((s) => s.serviceId === serviceId)?.name || "";

  if (serviceName) {
    appointment.serviceName = serviceName;
  }

  const onClickAppointment = (e) => {
    e.stopPropagation();

    if (!permission) {
      return;
    }

    setHideModalTabs(true);
    setSelectedAppointmentId(appointmentId);
    setPopoverOpen(false);
  };

  const appointmentEndInDay = (() => {
    if (
      dayjs(`${appointment.date}T${startTime}`)
        .add(appointment.duration, "minutes")
        .isAfter(locationEnd)
    ) {
      return locationEnd;
    }

    return dayjs(`${appointment.date}T${startTime}`).add(
      appointment.duration,
      "minutes",
    );
  })();

  const calculatedDuration = Math.max(
    appointmentEndInDay.diff(appointmentStartInDay, "minutes"),
    0,
  );

  const startDuration = Math.max(
    dayjs
      .min([
        appointmentStart.add(appointment.timeOffStart, "minutes"),
        locationEnd,
      ])
      .diff(appointmentStartInDay, "minutes"),
    0,
  );

  const timeoffDuration = appointment.timeOffDuration
    ? dayjs
        .min([
          appointmentStart
            .add(appointment.timeOffStart, "minutes")
            .add(appointment.timeOffDuration, "minutes"),
          locationEnd,
        ])
        .diff(appointmentStartInDay.add(startDuration, "minutes"), "minutes")
    : 0;

  const timeoffEndDuration = appointment.timeOffDuration
    ? dayjs
        .min([
          appointmentStart
            .add(appointment.timeOffStart, "minutes")
            .add(appointment.timeOffDuration, "minutes")
            .add(appointment.timeOffEnd, "minutes"),
          locationEnd,
        ])
        .diff(
          appointmentStartInDay
            .add(timeoffDuration, "minutes")
            .add(startDuration, "minutes"),
          "minutes",
        )
    : 0;

  const upperBlockHeight =
    transform && isAppointmentOverMultipleDays
      ? 100
      : returnHeightOfEventBlock({
          duration: startDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const timeoffHeight =
    transform && isAppointmentOverMultipleDays
      ? 0
      : returnHeightOfEventBlock({
          duration: timeoffDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const lowerBlockHeight =
    transform && isAppointmentOverMultipleDays
      ? 0
      : returnHeightOfEventBlock({
          duration: timeoffEndDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const totalHeight =
    transform && isAppointmentOverMultipleDays
      ? 100
      : returnHeightOfEventBlock({
          duration: calculatedDuration,
          slotHeight: calendarData.slotHeight,
          timeStep: calendarData.timeStep,
        });

  const blockBeforeHeight = returnHeightOfEventBlock({
    duration: appointment.blockBeforeMins,
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  const blockAfterHeight = returnHeightOfEventBlock({
    duration: appointment.blockAfterMins,
    timeStep: calendarData.timeStep,
    slotHeight: calendarData.slotHeight,
  });

  let upperContent;
  let lowerContent = null;

  const isTimeoffBlockingRelevant =
    isTimeoffBlockingResource && calendarMode === "resources";

  const heightTotal = totalHeight;
  const topBlockHeight = isTimeoffBlockingRelevant
    ? heightTotal
    : upperBlockHeight;
  const bottomBlockHeight = isTimeoffBlockingRelevant ? null : lowerBlockHeight;
  const bottomYOffset = isTimeoffBlockingRelevant ? null : timeoffHeight;

  const dragTime = calculateTimeForDragYOffset(
    yOffset + transform?.y + lastClickYOffset,
  );
  const draggingContent = (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 500,
        fontSize: ".8rem",
      }}
    >
      <div>
        {isAppointmentOverMultipleDays ? (
          <div className="flex flex-col items-center gap-0">
            <span>
              {dayjs(`${appointmentDate}T${dragTime}`).format("DD. MM. HH:mm")}
            </span>
            <span>-</span>
            <span>
              {dayjs(`${appointmentDate}T${dragTime}`)
                .add(appointment.duration, "minutes")
                .format("DD. MM. HH:mm")}
            </span>
          </div>
        ) : (
          <span>{dragTime}</span>
        )}
      </div>
    </div>
  );

  if (transform) {
    upperContent = draggingContent;
  } else if (isPreScheduled) {
    upperContent = (
      <PreScheduledAppointmentContent
        appointment={appointment}
        view={calendarView}
        color={appointment ? serviceColor : "grey"}
        shown={isShown}
        showUsers={permission}
      />
    );
  } else {
    const { upperBlockContent, lowerBlockContent } =
      SingleUserAppointmentContent({
        appointment,
        view: calendarView,
        color: appointment ? serviceColor : "grey",
        shown: isShown,
        showUsers: permission,
        avatars,
      });
    upperContent = upperBlockContent;
    lowerContent = lowerBlockContent;
  }

  const dateTime = appointment.date + "T" + startTime;
  const isInPast = Date.parse(dateTime) < Date.now();

  if (service == null) {
    return null;
  }

  let cursor = "pointer";
  if (transform) cursor = "move";
  if (!permission) cursor = "default";

  const locale = i18n.locale;

  const dayName = capitalize(
    dayjs(date, "DD.MM.YYYY").locale(locale).format("dddd"),
  );

  return (
    <>
      {blockBeforeMins > 0 && (
        <div
          className={classes.blockBefore}
          style={{
            top: yOffset - blockBeforeHeight,
            height: blockBeforeHeight,
            // backgroundColor: `${serviceColor}70`,
            width: `${width}%`,
            backgroundImage: ` repeating-linear-gradient(
              159.5deg,
              transparent,
              transparent 8px,
              ${serviceColor}30 8px,
              ${serviceColor}30 18px
            )`,
          }}
        ></div>
      )}
      <Wrapper
        color={serviceColor}
        onMouseDown={(event) => {
          if (!isAppointmentOverMultipleDays) return;
          const parentElement = event.target.parentElement;
          const parentRect = parentElement.getBoundingClientRect();

          const offsetY = event.clientY - parentRect.top;

          setLastClickYOffset(offsetY);
        }}
      >
        <Popover
          opened={popoverOpen}
          radius={8}
          position={calendarView === "day" ? "bottom" : "right-start"}
          disabled={transform || window.innerWidth < 768 /*|| !permission*/}
          shadow={"md"}
          withinPortal={true}
          withArrow
        >
          <Popover.Target>
            <BlockWrapper
              overlapIndex={overlapIndex}
              width={width}
              xOffset={xOffset}
              ref={setNodeRef}
              style={style}
              {...listeners}
              {...attributes}
              height={heightTotal}
              lowerBlockHeight={bottomBlockHeight}
              lowerYoffset={bottomYOffset}
              upperBlockHeight={topBlockHeight}
              yOffset={yOffset}
              color={serviceColor}
              isShown={viewMode === "appointments" && isShown}
              cursor={cursor}
              isPreScheduled={isPreScheduled}
              isPreScheduledFull={
                isPreScheduled &&
                appointment.customers.length >= appointment.numberOfPeople
              }
              isInPast={isInPast}
            >
              <div
                role={"presentation"}
                className={"upper-block block-part"}
                onClick={onClickAppointment}
                onMouseEnter={() => setPopoverOpen(true)}
                onMouseLeave={() => setPopoverOpen(false)}
              >
                {upperContent}
              </div>
              <Space h={`${bottomYOffset}px`} />
              <div
                role="presentation"
                className={"lower-block block-part"}
                onClick={onClickAppointment}
                onMouseEnter={() => setPopoverOpen(true)}
                onMouseLeave={() => setPopoverOpen(false)}
              >
                {lowerContent}
              </div>
            </BlockWrapper>
          </Popover.Target>
          <Popover.Dropdown
            style={{
              borderLeft: "1px solid #eaeaea",
            }}
          >
            <HoverContent
              resources={resources}
              users={users}
              appointment={appointment}
              viewCustomersPermission={permission}
            />
          </Popover.Dropdown>
        </Popover>
      </Wrapper>

      {blockAfterHeight > 0 && (
        <div
          className={classes.blockBefore}
          style={{
            top: yOffset + heightTotal,
            height: blockAfterHeight,
            width: `${width}%`,
            backgroundImage: ` repeating-linear-gradient(
              159.5deg,
              transparent,
              transparent 8px,
              ${serviceColor}30 8px,
              ${serviceColor}30 18px
            )`,
          }}
        ></div>
      )}
    </>
  );
};

const SingleUserAppointmentContent = ({
  appointment,
  view,
  color,
  shown,
  showUsers,
  avatars,
}) => {
  const customer = appointment?.customers?.at(0);

  let icon;
  if (customer?.bookedFromForm) {
    icon = <RiCalendar2Line></RiCalendar2Line>;
  } else {
    icon = <RiUserLine></RiUserLine>;
  }

  const hasLowerBlock = appointment.lowerBlockHeight != null;

  const paymentStatus =
    customer?.paymentStatus === "succeeded" ? "success" : "warning";

  const upperBlockContent = (
    <AppointmentText
      view={view}
      color={appointment ? appointment.serviceColor : color}
    >
      {shown && appointment && (
        <>
          {appointment.hideData && (
            <span
              className={"time-label"}
              style={{
                color: "#8C8C8C",
              }}
            >
              {appointment.serviceName}
            </span>
          )}

          <Flex
            justify={"space-between"}
            w={"100%"}
            align={"flex-start"}
            p={"4px 5px 1px 1px"}
          >
            {!appointment.hideData && showUsers && (
              <UserRow
                bookedFromForm={customer?.bookedFromForm}
                className={"user-row"}
                isPaid={
                  customer?.paymentStatus == null ||
                  customer?.paymentStatus === "paid"
                }
              >
                <div className={"user-icon"}>{icon}</div>
                <Text
                  variant={TextVariant.Caption}
                  c="#262626"
                  ta={"left"}
                  lh={".9rem"}
                  fw={600}
                  styles={{ root: { fontSize: "12px" } }}
                >{`${customer?.name ?? ""} ${customer?.lastName ?? ""}`}</Text>
              </UserRow>
            )}

            {customer?.paymentStatus != null && (
              <>
                {customer?.paymentStatus === "succeeded" ? (
                  <BsCurrencyEuro size=".8rem" />
                ) : (
                  getStatusIcon(paymentStatus)
                )}
              </>
            )}

            {avatars}
          </Flex>

          <span className={"time-label"}>
            {`${appointment.formattedData.startTime} - ${appointment.formattedData.endTime}`}
          </span>

          {!hasLowerBlock && (
            <>
              <Text size={"10px"} ta={"left"} fw={600} c={"#8C8C8C"}>
                {appointment.serviceName}
              </Text>

              <Text
                size={"10px"}
                ta={"left"}
                style={{
                  padding: "0px 2px",
                  backgroundColor: `${appointment.serviceColor}50`,
                  display: "block",
                  width: "100%",
                }}
                fw={500}
                c={"#262626"}
              >
                {customer?.comment}
              </Text>
            </>
          )}
        </>
      )}
    </AppointmentText>
  );
  const lowerBlockContent = (
    <AppointmentText>
      {shown && appointment && (
        <>
          <div className={"user-icon"}>{icon}</div>

          <Text size={"10px"} ta={"left"} c={"#8C8C8C"}>
            {appointment.serviceName}
          </Text>
        </>
      )}
    </AppointmentText>
  );

  return { upperBlockContent, lowerBlockContent };
};

const PreScheduledAppointmentContent = ({
  appointment,
  view,
  color,
  shown,
  showUsers,
}) => {
  return (
    <AppointmentText
      view={view}
      color={appointment ? appointment.serviceColor : color}
    >
      {shown && appointment && (
        <Flex
          direction={"column"}
          justify={"space-between"}
          style={{ height: "100%" }}
        >
          <div>
            <Flex align={"center"} justify={"space-between"}>
              <Text size={"11px"} fw={600}>
                {appointment.customers.length}/{appointment.numberOfPeople}
              </Text>
              <span className={"time-label"} style={{ width: "fit-content" }}>
                {`${appointment.formattedData.startTime} - ${appointment.formattedData.endTime}`}
              </span>
            </Flex>

            {appointment.hideData && (
              <span
                className={"time-label"}
                style={{
                  color: "#8C8C8C",
                }}
              >
                {appointment.serviceName}
              </span>
            )}

            {!appointment.hideData && showUsers && (
              <div className={"customerListForAppointment"}>
                {appointment.customers.map((customer, customerIndex) => {
                  let icon;
                  if (customer?.paymentStatus === "requires_payment_method") {
                    icon = (
                      <PiWarningFill color={Color.Warning}></PiWarningFill>
                    );
                  } else if (customer?.paymentStatus === "succeeded") {
                    icon = <BsCurrencyEuro />;
                  } else {
                    if (customer?.bookedFromForm) {
                      icon = <RiCalendar2Line></RiCalendar2Line>;
                    } else {
                      icon = <RiUserLine></RiUserLine>;
                    }
                  }

                  return (
                    <UserRow
                      key={customerIndex}
                      bookedFromForm={customer?.bookedFromForm}
                      className={"user-row"}
                      isPaid={
                        customer?.paymentStatus == null ||
                        customer?.paymentStatus === "paid"
                      }
                    >
                      <div className={"user-icon"}>{icon}</div>
                      <Text
                        variant={TextVariant.Caption}
                        style={{ whiteSpace: "nowrap" }}
                        c="#262626"
                      >{`${customer?.name ?? ""} ${
                        customer?.lastName ?? ""
                      }`}</Text>
                    </UserRow>
                  );
                })}

                <Text variant={TextVariant.Caption} fw={500}>
                  <Trans>{appointment.serviceName}</Trans>
                </Text>
              </div>
            )}
          </div>
          <div style={{ margin: "2px" }}>
            <Text size={"11px"} ta={"left"} fw={600} c={"#8C8C8C"}>
              {appointment.serviceName}
            </Text>
          </div>
        </Flex>
      )}
    </AppointmentText>
  );
};

const UserRow = styled.div`
  position: relative;
  //background: ${(props) => (props.isPaid ? "transparent" : "#ffa7a0")};
  /* border: ${(props) => (props.isPaid ? "none" : "2px dashed #ffa7a0")}; */
  padding-right: 5px;
  border-radius: 5px;

  width: 90%;

  display: flex;
  /* justify-content: space-between; */
`;

const AppointmentText = styled.div`
  overflow: hidden;
  color: #424b64;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1%;
  font-size: 12px;
  height: 100%;
  width: 100%;
  z-index: 2;
  ${(props) =>
    props.view === "month" &&
    "display: -webkit-box;\n  -webkit-box-orient: vertical;\n    -webkit-line-clamp: 1;"};
  @media (max-width: 768px) {
    ${(props) => props.view === "month" && "font-size: 8px;"}
  }
  .time-label {
    font-size: 10px;
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }
  .user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 12px;
    height: 18px;
    font-size: 0.8rem;
  }
  .customerListForAppointment {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
  }

  .user-row {
    display: flex;
    gap: 2px;
    align-items: center;
  }

  .max-users-label {
    font-size: 10px;
    width: 100%;
    text-align: right;
  }
`;

const Wrapper = styled.div`
  .mantine-HoverCard-dropdown {
    border-left: 6px solid ${({ color }) => color} !important;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.12);
    pointer-events: none;
  }
`;

const BlockWrapper = styled.button`
  border: none;
  padding: 0 0 0 5px;
  position: absolute;
  width: ${(props) => props.width}%;
  height: ${(props) => props.height}px;
  top: ${(props) => props.yOffset}px;
  left: ${(props) => props.xOffset}%;
  background: transparent;
  transition: width 0.1s ease-in-out;

  &:hover,
  &:hover .block-part {
    z-index: 9999;
  }

  border-radius: 10px;
  pointer-events: ${(props) => (props.isShown ? "auto" : "none")};

  z-index: 1;
  cursor: ${(props) => props.cursor};
  user-select: none;

  ${(props) => (!props.isShown ? "opacity: 0.3; border-radius:0; " : "")}

  z-index: ${(props) => props.overlapIndex + 2};

  ${({ isPreScheduled, color, isPreScheduledFull }) =>
    isPreScheduled &&
    `
    border-width: 2px 2px 2px 0px;
    border-style: ${isPreScheduledFull ? "solid" : "dashed"};
    border-color: ${color};
    border-radius: 12px;
  `};

  .upper-block {
    background: white;
    position: relative;
    height: ${(props) => props.upperBlockHeight}px;
    padding-left: 1px;
    border-radius: 0 12px 12px 0;
    text-align: left;

    padding: clamp(0px, 5px)
      ${(props) =>
        `clamp(0px, 5px, ${props.upperBlockHeight > 10 ? "1%" : 0} )`};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 12px 12px 0;

      background: ${(props) =>
        props.isShown ? `${props.color}70` : "#bbbbbb"};
      ${({ isPreScheduled, color }) =>
        isPreScheduled &&
        `
          background: ${color}10;
        `};

      z-index: 1;
    }
  }

  .lower-block {
    background: white;
    position: relative;
    height: ${(props) =>
      props.lowerBlockHeight && props.lowerBlockHeight > 0
        ? `${props.lowerBlockHeight}px`
        : 0};
    padding-left: 1px;
    border-radius: 0 6px 6px 0;

    padding: clamp(0px, 5px)
      ${(props) =>
        `clamp(0px, 5px, ${
          props.isShown && props.lowerBlockHeight > 10 ? "1%" : 0
        } )`};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 6px 6px 0;

      background: ${(props) =>
        props.isShown ? `${props.color}70` : "#bbbbbb"};
      ${({ isPreScheduled, color }) =>
        isPreScheduled &&
        `
          background: ${color}10;
        `};

      z-index: 1;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-left: ${(props) =>
      props.isShown ? "5px solid " + props.color : ""};
    background: transparent;

    border-radius: 6px;

    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
    background: #e7e7e8;
    opacity: ${(props) => (props.isInPast ? "0.4" : "0")};
    border-radius: 6px;
  }
`;

export default React.memo(AppointmentBlock);
