import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { DateRangeActions } from "@/Components/DateRangeActions";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import useUserPermissionsStore from "@/stores/useUserPermissionsStore";
import { TextVariant } from "@/types/text-variants";
import {
  type ComboboxItem,
  Flex,
  MultiSelect,
  Tabs,
  Text,
} from "@mantine/core";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { RiMapPin2Fill } from "react-icons/ri";
import styled from "styled-components";
import { TabAppointments } from "./components/TabAppointments";
import { TabGeneral } from "./components/TabGeneral";
import { TabUsers } from "./components/TabUsers/TabUsers";
import { useAnalytics } from "./hooks/useAnalytics";
import { useAnalyticsStore } from "./stores/useAnalyticsStore";

export const Analytics = () => {
  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { setDateRange, selectedLocations, setSelectedLocations } =
    useAnalyticsStore((state) => state);

  const [activeTab, setActiveTab] = useState(
    permissions.analytics_finance ? "general" : "appointments",
  );

  const { width } = useViewportSize();

  const { locations } = useAnalytics();

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isLessThan1400 = useMediaQuery("(max-width: 1400px)");

  useEffect(() => {
    setDateRange([
      dayjs().startOf("day").subtract(1, "week").toDate(),
      dayjs().endOf("day").toDate(),
    ]);
  }, [setDateRange]);

  const locationPicker = (
    <Flex
      justify={"center"}
      align={"center"}
      gap={10}
      bg={"#f5f5f5"}
      p={"0 10px"}
      style={{
        borderRadius: 10,
      }}
    >
      <RiMapPin2Fill color="#8c8c8c"></RiMapPin2Fill>
      <Text
        c={"#8c8c8c"}
        style={{
          display: isLessThan1400 ? "none" : "block",
        }}
        variant={TextVariant.Body}
      >
        <Trans>Lokacija</Trans>
      </Text>
      <MultiSelect
        variant="filled"
        placeholder={isLessThan1400 ? t`lokacija` : undefined}
        data={
          locations.map((l: Record<string, unknown>) => ({
            value: l.locationId?.toString() ?? "",
            label: l.label,
          })) as ComboboxItem[]
        }
        value={selectedLocations.map((l: number) => l.toString())}
        onChange={setSelectedLocations}
        styles={{
          pill: {
            fontSize: 10,
          },
        }}
        miw={240}
        // maw={"25vw"}
        style={{
          flex: isLessThan1400 ? 1 : "unset",
        }}
      ></MultiSelect>
    </Flex>
  );

  const rightTabsSection = (
    <Flex gap={30}>
      {width > 1400 && (
        <Flex className="location_pick_export_wrap" gap={30}>
          {locationPicker}
          <DateRangeActions
            borderRadius={10}
            handleChangeDateRange={(start, end) => {
              const startD = start ? dayjs(start).toDate() : null;
              const endD = end ? dayjs(end).toDate() : null;

              setDateRange([startD, endD]);
            }}
            disabledDateRangeOptions={["all"]}
            defaultSelectedOption="1w"
          ></DateRangeActions>
        </Flex>
      )}
    </Flex>
  );

  const tabs = [
    {
      label: t`Pregled terminov`,
      value: `appointments`,
      rightSection: isMobile ? null : rightTabsSection,
    },
    {
      label: t`Zaposleni`,
      value: `users`,
      rightSection: isMobile ? null : rightTabsSection,
    },
  ];

  if (permissions.analytics_finance) {
    tabs.unshift({
      label: t`Splošno`,
      value: `general`,
      rightSection: isMobile ? null : rightTabsSection,
    });
  }
  return (
    <Wrapper>
      <PageHeader
        title={t`Analitika`}
        subHeader={{
          tabs,
          selectedTab: activeTab,
          onSelectTab: (tab) => {
            setActiveTab(tab);
          },
        }}
      />

      <PageWrapper>
        {width < 1400 && (
          <Flex
            direction={"column"}
            gap={10}
            style={{
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            {locationPicker}
            <DateRangeActions
              borderRadius={10}
              handleChangeDateRange={(start, end) => {
                setDateRange([start, end]);
              }}
              disabledDateRangeOptions={["all"]}
              // additionalActions={[
              //   {
              //     icon: <RiDownload2Line></RiDownload2Line>,
              //     label: t`Izvozi`,
              //     onClick: () => { },
              //   },
              // ]}
              defaultSelectedOption="1w"
            ></DateRangeActions>
          </Flex>
        )}

        <Tabs
          value={activeTab}
          onChange={(tab) => setActiveTab(tab as string)}
          keepMounted={false}
        >
          {permissions.analytics_finance ? (
            <Tabs.Panel value="general">
              <TabGeneral></TabGeneral>
            </Tabs.Panel>
          ) : null}
          <Tabs.Panel value="appointments">
            <TabAppointments></TabAppointments>
          </Tabs.Panel>
          <Tabs.Panel value="users">
            <TabUsers></TabUsers>
          </Tabs.Panel>
        </Tabs>
      </PageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  .mobile_date_picker {
    display: flex;

    .title {
      display: none;
    }
  }
`;

const PageWrapper = styled.div`
  .tabs {
    .mantine-Tabs-tab {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #8c8c8c;

      &[data-active] {
        color: #74b587;
      }
    }

    .location_pick_export_wrap {
      display: flex;
      flex: 1;
      gap: 20;

      justify-content: end;

      color: #8c8c8c;

      * {
        color: #8c8c8c;

        font-weight: 400;
      }
    }
  }

  .mantine-Tabs-panel {
    @media (max-width: 1400px) {
      margin-top: 20px;
    }
  }
`;
