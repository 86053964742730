import { Trans } from "@lingui/react/macro";
import { cn } from "@/utils";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";

export const ExtraMenuModal = ({
  isOpen,
  handleClose,
  canCreateInvoice,
  handleAddDiscountToInvoice,
  handlePickCustomer,
  handleSplitPayment,
}: {
  isOpen: boolean;
  handleClose: () => void;

  canCreateInvoice: boolean;

  handleAddDiscountToInvoice: () => void;
  handlePickCustomer: () => void;
  handleSplitPayment: () => void;
}) => {
  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={handleClose}
        classNames={{
          closeButton: "m-2",
        }}
      >
        <ModalContent>
          {(_) => (
            <>
              <ModalHeader>
                <Trans>Dodatna dejanja</Trans>
              </ModalHeader>
              <ModalBody className="gap-2 px-2">
                <ExtraMenuButtons
                  canCreateInvoice={canCreateInvoice}
                  handleAddDiscountToInvoice={handleAddDiscountToInvoice}
                  handlePickCustomer={handlePickCustomer}
                  handleSplitPayment={handleSplitPayment}
                />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export const ExtraMenuButtons = ({
  canCreateInvoice,
  handleAddDiscountToInvoice,
  handlePickCustomer,
  handleSplitPayment,
  className,
}: {
  canCreateInvoice: boolean;
  handleAddDiscountToInvoice: () => void;
  handlePickCustomer: () => void;
  handleSplitPayment: () => void;
  className?: string;
}) => {
  return (
    <>
      <Button className={cn("h-12", className)} onClick={handlePickCustomer}>
        <Trans>Dodeli stranko</Trans>
      </Button>
      <Button
        className={cn("h-12", className)}
        onClick={handleSplitPayment}
        isDisabled={!canCreateInvoice}
        color="primary"
      >
        <Trans>Razdeli plačilo</Trans>
      </Button>
      {/* <Button
        className={cn("h-12", className)}
        onClick={handleAddDiscountToInvoice}
      >
        <Trans>Dodaj popust</Trans>
      </Button> */}
    </>
  );
};
