import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useLingui } from "@lingui/react";
import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import LimeDrawer from "../../../../Components/LimeDrawer/LimeDrawer";
import useAlertModal from "../../../../Components/Modals/useAlertModal";
import { apiGetUsers } from "../../../../apiCalls";
import useServices from "../../Services/useServices";
import { useUserStore } from "../userUserStore";
import { apiDeleteRole, patchRole, postRole } from "./apiRoles";
import RolesList from "./components/RolesList";
import RolesModal from "./components/RolesModal";

const initFormData = {
  roleName: "",
  roleId: null,
  userIds: [],
  services: [],
};

const useRoles = () => {
  useLingui();
  const [rolesModalOpened, setRolesModalOpened] = useState(false);
  const { roles, setRoles, users, setUsers } = useUserStore((state) => state);
  const [formData, setFormData] = useState({ ...initFormData });
  const { services } = useServices(false);

  const { alertModal } = useAlertModal();

  useEffect(() => {
    if (!rolesModalOpened) {
      setFormData({ ...initFormData });
    }
  }, [rolesModalOpened]);

  const openRolesModal = () => {
    setRolesModalOpened(true);
  };

  const closeRolesModal = () => {
    setRolesModalOpened(false);
  };

  const createRole = async () => {
    const create = await postRole(formData).catch((err) =>
      notifications.show({
        title: t`Napaka`,
        message: err.response.data.message,
        color: "red",
      }),
    );

    if (create == null || create.status !== 200) {
      notifications.show({
        title: t`Napaka`,
      });
      return;
    }

    notifications.show({
      title: t`Vloga uspešno ustvarjena`,
    });

    const token = localStorage.getItem("usr_token") || "";
    const response = await apiGetUsers(token);

    const newUsers = await response.data.users;
    const newRoles = await response.data.roles;
    setUsers(newUsers);
    setRoles(newRoles);

    closeRolesModal();
  };

  const updateRole = async () => {
    const create = await patchRole(formData).catch((err) =>
      notifications.show({
        title: t`Napaka`,
        message: err.response.data.message,
        color: "red",
      }),
    );

    if (create.status !== 200) {
      notifications.show({
        title: t`Napaka`,
      });
      return;
    }

    notifications.show({
      title: t`Vloga uspešno posodobljena`,
    });

    const token = localStorage.getItem("usr_token") || "";
    const response = await apiGetUsers(token);
    const newUsers = await response.data.users;
    const newRoles = await response.data.roles;
    setUsers(newUsers);
    setRoles(newRoles);

    closeRolesModal();
  };

  const handleClickRole = (role) => {
    setFormData({
      ...initFormData,
      roleId: role.value,
      roleName: role.label,
      userIds: role.userIds,
      services: role.services,
    });

    openRolesModal();
  };

  const deleteRole = (role) => {
    alertModal(
      t`Izbriši vlogo`,
      t`Ali ste prepričani, da želite izbrisati vlogo?`,
      { labelConfirm: t`Izbriši`, labelCancel: t`Prekliči` },
      async () => {
        const _delete = await apiDeleteRole(role.value);

        if (_delete.status !== 200) {
          notifications.show({
            title: t`Napaka`,
            message: _delete.data.message,
          });
          return;
        }

        notifications.show({
          title: t`Vloga uspešno izbrisana`,
          // message: _delete.data.message,
        });

        const token = localStorage.getItem("usr_token") || "";
        const response = await apiGetUsers(token);
        const newUsers = await response.data.users;
        const newRoles = await response.data.roles;
        setUsers(newUsers);
        setRoles(newRoles);
      },
    );
  };

  const listOfRoles = (
    <RolesList
      list={roles}
      handleClick={handleClickRole}
      handleDelete={deleteRole}
    ></RolesList>
  );

  const RolesModalContent = (
    <RolesModal
      fields={formData}
      setFields={setFormData}
      users={users}
      services={services}
    ></RolesModal>
  );

  const rolesModal = (
    <LimeDrawer
      opened={rolesModalOpened}
      onClose={closeRolesModal}
      title={t`Vloga`}
      buttons={{
        save: (
          <Button
            onClick={formData.roleId ? updateRole : createRole}
            disabled={formData.roleName === ""}
          >
            {formData.role ? (
              <Trans>Posodobi vlogo</Trans>
            ) : (
              <Trans>Shrani vlogo</Trans>
            )}
          </Button>
        ),
        cancel: (
          <Button onClick={closeRolesModal} variant={"outline"}>
            <Trans>Prekliči</Trans>
          </Button>
        ),
      }}
    >
      {RolesModalContent}
    </LimeDrawer>
  );

  return {
    roles,
    setRoles,
    listOfRoles,
    openRolesModal,
    rolesModal,
    deleteRole,
  };
};

export default useRoles;
