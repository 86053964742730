import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import LoadingSpinner from "@/Components/Dashboard/LoadingSpinner/LoadingSpinner";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimePageTabs } from "@/Components/LimePageTabs";
import { LimeTextInput } from "@/Components/LimeTextInput";
import useFeature from "@/hooks/useFeature";
import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Box, Button, Flex, Table, Text } from "@mantine/core";
import { useDebouncedState, useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { BsEyeSlash } from "react-icons/bs";
import {
  RiArrowRightSLine,
  RiBuilding4Line,
  RiSearch2Line,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { GetResourceGroups } from "../../../../../server/src/types";
import classes from "./GroupsListTable.module.css";
import { ResourceHierarchy } from "./ResourceHierarchy";
import useManageResourceGroup from "./useManageResourceGroup";

export const ResourceGroups = () => {
  const { isFeatureEnabled } = useFeature();

  const tabs = [
    {
      label: t`Vsa sredstva`,
      value: "all",
    },
    ...(isFeatureEnabled("RESOURCES_HIERARCHY")
      ? [
          {
            label: t`Hierarhija`,
            value: "hierarchy",
          },
        ]
      : []),
  ];

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [search, setSearch] = useDebouncedState("", 200);

  const { setFarthestTab } = useManageResourceGroup();

  const [activeTab, setActiveTab] =
    useState<(typeof tabs)[number]["value"]>("all");

  const { data: resourceGroups } = api.resourceGroups.useGetResourceGroups({
    search,
  });

  const handleResourceGroupClick = (resourceGroupId: number | undefined) => {
    if (resourceGroupId === undefined) {
      navigate(`/dashboard/resource-groups`);
      return;
    }

    setFarthestTab(null);

    navigate(
      `/dashboard/resource-groups/${resourceGroupId}/general${
        resourceGroupId === -1 ? "?onboarding" : ""
      }`,
    );
  };

  return (
    <>
      <LimePageHeader
        title={t`Sredstva`}
        rightSection={{
          label: t`Dodaj sredstvo`,
          onClick: () => handleResourceGroupClick(-1),
        }}
      />

      {resourceGroups?.resourceGroups && resourceGroups.total > 0 && (
        <LimePageTabs
          activeTab={activeTab}
          onChangeTab={(tab) => setActiveTab(tab!)}
          tabs={tabs}
          rightSection={
            activeTab === "all" ? (
              <Flex
                gap={"10px"}
                justify={"center"}
                align={"center"}
                p={isMobile ? "xs" : 0}
              >
                <LimeTextInput
                  w={"100%"}
                  radius={"4px"}
                  placeholder={t`Išči sredstva`}
                  leftSection={<RiSearch2Line size={".85rem"} />}
                  defaultValue={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                />
                {/* <Button radius={"4px"} style={{ flexShrink: 0 }}>
                <Trans>Iskanje</Trans>
              </Button> */}
              </Flex>
            ) : (
              <></>
            )
          }
        />
      )}

      {activeTab === "all" && (
        <ResourceGroupList
          resourceGroups={resourceGroups}
          onResourceGroupClick={handleResourceGroupClick}
        />
      )}

      {activeTab === "hierarchy" && <ResourceHierarchy />}
    </>
  );
};

const ResourceGroupList = ({
  resourceGroups,
  onResourceGroupClick,
}: {
  resourceGroups?: GetResourceGroups["response"];
  onResourceGroupClick: (resourceGroupId: number) => void;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (!resourceGroups) {
    return <LoadingSpinner />;
  }

  if (resourceGroups.total < 1) {
    return <NoResourceGroups />;
  }

  return (
    <>
      <Box px={isMobile ? undefined : "xl"}>
        <Table classNames={classes}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>
                <Text variant={TextVariant.Body}>
                  <Trans>Ime skupine</Trans>
                </Text>
              </Table.Th>
              <Table.Th>
                <Text variant={TextVariant.Body}>
                  <Trans>Št. sredstev</Trans>
                </Text>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {resourceGroups.resourceGroups.map((resourceGroup) => (
              <Table.Tr
                h={54}
                pos={"relative"}
                onClick={() => onResourceGroupClick(resourceGroup.id)}
                style={{ cursor: "pointer" }}
                key={resourceGroup.id}
                c={!resourceGroup.isEnabled ? "gray" : undefined}
              >
                <Table.Td py={0}>
                  <Flex gap={"xs"} align={"center"}>
                    {!resourceGroup.isEnabled && <BsEyeSlash />}
                    <Text variant={TextVariant.BodyEmphasized} lh={"54px"}>
                      {resourceGroup.name}
                    </Text>
                  </Flex>
                </Table.Td>
                <Table.Td py={0} pos={"relative"}>
                  <Text variant={TextVariant.Body}>
                    {resourceGroup._count.resources}
                  </Text>

                  <Flex
                    pos={"absolute"}
                    right={0}
                    top={0}
                    bottom={0}
                    px={"sm"}
                    justify={"center"}
                    align={"center"}
                  >
                    <RiArrowRightSLine size={"24px"} />
                  </Flex>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Box>
    </>
  );
};

const NoResourceGroups = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Flex
        h={"calc(100% - 60px)"}
        direction={"column"}
        justify={"center"}
        align={"center"}
      >
        <Flex
          direction={"column"}
          align={"center"}
          gap={4}
          style={{
            transform: "translateY(-50%)",
          }}
          w={isMobile ? "80%" : "350px"}
        >
          <RiBuilding4Line />
          <Text
            variant={TextVariant.BodyEmphasized}
            c={Color.PrimaryText}
            ta={"center"}
          >
            <Trans>Sredstva še niso dodana.</Trans>
          </Text>
          <Text
            variant={TextVariant.Caption}
            c={Color.SecondaryText}
            ta={"center"}
          >
            <Trans>
              Za uporabo strani sredstva je treba dodati vsaj eno sredstvo, ki
              se uporablja za izvajanje storitev.
            </Trans>
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
