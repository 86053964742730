import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { UserAppointmentMessageScheduler } from "@/Components/MessageScheduler";
import { Button, Flex, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { LuMessageSquarePlus } from "react-icons/lu";

export const MessageModal = ({
  userAppointmentId,
}: {
  userAppointmentId: number;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button p={0} onClick={open} w={"fit-content"} variant="transparent">
        <Flex gap={5}>
          <LuMessageSquarePlus></LuMessageSquarePlus>
          <Trans>Ustvari novo</Trans>
        </Flex>
      </Button>
      <Modal opened={opened} onClose={close} title={t`Načrtuj sporočilo`}>
        <UserAppointmentMessageScheduler
          userAppointmentId={userAppointmentId}
          onClose={close}
        ></UserAppointmentMessageScheduler>
      </Modal>
    </>
  );
};
