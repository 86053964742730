import { t } from "@lingui/core/macro";
import { Select, Text, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { RiCalendarTodoLine } from "react-icons/ri";
import styled from "styled-components";
import ColorSwatches from "../../../../../Components/ColorSwatches";
import { useScheduleStore } from "../../Stores/useScheduleStore";

const GeneralSettings = ({ data, setData, overlappingSchedules }) => {
  const { locations } = useScheduleStore((state) => state);

  let error = null;
  if (overlappingSchedules.length > 0) {
    error = `* Urnik se prekriva z urniki: ${overlappingSchedules
      .map((schedule) => schedule.name)
      .join(", ")}`;
  }

  return (
    <Wrapper>
      <TextInput
        placeholder={t`Ime urnika`}
        label={t`Ime urnika`}
        variant="filled"
        radius="md"
        withAsterisk
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <Select
        variant={"filled"}
        label={t`Lokacija`}
        placeholder={t`Izberite lokacijo`}
        data={locations.map((location) => ({
          label: location.label,
          value: location.locationId.toString(),
        }))}
        value={data.locationId}
        onChange={(value) => setData({ ...data, locationId: value })}
      />
      <div>
        {error && (
          <Text c="red" size={"sm"}>
            {error}
          </Text>
        )}
        <DatePickerInput
          variant={"filled"}
          type={"range"}
          icon={<RiCalendarTodoLine size="1.1rem" />}
          label={t`Čas trajanja`}
          placeholder={t`Izberite datum`}
          value={data.dateRange}
          onChange={(value) => setData({ ...data, dateRange: value })}
          w={"100%"}
          locale={"sl"}
          popoverProps={{
            withinPortal: true,
          }}
        />
      </div>
      <ColorSwatches
        selectedColor={data.color}
        label={t`Barva urnika`}
        setSelectedColor={(value) => setData({ ...data, color: value })}
        predefinedColors={["#FBBC05", "#EA4335", "#7E96CD", "#74B587"]}
        allowCustomColor={true}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export default GeneralSettings;
