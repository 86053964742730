import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { EasyNumberInput } from "@/Components/EasyNumberInput";
import TimeSpanRow from "@/Components/TimeSpanRow";
import { daysOfWeek } from "@/constants/daysOfWeek";
import type { DayOfWeek } from "@/server-types";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { Box, Button, Divider, Flex, Group, Space, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { ValueDisplay } from "./Components/ValueDisplay";
import { type FormValues, managingPriceObjectAtom } from "./ManageService";

type Form = {
  price: number;
  daysOfWeek: DayOfWeek[];
  timeFrom: Date;
  timeTo: Date;
};

export const ManageSchedule = ({
  basePrice,
  index,
  schedules,
  handleSaveSchedule,
}: {
  basePrice: number;
  index: number;
  schedules: FormValues["schedules"];
  handleSaveSchedule: (values: Form) => void;
}) => {
  const [_, setManagingPricingObject] = useAtom(managingPriceObjectAtom);

  const existingSchedule = schedules[index];

  const form = useForm<Form>({
    mode: "controlled",
    initialValues: {
      price: existingSchedule?.price || 0,
      daysOfWeek: existingSchedule?.daysOfWeek || [],
      timeFrom: existingSchedule?.timeFrom || null,
      timeTo: existingSchedule?.timeTo || null,
    },

    validate: {
      daysOfWeek: (value) => {
        if (value.length === 0) {
          return t`Izberite vsaj en dan`;
        }

        // check overlap
        const overlaps = schedules.some((schedule, idx) => {
          if (idx === index) {
            return false;
          }

          const overlap = schedule.daysOfWeek.some((day) =>
            value.includes(day),
          );

          const fromTime = dayjs(schedule.timeFrom).format("HH:mm");
          const toTime = dayjs(schedule.timeTo).format("HH:mm");
          const from = dayjs(`1970-01-01 ${fromTime}`);
          const to = dayjs(`1970-01-01 ${toTime}`);

          const formFrom = dayjs(form.getValues().timeFrom).format("HH:mm");
          const formTo = dayjs(form.getValues().timeTo).format("HH:mm");
          const fromForm = dayjs(`1970-01-01 ${formFrom}`);
          const toForm = dayjs(`1970-01-01 ${formTo}`);

          if (overlap) {
            if (
              dayjs(from).isBefore(dayjs(toForm)) &&
              dayjs(to).isAfter(dayjs(fromForm))
            ) {
              return true;
            }
          }

          return false;
        });

        if (overlaps) {
          return t`Tarifa se prekriva z obstoječo tarifo`;
        }
      },

      price: (_) => {
        if (basePrice + form.getValues().price < 0) {
          return t`Končna cena ne sme biti negativna`;
        }
      },
    },
  });

  const finalPrice: number | string = basePrice + form.getValues().price;

  return (
    <form
      style={{ display: "contents" }}
      onSubmit={form.onSubmit(handleSaveSchedule)}
    >
      <Flex direction={"column"}>
        <EasyNumberInput
          px={"md"}
          label={t`Dodatek k ceni`}
          {...form.getInputProps("price")}
        />

        <ValueDisplay
          label={t`Končna cena tarife`}
          text={
            typeof finalPrice === "number"
              ? finalPrice.toFixed(2).toString()
              : parseFloat(finalPrice).toFixed(2).toString()
          }
        />

        <Divider my={"md"} />

        <Text px={"md"} variant={TextVariant.BodyEmphasized}>
          <Trans>Izberite čas in dan tarife</Trans>
        </Text>
        <Space h={10} />
        <Box px={"md"}>
          <TimeSpanRow
            fromTimeValue={form.getValues().timeFrom}
            toTimeValue={form.getValues().timeTo}
            onFromTimeChange={(value) =>
              form.setFieldValue("timeFrom", dayjs(value, "HH:mm").toDate())
            }
            onToTimeChange={(value) =>
              form.setFieldValue("timeTo", dayjs(value, "HH:mm").toDate())
            }
          />
        </Box>

        <Group mt="md" gap={2} grow px={"md"}>
          {daysOfWeek().map((day) => {
            const isSelected = form.getValues().daysOfWeek.includes(day.value);
            console.log("day", day, isSelected);

            return (
              <Button
                key={day.value}
                onClick={() => {
                  const daysOfWeek = form.getValues().daysOfWeek;
                  const index = daysOfWeek.indexOf(day.value);

                  if (index !== -1) {
                    daysOfWeek.splice(index, 1);
                  } else {
                    daysOfWeek.push(day.value as DayOfWeek);
                  }

                  console.log("set", daysOfWeek);

                  form.setValues({
                    ...form.getValues(),
                    daysOfWeek,
                  });
                }}
                variant={"filled"}
                color={"gray"}
                radius={"8px"}
                size={"sm"}
                h={"44px"}
                style={{
                  background: isSelected ? "#e6f5e8" : "#f5f5f5",
                  color: isSelected
                    ? "var(--mantine-color-brand-6)"
                    : "#8c8c8c",
                  border: "0px solid var(--mantine-color-brand-6)",
                  borderWidth: isSelected ? "1px" : "0px",
                }}
              >
                {day.label.charAt(0).toUpperCase()}
              </Button>
            );
          })}
        </Group>

        {form.errors.daysOfWeek && (
          <Text c={Color.Error} px={"md"} mt={"sm"}>
            {form.errors.daysOfWeek}
          </Text>
        )}

        <Divider my={"md"} />
      </Flex>

      <Flex
        align={"center"}
        justify={"center"}
        gap={"sm"}
        pos={"fixed"}
        bottom={"20px"}
        left={0}
        w={"100%"}
      >
        <Button
          variant="outline"
          onClick={() =>
            setManagingPricingObject({
              pricingStrategy: "flat",
              objectIndex: null,
            })
          }
        >
          <Trans>Prekliči</Trans>
        </Button>
        <Button type={"submit"}>
          <Trans>Shrani</Trans>
        </Button>
      </Flex>
    </form>
  );
};
