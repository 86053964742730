import { Trans } from "@lingui/react/macro";
import { i18n } from "@lingui/core";
import { Flex, Text, Stack, Divider, Group, Avatar } from "@mantine/core";
import dayjs from "dayjs";
import {
  RiCalendar2Line,
  RiTimeLine,
  RiBox1Line,
  RiServiceLine,
  RiUserLine,
} from "react-icons/ri";

import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

type Customer = {
  name: string;
  lastName: string;
  email?: string;
  gsm?: string;
  comment?: string;
  bookedFromForm: boolean;
};

type Resource = {
  label: string;
  id: number;
};

type FormattedData = {
  startTime: string;
  endTime: string;
  date: string;
};

type Appointment = {
  formattedData: FormattedData;
  serviceName: string;
  customers: Customer[];
  resources: number[];
  userIds: number[];
  numberOfPeople: number | undefined;
};

type HoverContentProps = {
  appointment: Appointment;
  viewCustomersPermission: boolean;
  resources: Resource[];
  users: User[];
};

type User = {
  userId: number;
  name: string;
  lastName: string;
};

export const HoverContent = ({
  appointment,
  viewCustomersPermission,
  resources,
  users,
}: HoverContentProps) => {
  const { formattedData, serviceName, customers = [] } = appointment;

  const { startTime, endTime, date } = formattedData;

  const { locale } = i18n;
  const appointmentDate = dayjs(date, "DD.MM.YYYY").locale(locale).format("LL");
  const appointmentTime = `${startTime} - ${endTime}`;

  const groupAppointment = appointment.numberOfPeople
    ? appointment.numberOfPeople > 1
    : false;

  return (
    <Stack gap="xs" className="max-w-72 p-1">
      <Group gap="xs">
        <RiCalendar2Line />
        <Text size="sm">{appointmentDate}</Text>
      </Group>
      <Group gap="xs">
        <RiTimeLine />
        <Text size="sm">{appointmentTime}</Text>
      </Group>
      <Divider />
      <Group gap="xs">
        <RiServiceLine />
        <Text size="sm">{serviceName}</Text>
      </Group>
      {appointment.resources.length > 0 && (
        <Group gap="xs">
          <RiBox1Line />
          <Text size="sm" c="dimmed">
            {appointment.resources
              .map(
                (resourceId) =>
                  resources.find((resource) => resource.id === resourceId)
                    ?.label,
              )
              .join(", ")}
          </Text>
        </Group>
      )}
      {appointment.userIds.length > 0 && (
        <Group gap="xs">
          <RiUserLine />
          <Text size="sm" c="dimmed">
            {appointment.userIds
              .map((userId) => {
                const user = users.find((u) => u.userId === userId);
                return `${user?.name} ${user?.lastName}`;
              })
              .join(", ")}
          </Text>
        </Group>
      )}
      {viewCustomersPermission && (
        <>
          <Divider />
          <Stack gap="xs" mt="xs">
            {customers.length > 0 ? (
              <>
                {groupAppointment && (
                  <Text size="sm" c="dimmed">
                    <Trans>
                      {`Št. strank: ${customers.length}`} /{" "}
                      {appointment.numberOfPeople}
                    </Trans>
                  </Text>
                )}
                {customers.map((customer, index) => (
                  <>
                    <Flex align={"center"}>
                      <Avatar
                        size="sm"
                        radius="md"
                        color="green"
                        style={{
                          display: "inline-block",
                          marginRight: "8px",
                          textAlign: "center",
                        }}
                      >
                        {customer.name[0]} {customer.lastName[0]}
                      </Avatar>
                      <Text size="sm" style={{ whiteSpace: "nowrap" }}>
                        {customer.name} {customer.lastName}{" "}
                        {customer.bookedFromForm && (
                          <RiCalendar2Line
                            style={{
                              display: "inline-block",
                            }}
                          />
                        )}
                      </Text>
                    </Flex>
                    <Flex direction="column" align={"flex-start"}>
                      {customer.email && !groupAppointment && (
                        <Text size="xs" c="dimmed">
                          {customer.email}
                        </Text>
                      )}
                      {customer.gsm && (
                        <Text size="xs" c="dimmed">
                          {customer.gsm}
                        </Text>
                      )}
                      {customer.comment && (
                        <Flex
                          align="center"
                          gap="xs"
                          p="xs"
                          style={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "4px",
                            marginTop: "4px",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <Text
                            size="xs"
                            c="dimmed"
                            style={{
                              whiteSpace: "normal",
                              overflowWrap: "break-word",
                            }}
                          >
                            {customer.comment}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </>
                ))}
              </>
            ) : (
              <Text size="sm" c="dimmed">
                <Trans>Na terminu še ni nobene stranke</Trans>
              </Text>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};
