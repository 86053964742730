import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { api } from "@/lib/api-client";
import { TextVariant } from "@/types/text-variants";
import { i18n } from "@lingui/core";
import { Button, Collapse, Flex, Radio, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { LuMail, LuMessageSquare } from "react-icons/lu";
import { LimeDateTimePicker } from "../LimeDateTimePicker";
import { LimeTextInput } from "../LimeTextInput";
import { LimeTextarea } from "../LimeTextarea";

import { Link } from "react-router-dom";
import classes from "./UserAppointmentMessageScheduler.module.css";

type FormType = {
  channel: "sms" | "email";
  subject?: string;
  message: string;
  scheduledTime?: Date;
};

export const UserAppointmentMessageScheduler = ({
  userAppointmentId,
  onClose,
}: {
  userAppointmentId: number;
  onClose?: () => void;
}) => {
  const form = useForm<FormType>({
    initialValues: {
      channel: "sms",
      subject: undefined,
      message: "",
      scheduledTime: undefined,
    },
    validate: {
      channel: (value) =>
        value === "sms" || value === "email"
          ? null
          : t`Vrsta sporočila ni veljavna`,
      scheduledTime: (value) =>
        value && value > new Date() ? null : t`Čas mora biti v prihodnosti`,
      message: (value) =>
        value.length > 0 ? null : t`Polje sporočila je obvezno`,
      subject: (value) => {
        if (form.getValues().channel === "email") {
          return value && value.length > 0 ? null : t`Zadeva je obvezna`;
        }
        return null;
      },
    },
  });

  const [templateOpened, { open: openTemplate, close: closeTemplate }] =
    useDisclosure();

  const { mutateAsync: createUserAppointmentMessageSchedule, isPending } =
    api.message.usePostUserAppointmentMessageSchedule();

  const { data: templatesData } = api.message.useGetMessageTemplate({
    channel: form.values.channel,
  });

  const language = i18n.locale;

  const handleSubmit = async () => {
    const currentValues = form.getValues();

    if (!currentValues.scheduledTime) return;

    const payload: {
      userAppointmentId: number;
      message: string;
      subject?: string;
      channel: "email" | "sms";
      scheduledTime: string;
    } = {
      userAppointmentId,
      message: currentValues.message,
      subject: currentValues.subject,
      channel: currentValues.channel,
      scheduledTime: dayjs(currentValues.scheduledTime).format(
        "YYYY-MM-DDTHH:mm",
      ),
    };

    await createUserAppointmentMessageSchedule(payload)
      .then(() => {
        notifications.show({
          message: t`Sporočilo je bilo uspešno načrtovano`,
          color: "green",
        });

        onClose?.();
      })
      .catch((e) =>
        notifications.show({
          title: t`Napaka`,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          message: (e as any).response.data.error,
          color: "red",
        }),
      );
  };

  const applyTemplate = (templateId: number) => {
    if (!templatesData) return;

    const templateToApply = templatesData.templates.find(
      (template) => template.id === templateId,
    );

    if (!templateToApply) return;

    const messageToApply =
      templateToApply.MessageTemplateLocalized.find(
        (ml) => ml.language === language,
      )?.message ||
      templateToApply.MessageTemplateLocalized.find((ml) => ml.language.length)
        ?.message ||
      "";

    form.setFieldValue("message", messageToApply);

    if (form.values.channel === "email") {
      const subjectToApply =
        templateToApply.MessageTemplateLocalized.find(
          (ml) => ml.language === language,
        )?.subject ||
        templateToApply.MessageTemplateLocalized.find(
          (ml) => ml.language.length,
        )?.subject;

      form.setFieldValue("subject", subjectToApply || undefined);
    }

    closeTemplate();
  };

  return (
    <form>
      <Flex direction={"column"} gap={10}>
        <Radio.Group
          label={t`Vrsta sporočila`}
          {...form.getInputProps("channel")}
        >
          <Flex gap={10} direction={"column"}>
            <Radio
              icon={LuMessageSquare}
              name="sms"
              value={"sms"}
              size="md"
              label={
                <Text variant={TextVariant.Body}>
                  <Trans>SMS</Trans>
                </Text>
              }
              styles={{
                body: {
                  alignItems: "center",
                },
              }}
            ></Radio>
            <Radio
              size="md"
              icon={LuMail}
              name="email"
              value={"email"}
              label={
                <Text variant={TextVariant.Body}>
                  <Trans>Email</Trans>
                </Text>
              }
              styles={{
                body: {
                  alignItems: "center",
                },
              }}
            ></Radio>
          </Flex>
        </Radio.Group>

        {templatesData?.templates.length ? (
          <Button
            variant="transparent"
            onClick={openTemplate}
            w={"fit-content"}
            p={0}
          >
            Uporabi predlogo
          </Button>
        ) : (
          <Flex gap={5}>
            <Text variant={TextVariant.Body}>
              <Trans>Nimate še dodanih predlog.</Trans>
            </Text>
            <Text variant={TextVariant.BodyEmphasized}>
              {" "}
              <Link to={"/dashboard/notifications/templates/create"}>
                {" "}
                Dodaj novo
              </Link>
            </Text>
          </Flex>
        )}

        <Collapse in={templateOpened}>
          <Flex direction={"column"}>
            <Text variant={TextVariant.BodyEmphasized}>
              <Trans>Predloge</Trans>
            </Text>

            {templatesData && (
              <Flex
                direction={"row"}
                className={classes.templateWrapper}
                gap={10}
                wrap={"wrap"}
              >
                {templatesData.templates.map((template, index) => (
                  <Flex
                    key={template.id}
                    onClick={() => applyTemplate(template.id)}
                    className={classes.templateRow}
                    mih={24}
                    align={"center"}
                    justify={"center"}
                    c={"#68A379"}
                  >
                    <Text mr={10} w={"1ch"} variant={TextVariant.Caption}>
                      {index + 1}
                    </Text>
                    <Text w={"100%"} variant={TextVariant.Caption}>
                      {template.channel === "email" ? (
                        <>
                          {template.MessageTemplateLocalized.find(
                            (tl) => tl.language === language,
                          )?.subject ||
                            template.MessageTemplateLocalized.find(
                              (tl) => tl.subject?.length,
                            )?.subject ||
                            undefined}{" "}
                          <br></br>
                        </>
                      ) : undefined}
                      {template.MessageTemplateLocalized.find(
                        (tl) => tl.language === language,
                      )?.message ||
                        template.MessageTemplateLocalized.find(
                          (tl) => tl.message.length,
                        )?.message ||
                        ""}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        </Collapse>

        <Collapse in={form.values.channel === "email"}>
          <LimeTextInput
            label={t`Zadeva`}
            {...form.getInputProps("subject")}
          ></LimeTextInput>
        </Collapse>
        <LimeTextarea
          label={t`Sporočilo`}
          {...form.getInputProps("message")}
          styles={{
            input: {
              padding: 8,
            },
          }}
        ></LimeTextarea>
        <LimeDateTimePicker
          label={t`Datum in ura pošiljanja`}
          placeholder="dd. mm. yy HH:mm"
          valueFormat="DD. MM. YY HH:mm"
          {...form.getInputProps("scheduledTime")}
        ></LimeDateTimePicker>
        <Button
          loading={isPending}
          onClick={() => {
            if (form.validate() && form.isValid()) handleSubmit();
          }}
        >
          <Text>{t`Načrtuj sporočilo`}</Text>
        </Button>
      </Flex>
    </form>
  );
};
