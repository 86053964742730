import { t } from "@lingui/core/macro";
import { TextVariant } from "@/types/text-variants";
import { useLingui } from "@lingui/react";
import { Flex, Text, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import {
  RiFirstAidKitLine,
  RiPauseCircleLine,
  RiRestTimeLine,
  RiRestaurantFill,
  RiWalkFill,
} from "react-icons/ri";
import { useCalendarStore } from "../../store/useCalendarStore";

import { TimeOffType } from "../../../../../../../server/src/types";
import classes from "./TimeoffColumn.module.css";

type TimeoffTypes = TimeOffType;

export const TimeoffColumn = ({
  timeoffs,
  date,
}: {
  timeoffs: {
    timeFrom: string;
    timeTo: string;
    type: TimeoffTypes;
    note: string;
  }[];
  date: string;
}) => {
  useLingui();

  const timeoffMap: Record<
    TimeoffTypes,
    { color: string; icon: JSX.Element; name: string }
  > = {
    BREAK: {
      color: "#969BA9",
      icon: <RiPauseCircleLine className={classes.timeoffTime} />,
      name: t`Pavza`,
    },
    LUNCH: {
      color: "#DFB81E",
      icon: <RiRestaurantFill className={classes.timeoffTime} />,
      name: t`Malica`,
    },
    SICK_LEAVE: {
      color: "#7E96CD",
      icon: <RiFirstAidKitLine className={classes.timeoffTime} />,
      name: t`Bolniška`,
    },
    VACATION: {
      color: " #74B587",
      icon: <RiRestTimeLine className={classes.timeoffTime} />,
      name: t`Dopust`,
    },
    WALK_IN: {
      color: "#F94144",
      icon: <RiWalkFill className={classes.timeoffTime} />,
      name: t`Walk in`,
    },
    OTHER: {
      color: "#3e04d1",
      icon: <RiRestTimeLine className={classes.timeoffTime} />,
      name: t`Drugo`,
    },
  };
  const { calendarData } = useCalendarStore((state) => state);

  const { slotHeight, timeStep, startTime, endTime } = calendarData;

  const dateStartTime = dayjs(`${date} ${startTime}`).format(
    "YYYY-MM-DD HH:mm:ss",
  );
  const dateEndTime = dayjs(`${date} ${endTime}`).format("YYYY-MM-DD HH:mm:ss");

  return (
    <>
      {timeoffs?.map((timeoff, i) => {
        const timeoffStart = dayjs
          .max(dayjs(timeoff.timeFrom), dayjs(dateStartTime))
          ?.format("YYYY-MM-DD HH:mm:ss");

        const timeoffEnd = dayjs
          .min(dayjs(timeoff.timeTo), dayjs(dateEndTime))
          ?.format("YYYY-MM-DD HH:mm:ss");

        const offset =
          (dayjs(timeoffStart).diff(dayjs(dateStartTime), "minute") /
            timeStep) *
          slotHeight;

        const timeOffDuration = dayjs(timeoffEnd).diff(
          dayjs(timeoffStart),
          "minute",
        );

        const height = (timeOffDuration / timeStep) * slotHeight;

        const { color, icon, name } = timeoffMap[timeoff.type];

        const { note } = timeoff;

        return (
          <Tooltip.Floating
            label={name}
            color={color}
            disabled={window.innerWidth < 768}
            key={i}
          >
            <Flex
              className={classes.container}
              pos={"absolute"}
              top={offset}
              w={"100%"}
              h={height}
            >
              <Flex
                direction={"column"}
                bg={`white repeating-linear-gradient(
                    159.5deg,
                    transparent,
                    transparent 8px,
                    rgb(243, 244, 247) 8px,
                    rgb(243, 244, 247) 18px
                  )`}
                align={"center"}
                style={{
                  borderWidth: "2px 2px 2px 6px",
                  borderStyle: "solid",
                  borderColor: color,
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
                className={classes.timeoffWrapper}
                w={"100%"}
              >
                <Flex
                  justify={"space-between"}
                  align={"center"}
                  style={{ width: "100%", padding: "0 2px" }}
                >
                  <Text
                    inline
                    span
                    size={"xs"}
                    ta={"left"}
                    style={{ whiteSpace: "pre", overflow: "hidden" }}
                    variant={TextVariant.Caption}
                    className={classes.timeoffTime}
                  >
                    {`${dayjs(timeoffStart).format("HH:mm")} - ${dayjs(
                      timeoffEnd,
                    ).format("HH:mm")}`}
                    <div
                      style={{
                        color: color,
                        fontSize: "12px",
                        fontWeight: "bold",
                        display: "inline",
                        marginLeft: "2px",
                      }}
                    >
                      {icon}
                    </div>
                  </Text>
                </Flex>
                <Text
                  size={"xs"}
                  c={color}
                  fw={700}
                  style={{
                    whiteSpace: "pre",
                    paddingLeft: "5px",
                    width: "100%",
                  }}
                  ta={"left"}
                  className={classes.timeoffTime}
                >
                  {name}
                </Text>
                <Text w={"100%"}>
                  {timeoff.note && (
                    <Text
                      size={"xs"}
                      c={"#969BA9"}
                      fw={500}
                      style={{
                        paddingLeft: "5px",
                        width: "100%",
                      }}
                      ta={"left"}
                    >
                      {note}
                    </Text>
                  )}
                </Text>
              </Flex>
            </Flex>
          </Tooltip.Floating>
        );
      })}
    </>
  );
};
