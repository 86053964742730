import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimePhoneInput } from "@/Components/NextBase/LimePhoneInput";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { LimeTimeInput } from "@/Components/NextBase/LimeTimeInput";
import { useForm } from "@mantine/form";
import {
  Accordion,
  AccordionItem,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { countries } from "../../../../../../shared/constants/countries";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { LimeTimeZoneSelect } from "@/Components/NextBase/LimeTimeZoneSelect";
import { api } from "@/lib/api-client";
import { useRef } from "react";
import { StripePaymentCurrency } from "@/server-types";
import { isValidPhoneNumber } from "react-phone-number-input";
import { fullPhoneNumberToNumberAndCountryCode } from "@/Utilities";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

type Form = {
  label: string;
  address: string;
  city: string;
  gsm?: string;
  startTime: string;
  endTime: string;
  country: string;
  timeZone?: string;
  paymentCurrency: string;
  showHolidays: boolean;
  disableOnHolidays: boolean;
};

export const CreateLocationDialog = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: (locationId?: number) => void;
}) => {
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  const form = useForm<Form>({
    initialValues: {
      label: "",
      address: "",
      city: "",
      gsm: undefined,
      startTime: "",
      endTime: "",
      country: "",
      timeZone: undefined,
      paymentCurrency: "",
      showHolidays: false,
      disableOnHolidays: false,
    },

    validate: {
      label: (value) => (value === "" ? t`Ime lokacije` : null),
    },

    onValuesChange: (values, previous) => {
      const didShowHolidaysChange =
        values.showHolidays !== previous.showHolidays;

      if (didShowHolidaysChange) {
        if (!values.showHolidays) {
          form.setFieldValue("disableOnHolidays", false);
        } else {
          if (modalBodyRef?.current) {
            setTimeout(() => {
              if (modalBodyRef?.current) {
                modalBodyRef.current.querySelector(".modal-body")!.scrollTop =
                  modalBodyRef.current.scrollHeight;
              }
            }, 150);
          }
        }
      }
    },
  });

  const { data: paymentCurrencies, isFetching: isPaymentCurrenciesFetching } =
    api.values.useGetStripePaymentCurrencies();

  const {
    mutateAsync: postLocation,
    isPending: isPostingLocation,
    processedErrorMessage: postLocationError,
  } = api.location.usePostLocation();

  const handleSubmit = async (values: Form) => {
    try {
      const { gsm, countryCode } = (() => {
        if (!values.gsm || !isValidPhoneNumber(values.gsm)) {
          return { gsm: undefined, countryCode: undefined };
        }

        const parsedNumber = fullPhoneNumberToNumberAndCountryCode(values.gsm);

        return {
          gsm: parsedNumber?.gsm,
          countryCode: parsedNumber?.countryCode,
        };
      })();

      const locationId = await postLocation({
        ...values,
        paymentCurrency: values.paymentCurrency as StripePaymentCurrency,
        gsm,
        countryCode,
        startTime: values.startTime.toString(),
        endTime: values.endTime.toString(),
      });

      handleClose(locationId);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      ref={modalBodyRef}
      onClose={handleClose}
      isDismissable={!isPostingLocation}
      scrollBehavior="outside"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <Trans>Ustvari lokacijo</Trans>
              </ModalHeader>
              <ModalBody className="modal-body scroll-smooth">
                <LimeInput
                  label={t`Ime lokacije`}
                  {...form.getInputProps("label")}
                  isRequired
                />

                <LimeInput
                  label={t`Ulica in hišna številka`}
                  {...form.getInputProps("address")}
                  isRequired
                />

                <LimeInput
                  label={t`Mesto`}
                  {...form.getInputProps("city")}
                  isRequired
                />

                <LimePhoneInput
                  label={t`Telefonska številka`}
                  {...form.getInputProps("gsm")}
                  defaultCountry={"SI"}
                />

                <div className="flex gap-3">
                  <LimeTimeInput
                    label={t`Začetni čas`}
                    {...form.getInputProps("startTime")}
                    isRequired
                  />

                  <LimeTimeInput
                    label={t`Konečni čas`}
                    {...form.getInputProps("endTime")}
                    isRequired
                  />
                </div>

                <LimeSelect
                  label={t`Država`}
                  items={countries.map((country) => ({
                    key: country.code,
                    label: country.name,
                    startContent: getUnicodeFlagIcon(country.code),
                  }))}
                  {...form.getInputProps("country")}
                  isRequired
                />

                <LimeTimeZoneSelect
                  label={t`Časovni pas`}
                  {...form.getInputProps("timeZone")}
                />

                <LimeSelect
                  label={t`Valuta`}
                  items={
                    paymentCurrencies?.map((currency) => ({
                      key: currency,
                      label: currency,
                    })) || []
                  }
                  isLoading={isPaymentCurrenciesFetching}
                  {...form.getInputProps("paymentCurrency")}
                  isRequired
                />

                <div>
                  <LimeSwitch
                    title={t`Prazniki in dela prosti dnevi`}
                    description={t`Vklopi prikaz praznikov in dela prostih dni`}
                    {...form.getInputProps("showHolidays")}
                  />

                  <Accordion
                    selectedKeys={form.getValues().showHolidays ? ["1"] : []}
                    itemClasses={{
                      trigger: "hidden",
                    }}
                    className="px-0"
                  >
                    <AccordionItem key={"1"} title={undefined}>
                      <LimeSwitch
                        title={t`Onemogoči naročanje`}
                        description={t`Dela prosti dnevi`}
                        {...form.getInputProps("disableOnHolidays")}
                      />
                    </AccordionItem>
                  </Accordion>
                </div>

                <LimeAlert color="danger" message={postLocationError} />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onPress={onClose}
                  isDisabled={isPostingLocation}
                >
                  <Trans>Prekliči</Trans>
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  isLoading={isPostingLocation}
                >
                  <Trans>Ustvari</Trans>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
};
