import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { api } from "@/lib/api-client";
import { useLingui } from "@lingui/react";
import {
  Button,
  Chip,
  Drawer,
  Flex,
  MultiSelect,
  ScrollArea,
  Space,
  Switch,
  Tabs,
  Text,
} from "@mantine/core";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import classes from "../../../../Components/LimeDrawer/LimeDrawer.module.css";
import useAlertModal from "../../../../Components/Modals/useAlertModal";
import { useScheduleStore } from "../Stores/useScheduleStore";
import { GetStartAndEndDate } from "../Utils";
import { apiGetShifts } from "../api/apiSchedule";
import BreaksTab from "./UserScheduleModalComponents/BreaksTab";
import BulkDaysList from "./UserScheduleModalComponents/BulkDaysList";
import SpecificShift from "./UserScheduleModalComponents/SpecificShift";

const UserScheduleModal = () => {
  useLingui();

  const {
    createShift,
    selectedUserId,
    scheduleModalOpened,
    toggleScheduleModal,
    selectedModalDate,
    selectedScheduleId,
    selectedScheduleDate,
    selectedScheduleData,
    selectedScheduleViewType,
    deleteShiftsForUser,
  } = useScheduleStore((state) => state);
  const users = useScheduleStore((state) => state.selectedScheduleData?.users);

  const { data: clientSettings, isPending: clientSettingsPending } =
    api.client.useClientSettings();

  const { alertModal } = useAlertModal();
  const [keepTimeOffs, setKeepTimeOffs] = useState(true);

  const isHoliday = selectedScheduleData?.holidays.find((h) => {
    return h.date === selectedModalDate?.format("YYYY-MM-DD");
  });

  const parseData = (existingData) => {
    let _data;

    const isSpecificDay = selectedModalDate != null && selectedUserId != null;

    const { startDate, endDate } = GetStartAndEndDate(
      selectedModalDate ?? selectedScheduleDate,
      isSpecificDay ? "day" : selectedScheduleViewType,
    );

    const dates = [];
    let currentDate = dayjs(startDate);
    const finalDate = dayjs(endDate);
    while (currentDate.isSameOrBefore(finalDate)) {
      dates.push(currentDate);
      currentDate = currentDate.add(1, "day");
    }

    // creates initial data object if no data is present
    if (existingData == null) {
      _data = {
        isAdding: true,
        users: [],
        shifts: dates.map((date) => ({
          date: date.format("YYYY-MM-DD"),
          start: null,
          end: null,
          is_working: false,
          formStart: null,
          formEnd: null,
          is_form_working: false,
          repeat: null,
          breaks: [],
        })),
      };
      return _data;
    }

    // if data is present, create other required days if they are missing
    const { shifts } = existingData;
    const existingDates = shifts?.map((shift) => shift.date);
    const missingDays = dates
      .map((date) => date.format("YYYY-MM-DD"))
      .filter((date) => !existingDates.includes(date));

    _data = {
      ...existingData,
      shifts: [
        ...existingData.shifts,
        ...missingDays.map((day) => ({
          date: day,
          start: null,
          end: null,
          is_working: false,
          formStart: null,
          formEnd: null,
          is_form_working: false,
          repeat: null,
          breaks: [],
        })),
      ],
    };

    // if we clicked on tile with specific date and user but
    // the shift does not exist yet (is empty), set isAdding to true
    if (isSpecificDay) {
      const existShift = _data.shifts.find((shift) => shift?.id != null);

      if (!existShift) {
        _data.isAdding = true;
      }
    }

    // sort shifts by date
    _data.shifts.sort((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    });

    return _data;
  };

  const [data, setData] = useState(null);
  const [isBulk, setIsBulk] = useState(false);
  const [preselectedUsers, setPreselectedUsers] = useState([]);

  useEffect(() => {
    if (!scheduleModalOpened) {
      setData(null);
      return;
    }

    const isBulk = selectedModalDate == null;
    setIsBulk(isBulk);

    if (selectedUserId == null) {
      setData(parseData(null));
      return;
    }

    let selectedDate = selectedModalDate;
    if (selectedDate == null) {
      selectedDate = selectedScheduleDate;
    }

    let startDate;
    let endDate;
    if (isBulk || selectedModalDate == null) {
      startDate = selectedDate.startOf("week").format("YYYY-MM-DD");
      endDate = selectedDate.endOf("week").format("YYYY-MM-DD");
    } else {
      startDate = selectedDate.format("YYYY-MM-DD");
      endDate = selectedDate.format("YYYY-MM-DD");
    }

    apiGetShifts({
      userId: selectedUserId,
      startDate: startDate,
      endDate: endDate,
      scheduleId: selectedScheduleId,
    }).then((response) => {
      if (response.status === 200) {
        setData(parseData(response.data));

        // Convert ids to numbers otherwise multiselect will not work
        setPreselectedUsers(response.data.users.map((user) => user * 1));
      }
    });
  }, [scheduleModalOpened]);

  const onClose = () => {
    toggleScheduleModal({ opened: false });
    setIsBulk(false);
    setPreselectedUsers([]);
  };

  const submitData = async () => {
    const dataForRequest = data;
    // transform users to array of strings
    dataForRequest.users = data.users.map((user) => "" + user);

    dataForRequest.shifts = dataForRequest.shifts.filter(
      (shift) => shift.start && shift.end,
    );

    dataForRequest.keepTimeOffs = keepTimeOffs;

    const response = await createShift(dataForRequest);

    if (response != null) {
      toggleScheduleModal({ opened: false });
      setIsBulk(false);
      setPreselectedUsers([]);
    }
  };

  const updateData = (date, key, value) => {
    setData((prevData) => {
      const _data = { ...prevData };

      if (_data?.shifts == null) {
        return _data;
      }

      const index = _data.shifts.findIndex((shift) => shift.date === date);
      const tempCopy = JSON.parse(JSON.stringify(_data.shifts[index]));
      tempCopy[key] = value;

      _data.shifts = _data.shifts.filter((shift) => shift.date !== date);
      _data.shifts.push(tempCopy);

      return _data;
    });
  };

  const isEditingUserShifts =
    selectedUserId != null && selectedModalDate == null;

  // Find correct modal title
  let modalTitle = t`Dodajanje urnika`;
  const existShift = data?.shifts?.find(
    (shift) => shift?.scheduleShiftId != null,
  );
  if (isEditingUserShifts) {
    modalTitle = users.find((user) => user.userId === selectedUserId)?.name;
  } else if (existShift) {
    modalTitle = capitalize(selectedModalDate?.format("dddd, DD.MM.YYYY"));
  }

  let canSave = true;
  if (data != null) {
    if (data.users?.length === 0) {
      canSave = false;
    }

    // check if there is any shift that has is_working true but time_from or time_to is null
    const existShift = data.shifts?.find(
      (shift) =>
        shift.is_working === true && (shift.start == null || shift.end == null),
    );
    if (existShift) {
      canSave = false;
    }

    // check if there is any shift that has is_form_working true but form_time_from or form_time_to is null
    const existFormShift = data.shifts?.find(
      (shift) =>
        shift.is_working === true &&
        shift.is_form_working === true &&
        (shift.formStart == null || shift.formEnd == null),
    );
    if (existFormShift) {
      canSave = false;
    }

    const isFormOutsideWorkingTimesError = data.shifts?.some((shift) => {
      if (!shift.is_working) {
        return false;
      }

      if (!shift.start || !shift.end) {
        return false;
      }

      if (!shift.formStart || !shift.formEnd) {
        return false;
      }

      if (
        dayjs(shift.formStart).isBefore(dayjs(shift.start)) ||
        dayjs(shift.formEnd).isAfter(dayjs(shift.end))
      ) {
        return true;
      }

      return false;
    });

    if (isFormOutsideWorkingTimesError) {
      canSave = false;
    }

    // check if there is any shift that has any break that has time_from or time_to null
    const existBreak = data.shifts?.find(
      (shift) =>
        shift.is_working === true &&
        shift?.breaks?.find(
          (breakItem) =>
            (breakItem.timeFrom == null || breakItem.timeTo == null) &&
            breakItem.timeTo !== breakItem.timeFrom,
        ) != null,
    );

    if (existBreak) {
      canSave = false;
    }

    if (clientSettings?.maxLunchTimeMinutes != null) {
      const totalBreakDuration = data.shifts
        ?.flatMap((shift) => shift?.breaks)
        .filter((break_) => break_.type === "LUNCH")
        .reduce((acc, curr) => {
          const from = dayjs(curr.from);
          const to = dayjs(curr.to);
          return acc + to.diff(from, "minutes");
        }, 0);

      if (totalBreakDuration > clientSettings.maxLunchTimeMinutes) {
        canSave = false;
      }
    }
  }

  let breaksTabDisabled = false;
  if (data != null) {
    // check if it is working and time from and time to is not null
    const existShift = data.shifts?.find(
      (shift) =>
        shift.is_working === true && shift.start != null && shift.end != null,
    );
    if (!existShift) {
      breaksTabDisabled = true;
    }
  }

  const warnings = [];

  if (isHoliday) {
    warnings.push({
      message: t`Izbran datum je praznik!`,
      variant: "filled",
    });
  }

  return (
    <OuterWrapper>
      <Drawer.Root
        opened={scheduleModalOpened}
        onClose={onClose}
        position={"right"}
        padding={"xl"}
        size={"lg"}
        classNames={classes}
        className={classes.root}
        transitionProps={{
          transition: "slide-left",
          duration: 250,
          timingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
        }}
      >
        <Drawer.Overlay opacity={0.5} blur={2} />
        <Drawer.Content>
          <Drawer.Header>
            <Drawer.Title>
              <Text fw={500} size={"xl"}>
                {modalTitle}
              </Text>
            </Drawer.Title>
            <Drawer.CloseButton />
          </Drawer.Header>
          <Drawer.Body>
            {data != null && (
              <Wrapper>
                <div className={"content"}>
                  <Tabs defaultValue={"working_time"}>
                    <Tabs.List>
                      <Tabs.Tab value={"working_time"}>
                        <Trans>Delovni čas</Trans>
                      </Tabs.Tab>
                      {!isBulk && (
                        <Tabs.Tab
                          value={"free_time"}
                          disabled={breaksTabDisabled || clientSettingsPending}
                        >
                          <Trans>Prosti čas</Trans>
                        </Tabs.Tab>
                      )}
                    </Tabs.List>

                    <WarningsWrapper>
                      {warnings &&
                        warnings.map((warning, index) => (
                          <Chip
                            key={index}
                            checked={true}
                            color={warning.color ?? "red"}
                            variant={warning.variant ?? "filled"}
                          >
                            {warning.message}
                          </Chip>
                        ))}
                    </WarningsWrapper>

                    <ScrollArea.Autosize
                      w={"99%"}
                      mah={window.innerHeight - 255}
                      offsetScrollbars
                    >
                      <Space h={"lg"} />

                      <Tabs.Panel value={"working_time"} pl={"5px"}>
                        <MultiSelect
                          disabled={preselectedUsers.length > 0}
                          label={t`Zaposleni`}
                          placeholder={t`Izberite zaposlene`}
                          data={
                            users?.map((sub) => ({
                              label: `${sub.name} ${sub.lastName}`,
                              value: sub.userId?.toString(),
                            })) ?? []
                          }
                          value={
                            preselectedUsers.length > 0
                              ? preselectedUsers?.map((user) =>
                                  user?.toString(),
                                )
                              : data.users?.map((user) => user?.toString())
                          }
                          onChange={(value) => {
                            setData({
                              ...data,
                              users: value,
                            });
                          }}
                          clearable
                        />
                        <Space h={20} />

                        {isBulk ? (
                          <BulkDaysList
                            viewType={selectedScheduleViewType}
                            data={data}
                            updateData={updateData}
                            holidays={selectedScheduleData?.holidays}
                          />
                        ) : (
                          <SpecificShift data={data} updateData={updateData} />
                        )}
                        <Space h={20} />
                        <Switch
                          label={t`Prepreči brisanje že vnešenih pavz`}
                          labelPosition={"left"}
                          checked={keepTimeOffs}
                          onChange={(event) =>
                            setKeepTimeOffs(event.currentTarget.checked)
                          }
                        />
                      </Tabs.Panel>

                      <Tabs.Panel value={"free_time"}>
                        <BreaksTab
                          data={data}
                          updateData={updateData}
                          date={selectedModalDate?.format("YYYY-MM-DD")}
                          maxLunchTimeMinutes={
                            clientSettings?.maxLunchTimeMinutes
                          }
                        />
                      </Tabs.Panel>
                    </ScrollArea.Autosize>
                  </Tabs>
                </div>

                <div className={"footer"}>
                  <Flex w={"100%"} justify={"space-between"}>
                    {isEditingUserShifts && (
                      <Button
                        variant={"outline"}
                        color={"red"}
                        style={{ flexShrink: 0 }}
                        onClick={() => {
                          alertModal(
                            t`Brisanje urnika za osebo`,
                            t`Ste prepričani da želite izbrisati urnik za osebo?`,
                            {
                              labelCancel: t`Prekliči`,
                              labelConfirm: t`Izbriši`,
                            },
                            () => {
                              deleteShiftsForUser(preselectedUsers.at(0));
                            },
                          );
                        }}
                      >
                        <Trans>Izbriši urnik osebe</Trans>
                      </Button>
                    )}
                    <Flex justify={"flex-end"} gap={"1rem"} w={"100%"}>
                      <Button onClick={onClose} variant="outline">
                        <Trans>Prekliči</Trans>
                      </Button>
                      <Button
                        disabled={!canSave}
                        onClick={submitData}
                        variant="filled"
                      >
                        <Trans>Shrani</Trans>
                      </Button>
                    </Flex>
                  </Flex>
                </div>
              </Wrapper>
            )}
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </OuterWrapper>
  );
};

const WarningsWrapper = styled.div`
  margin: 5px 0;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  .mantine-Chip-iconWrapper {
    display: none;
  }

  .mantine-Chip-label {
    cursor: default;
  }
`;

const OuterWrapper = styled.div`
  .mantine-Drawer-inner {
    right: 10px;
    margin: 0 10px;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    padding: 5px 0;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    background: white;

    height: 100px;
    padding: 20px;
    border-top: 1px solid #eaebed;
  }

  .holiday_span {
    height: 16px;
    line-height: 16px;
    font-size: 10px;
    background-color: #7e96cd;

    color: #fafafa;
    width: 90%;
    border-radius: 12px;

    display: block;
    justify-content: left;
    align-items: center;
    padding: 0 5px;

    cursor: default;
  }
`;

export default UserScheduleModal;
