import { Trans } from "@lingui/react/macro";
import TimeSpanRow from "@/Components/TimeSpanRow";
import { TimeOffType } from "@/server-types";
import { TextVariant } from "@/types/text-variants";
import { useLingui } from "@lingui/react";
import { Box, Button, Flex, Skeleton, Text, TextInput } from "@mantine/core";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { RiAddFill } from "react-icons/ri";
import { Color } from "../../../../../types/colors";

import { api } from "@/lib/api-client";
import dayjs from "dayjs";
import { TimeType } from "../../../../../../../shared/types/time-type";
import { useCalendarStore } from "../../store/useCalendarStore";
import { useBreakModal } from "../hooks/useBreakModal";

// eslint-disable-next-line react/display-name
const BreakModal = forwardRef(
  (
    props: {
      onClose: () => void;
      onLunchTimeValidChange: (value: boolean) => void;
    },
    ref,
  ) => {
    const { data: clientSettings, isSuccess: isClientSettingsSuccess } =
      api.client.useClientSettings();

    const {
      selectedLocationId,
      lastSelectedUserId,
      lastSelectedDate,
      lastSelectedTime,
      calendarData,
    } = useCalendarStore((state) => state);

    const {
      timeoffs,
      data,
      isLoading,
      isError,
      addTimeoff,
      updateTimeoffTimes,
      saveTimeoffs,
      deleteTimeoff,
      updateTimeoffNote,
    } = useBreakModal(
      lastSelectedDate,
      selectedLocationId,
      lastSelectedUserId,
      lastSelectedTime,
      calendarData,
    );
    useLingui();

    useImperativeHandle(ref, () => ({
      async action(refetchTimeoffs: () => void) {
        await saveTimeoffs(props.onClose);

        refetchTimeoffs();
      },
    }));

    useEffect(() => {
      if (
        !isClientSettingsSuccess ||
        clientSettings.maxLunchTimeMinutes == null
      ) {
        props.onLunchTimeValidChange(true);
        return;
      }

      const totalLunchTime = timeoffs
        ?.find((timeoff) => timeoff.type === "LUNCH")
        ?.timeoffs.reduce((acc, timeoff) => {
          const from = dayjs(timeoff.timeFrom);
          const to = dayjs(timeoff.timeTo);
          return acc + to.diff(from, "minutes");
        }, 0);

      if (totalLunchTime != null) {
        props.onLunchTimeValidChange(
          totalLunchTime <= clientSettings.maxLunchTimeMinutes,
        );
      }
    }, [timeoffs, isClientSettingsSuccess, clientSettings]);

    return (
      <Flex direction={"column"}>
        <Skeleton visible={isLoading || isError}>
          {data?.types.map((type) => (
            <Flex gap={10} direction={"column"} key={type.type}>
              <Box style={{ borderBottom: "1px solid #EAEBED" }}>
                <Flex align={"center"} justify={"space-between"}>
                  <Text c={"#8c8c8c"} size="14px">
                    {type.label}
                  </Text>
                  <Button
                    leftSection={<RiAddFill />}
                    variant={"white"}
                    onClick={() => {
                      addTimeoff(
                        type.type as TimeOffType,
                        selectedLocationId,
                        lastSelectedUserId,
                        lastSelectedDate,
                      );
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    <Trans>Dodaj</Trans>
                  </Button>
                </Flex>
              </Box>

              <Box>
                {timeoffs
                  ?.find((timeoff) => timeoff.type === type.type)
                  ?.timeoffs.map((timeoff) => {
                    return (
                      <Flex
                        key={timeoff.id}
                        direction={"column"}
                        gap={10}
                        style={{
                          border: "1px solid #EAEBED",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <TimeSpanRow
                          fromTimeValue={
                            timeoff.timeFrom.split("T")[1] as TimeType
                          }
                          toTimeValue={timeoff.timeTo.split("T")[1] as TimeType}
                          onFromTimeChange={(value): void => {
                            updateTimeoffTimes(
                              timeoff.id,
                              `${lastSelectedDate} ${value}`,
                              timeoff.timeTo,
                            );
                          }}
                          onToTimeChange={(value): void => {
                            updateTimeoffTimes(
                              timeoff.id,
                              timeoff.timeFrom,
                              `${lastSelectedDate} ${value}`,
                            );
                          }}
                          minTime={"00:00"}
                          maxTime={"23:59"}
                        />
                        <Flex direction={"column"} gap={5}>
                          <Text
                            variant={TextVariant.Caption}
                            c={Color.SecondaryText}
                          >
                            <Trans>Opomba</Trans>
                          </Text>
                          <TextInput
                            value={timeoff.note as string}
                            onChange={(e) =>
                              updateTimeoffNote(timeoff.id, e.target.value)
                            }
                          ></TextInput>
                        </Flex>
                        <Flex justify={"start"}>
                          <Button
                            variant={"transparent"}
                            w={"fit-content"}
                            fw={500}
                            color={"red"}
                            size={"xs"}
                            style={{
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              deleteTimeoff(timeoff.id, timeoff.type);
                            }}
                            pl={0}
                          >
                            <Trans>Izbriši</Trans>
                          </Button>
                        </Flex>
                      </Flex>
                    );
                  })}
              </Box>
            </Flex>
          ))}
        </Skeleton>
      </Flex>
    );
  },
);

export default BreakModal;
