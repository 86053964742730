import { t } from "@lingui/core/macro";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { api } from "@/lib/api-client";
import {
  GetOrganizationBusinessPremise,
  GetOrganizationElectronicDevice,
} from "@/server-types";
import { Button, Divider } from "@nextui-org/react";
import { Printer } from "lucide-react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TaxRegisterAuthorizationDialog } from "../AuthorizationDialog";

export const TaxRegisterSetupAct = () => {
  const navigate = useNavigate();

  const printRef = useRef<HTMLDivElement | null>(null);

  const {
    data: businessPremises,
    isFetching: isFetchingBusinessPremises,
    processedErrorMessage: businessPremisesErrorMessage,
    refetch: refetchBusinessPremises,
  } = api.taxRegister.useGetOrganizationBusinessPremise({});

  const {
    data: organization,
    isFetching: isFetchingOrganization,
    processedErrorMessage: organizationErrorMessage,
    refetch: refetchOrganization,
  } = api.taxRegister.useGetOrganization({});

  const {
    data: electronicDevices,
    isFetching: isFetchingElectronicDevices,
    processedErrorMessage: electronicDeviceErrorMessage,
    refetch: refetchElectronicDevices,
  } = api.taxRegister.useGetOrganizationElectronicDevice({});

  const handlePrint = () => {
    if (!printRef.current) {
      return;
    }

    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    // Set the entire page content to the div content
    document.body.innerHTML = printContents;

    // Trigger the print dialog
    window.print();

    // Restore the original page content
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload to restore any lost state
  };

  const [legalRepresentative, setLegalRepresentative] = useState<string>("");

  if (
    !organization ||
    isFetchingOrganization ||
    !electronicDevices ||
    isFetchingElectronicDevices ||
    !businessPremises ||
    isFetchingBusinessPremises
  ) {
    return (
      <>
        <TaxRegisterAuthorizationDialog
          handleSuccessfulAuthorization={async () => {
            refetchBusinessPremises();
            refetchOrganization();
            refetchElectronicDevices();
          }}
          requiresSetupCompleted={false}
        />

        <p>generating...</p>
      </>
    );
  }

  if (
    businessPremisesErrorMessage ||
    organizationErrorMessage ||
    electronicDeviceErrorMessage
  ) {
    return (
      <LimeAlert
        message={
          businessPremisesErrorMessage ||
          organizationErrorMessage ||
          electronicDeviceErrorMessage
        }
      />
    );
  }

  return (
    <>
      <TaxRegisterAuthorizationDialog
        handleSuccessfulAuthorization={async () => {
          refetchBusinessPremises();
          refetchOrganization();
          refetchElectronicDevices();
        }}
        requiresSetupCompleted={false}
      />

      <LimePageHeader
        title={t`Sprejem akta`}
        subPage={{
          title: t`Akt`,
          onBackButtonClick: () => navigate("../", { relative: "path" }),
        }}
      />

      <div className="mt-4 flex w-full flex-col items-center px-4 md:px-8">
        <LimeInput
          className="max-w-3xl"
          value={legalRepresentative}
          onValueChange={setLegalRepresentative}
          label={t`Zakoniti zastopnik`}
          isRequired
        />

        <Divider className="my-4" />

        <div
          id="act"
          className="w-full max-w-3xl overflow-x-auto text-sm"
          ref={printRef}
        >
          <p>
            V skladu s šestim odstavkom 5. člena Zakona o davčnem potrjevanju
            računov (Uradni list RS, št. 57/15 in 69/17) odgovorna oseba{" "}
            {organization.name}, {organization.address}, davčna številka{" "}
            {organization.taxNumber}, zakoniti zastopnik {legalRepresentative},
            sprejema akt.
          </p>

          <Section label="INTERNI AKT">
            <p className="font-semibold">
              o popisu poslovnih prostorov, dodelitvi oznak poslovnim prostorom
              in pravilih za dodeljevanje zaporednih številk računov
            </p>
          </Section>

          <Section label={"1. Člen"}>
            <p>
              S tem internim aktom se ureja popis poslovnih prostorov in
              dodelitev oznak poslovnim prostorom ter predpisujejo pravila za
              dodeljevanje zaporednih številk računov za podjetje{" "}
              {organization.name}, {organization.address}, davčna številka{" "}
              {organization.taxNumber}.
            </p>
          </Section>

          <Section label="2. Člen">
            <p>
              Popis poslovnih prostorov z dodeljenimi oznakami, pripadajočimi
              naslovi in identifikacijskimi oznakami iz registra nepremičnin ter
              vrste premičnih poslovnih prostorov.
            </p>

            <table className="mt-4 w-full border-collapse border-1 text-left">
              <thead>
                <tr>
                  <th className="border-1">Oznaka poslovnega prostora</th>
                  <th className="border-1">Naslov poslovnega prostora</th>
                  <th className="border-1">Številka katastrske občine</th>
                  <th className="border-1">Številka stavbe</th>
                  <th className="border-1">Številka dela stavbe</th>
                  <th className="border-1">Vrsta poslovnega prostora</th>
                </tr>
              </thead>
              <tbody>
                {businessPremises.map((bp) => (
                  <tr>
                    <td className="border-1">{bp.businessPremiseId}</td>
                    <td className="border-1">
                      {bp.street} {bp.houseNumber}
                      {bp.houseNumberAdditional} {bp.postalCode} {bp.city}
                    </td>
                    <td className="border-1">{bp.cadastralNumber}</td>
                    <td className="border-1">{bp.buildingNumber}</td>
                    <td className="border-1">{bp.buildingSectionNumber}</td>
                    <td className="border-1">{bp.typeLabel}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Section>

          <Section label="3. Člen">
            <p>
              Oznake elektronskih naprav, preko katerih se v posameznem
              poslovnem prostoru izdajajo računi:
            </p>

            <table className="mt-4 w-full border-collapse border-1 text-left">
              <thead>
                <tr>
                  <th className="border-1">Oznaka poslovnega prostora</th>
                  <th className="border-1">Oznaka elektronske naprave</th>
                </tr>
              </thead>
              <tbody>
                {electronicDevices.map((ed) => (
                  <tr>
                    <td className="border-1">{ed.businessPremiseLabel}</td>
                    <td className="border-1">{ed.electronicDeviceId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Section>

          <Section label="4. Člen">
            <p>
              Zaporedne številke računov si vsako koledarsko leto, od 1.
              januarja do 31. decembra, sledijo v neprekinjenem zaporedju po
              posameznem poslovnem prostoru, in sicer:
            </p>

            <ul className="my-2 list-inside list-disc">
              {businessPremises.map((bp) => (
                <li>
                  v poslovnem prostoru {bp.businessPremiseId} od zaporedne
                  številke do »n«
                </li>
              ))}
            </ul>

            <p>Zaporedje številk računov je razvidno iz tabele:</p>
            <table className="mt-4 w-full border-collapse border-1 text-left">
              <thead>
                <tr>
                  {businessPremises.map((bp) => (
                    <th className="border-1">{bp.businessPremiseId}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 3 }).map((_, index) => (
                  <tr>
                    {businessPremises.map((bp) => (
                      <td className="border-1">
                        {generateConsecutiveNumber({
                          bp,
                          ed: electronicDevices.find(
                            (ed) => ed.businessPremiseId === bp.id,
                          ),
                          index,
                        })}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr>
                  {businessPremises.map((bp) => (
                    <td className="border-1">...</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </Section>

          <Section label="5. Člen">
            <p>Ta akt začne veljati {new Date().toDateString()}.</p>

            <div className="flex justify-between">
              <p>Ljubljana, {new Date().toDateString()}</p>
              <p>Zakoniti zastopnik: {legalRepresentative}</p>
            </div>
          </Section>
        </div>

        <Divider className="my-4" />

        <Button
          startContent={<Printer />}
          color="primary"
          onPress={handlePrint}
          isDisabled={!legalRepresentative}
        >
          Print
        </Button>
      </div>
    </>
  );
};

const Section = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div className="mb-4 mt-8">
    <h3 className="mb-2 text-center font-semibold">{label}</h3>
    {children}
  </div>
);

const generateConsecutiveNumber = ({
  bp,
  ed,
  index,
}: {
  bp: GetOrganizationBusinessPremise["response"][number];
  ed?: GetOrganizationElectronicDevice["response"][number];
  index: number;
}) => {
  if (!ed) return "";

  return `${bp.businessPremiseId}-${ed.electronicDeviceId}-${index + 1}`;
};
