import { Trans } from "@lingui/react/macro";
import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/Components/ui/carousel";
import { cn } from "@/utils";
import { GetProducts } from "@/server-types";
import { LimeInput } from "@/Components/NextBase/LimeInput";

export type CategoryServicesDrawerProps = {
  categories?: {
    tagId: number;
    localizedName: string;
    color: string | null;
    type: "service" | "product";
  }[];
  services?: {
    serviceId: number;
    color: string | null;
    name: string;
    priceWithTaxFormatted: string;
    service_tag: {
      tag: {
        tagId: number;
        color: string | null;
      };
    }[];
  }[];
  preSelectedCategoryId?: number;
  preSelectedCategoryType?: "all" | "service" | "product";
  products?: GetProducts["response"]["products"];
  selectedServiceIds: number[];
  selectedProductIds: number[];
  isOpen: boolean;
  handleClose: (itemId?: number, itemType?: string) => void;
  search: string;
  setSearch: (value: string) => void;
};

export const CategoriesServicesDrawer = ({
  categories,
  services,
  products,
  selectedServiceIds,
  selectedProductIds,
  preSelectedCategoryId,
  preSelectedCategoryType,
  isOpen,
  handleClose,
  search,
  setSearch,
}: CategoryServicesDrawerProps) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  );
  const [selectedCategoryType, setSelectedCategoryType] = useState<
    "all" | "service" | "product" | null
  >("all");

  useEffect(() => {
    if (selectedCategoryType !== null || selectedCategoryId !== null) return;

    const firstCategory = categories?.[0];
    if (firstCategory) {
      setSelectedCategoryId(firstCategory.tagId);
      setSelectedCategoryType(firstCategory.type);
    }
  }, [categories]);

  useEffect(() => {
    if (preSelectedCategoryId != null || preSelectedCategoryType != null) {
      setSelectedCategoryId(preSelectedCategoryId || null);
      setSelectedCategoryType(preSelectedCategoryType || null);
    }
  }, [preSelectedCategoryId, preSelectedCategoryType]);

  const selectedCategoryServices =
    (selectedCategoryId === -1
      ? services?.filter((service) => service.service_tag.length === 0)
      : services?.filter(
          (service) => service.service_tag[0]?.tag.tagId === selectedCategoryId,
        )
    )?.map(
      (service) =>
        (({
          id: service.serviceId,
          name: service.name,
          color: service.color,
          priceWithTaxFormatted: service.priceWithTaxFormatted,
          type: "service",
          categoryId: service.service_tag[0]?.tag.tagId
        }) as const),
    ) || [];

  const selectedCategoryProducts =
    (selectedCategoryId === -1
      ? products?.filter((product) => product.categoryId == null)
      : products?.filter((product) => product.categoryId === selectedCategoryId)
    )?.map(
      (product) =>
        (({
          id: product.id,
          name: product.name,
          color: product.color,
          priceWithTaxFormatted: product.salesProductData?.grossPriceFormatted,
          type: "product",
          categoryId: product.categoryId,
          stockQuantity: product.salesProductData?.stockQuantity
        }) as const),
    ) || [];

  const items = (() => {
    if (selectedCategoryType === "all") {
      if (selectedCategoryId === -1) {
        return [...selectedCategoryServices, ...selectedCategoryProducts];
      }

      const mappedServices = services?.map(
        (service) =>
          (({
            type: "service",
            id: service.serviceId,
            name: service.name,
            color: service.color,
            priceWithTaxFormatted: service.priceWithTaxFormatted,
            categoryId: service.service_tag[0]?.tag.tagId
          }) as const),
      );
      const mappedProducts = products?.map(
        (product) =>
          (({
            type: "product",
            id: product.id,
            name: product.name,
            color: product.color,

            priceWithTaxFormatted:
              product.salesProductData?.grossPriceFormatted,

            categoryId: product.categoryId,
            stockQuantity: product.salesProductData?.stockQuantity
          }) as const),
      );

      return [...(mappedServices || []), ...(mappedProducts || [])];
    } else if (selectedCategoryType === "service") {
      return selectedCategoryServices;
    } else if (selectedCategoryType === "product") {
      return selectedCategoryProducts;
    }
  })();

  return (
    <Modal size="full" isOpen={isOpen} onClose={handleClose}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              <Trans>Izberite artikel</Trans>
            </ModalHeader>
            <ModalBody className="px-0">
              <LimeInput
                label={<Trans>Iskanje</Trans>}
                className="px-2"
                onValueChange={setSearch}
                value={search}
              />

              {!search ? (
                <Carousel
                  className="w-full pl-2"
                  opts={{
                    dragFree: true,
                    align: "start",
                    axis: "x",
                  }}
                  draggable
                >
                  <CarouselContent className="py-2 pl-1">
                    <CarouselItem
                      key={"-1all"}
                      style={{
                        flex: "0 0 auto",
                        minWidth: 0,
                        maxWidth: "100%",
                      }}
                      draggable
                    >
                      <Button
                        className={cn("w-full", {
                          "ring-2 ring-black": !selectedCategoryId,
                        })}
                        onPress={() => {
                          setSelectedCategoryType("all");
                          setSelectedCategoryId(null);
                        }}
                        style={{
                          backgroundColor: undefined,
                        }}
                        onMouseDown={(e) => e.stopPropagation()} // Prevent button from blocking drag
                        onTouchStart={(e) => e.stopPropagation()} // For touch devices
                      >
                        <p>
                          <Trans>Vsi artikli</Trans>
                        </p>
                      </Button>
                    </CarouselItem>
                    {categories?.map((category, index) => (
                      <CarouselItem
                        key={category.tagId}
                        className={cn("", {
                          "mr-2": index === categories?.length - 1,
                        })}
                        style={{
                          flex: "0 0 auto",
                          minWidth: 0,
                          maxWidth: "100%",
                        }}
                        draggable
                      >
                        <Button
                          className={cn("w-full", {
                            "ring-2 ring-black":
                              selectedCategoryId === category.tagId &&
                              selectedCategoryType === category.type,
                          })}
                          onPress={() => {
                            setSelectedCategoryType(category.type);
                            setSelectedCategoryId(category.tagId);
                          }}
                          onMouseDown={(e) => e.stopPropagation()} // Prevent button from blocking drag
                          onTouchStart={(e) => e.stopPropagation()} // For touch devices
                          style={{
                            backgroundColor: category.color || undefined,
                          }}
                        >
                          <p>{category.localizedName}</p>
                        </Button>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                </Carousel>
              ) : undefined}

              <Divider />

              <div className="grid grid-cols-3 gap-1 px-1">
                {items?.map((item) => {
                  const isSelected = (() => {
                    if (item.type === "service") {
                      return selectedServiceIds.includes(item.id);
                    }

                    if (item.type === "product") {
                      return selectedProductIds.includes(item.id);
                    }

                    return false;
                  })();

                  const category = categories?.find(
                    (category) =>
                      category.tagId == item.categoryId &&
                      category.type === item.type,
                  );

                  return (
                    <Button
                      key={`${item.type}-${item.id}`}
                      className="relative min-h-16 items-baseline justify-start px-2 py-2 text-center"
                      onPress={() => handleClose(item.id, item.type)}
                      style={{
                        backgroundColor:
                          (item.type === "service" ? item.color : undefined) ||
                          category?.color ||
                          undefined,
                      }}
                      isDisabled={isSelected}
                    >
                      <p className="w-full">
                        {item.type === "product" &&
                        item.stockQuantity != null ? (
                          <span className="mr-1 text-xs">
                            {item.type === "product" &&
                              `(${item.stockQuantity}x)`}
                          </span>
                        ) : undefined}
                        {item.name}
                      </p>

                      <p className="absolute bottom-1 right-1 text-xs font-normal">
                        {item.priceWithTaxFormatted}
                      </p>
                    </Button>
                  );
                })}
              </div>
            </ModalBody>
            <ModalFooter className="p-2">
              <Button
                variant="bordered"
                className="h-12 w-full font-semibold"
                onPress={onClose}
              >
                Nazaj
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
