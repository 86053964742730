import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useLingui } from "@lingui/react";
import { Divider, LoadingOverlay } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Bar, Line } from "recharts";
import styled from "styled-components";
import { BarGraph } from "./BarGraph";
import { LineGraph } from "./LineGraph";

import dayjs from "dayjs";
import { RiArrowUpDownFill } from "react-icons/ri";
import { useTabAppointments } from "../hooks/useTabAppointments";
import { AnalyticsCarousel, ChartWithLabel } from "./AnalyticsCarousel";
import { BoxValues } from "./BoxValues/BoxValues";
import { PieGraph } from "./PieGraph";

export const TabAppointments = () => {
  const {
    appointmentsPerDate,
    appointments,
    generalData,
    customers,
    helperDataPerDate,
    isCustomersLoading,
    isRevenueLoading,
  } = useTabAppointments();
  useLingui();

  const isDesktop = useMediaQuery("(min-width:768px)");

  const customDateTooltip = ({
    active,
    payload,
  }: {
    active: boolean;
    payload: { payload: { [key: string]: number } }[];
  }) => {
    if (!active || !payload || payload.length < 1) return;

    const [data] = payload;

    const dateData = data.payload;

    const { date } = dateData;

    const dateText = dayjs(date, "DD. MM.").format("DD. MMMM");

    const appointments =
      dateData[t`Izbrano obdobje`] ||
      (dateData[t`Prihodnje obdobje`] as number);
    const appointmentsLast = dateData[t`Preteklo obdobje`] as number;

    const topText = `${dateText}`;

    const percentChange = () => {
      if (!appointments || !appointmentsLast) return;

      const percent = (
        ((appointments - appointmentsLast) / appointmentsLast) *
        100
      )
        .toFixed(2)
        .replaceAll(".", ",");
      return (
        <div className="percent_change">
          <RiArrowUpDownFill></RiArrowUpDownFill>
          {percent} %
        </div>
      );
    };

    return (
      <div className="date_tooltip_wrap">
        <label className="date">{topText}</label>
        <Divider opacity={1} w={"100%"} color="#F0F0F0"></Divider>
        <div className="revenue_wrap">
          <label className="revenue">{appointments || 0}</label>
          {percentChange()}
        </div>
      </div>
    );
  };

  const charts = [
    {
      label: t`Linearni prikaz terminov`,
      chart: (
        <LineGraph
          data={appointmentsPerDate}
          height={400}
          helperData={helperDataPerDate}
          customTooltip={customDateTooltip}
          isCurrency={false}
          tooltipPrefix={t`Št. terminov:`}
          lines={
            <>
              <Line
                type="bump"
                dataKey={t`Izbrano obdobje`}
                stroke="#548F68"
                dot={false}
                strokeWidth={3}
                animationEasing="ease-out"
              />
              <Line
                type="bump"
                dataKey={t`Preteklo obdobje`}
                stroke="#BFBFBF"
                dot={false}
                strokeDasharray={"5 5"}
                strokeWidth={1}
                animationEasing="ease-out"
              />
              <Line
                type="bump"
                dataKey={t`Prihodnje obdobje`}
                stroke="#7E96CD"
                dot={false}
                strokeDasharray={"5 5"}
                strokeWidth={3}
                animationEasing="ease-out"
                connectNulls={true}
              />
            </>
          }
        ></LineGraph>
      ),
    },
    {
      label: t`Stolpični prikaz terminov`,
      chart: (
        <BarGraph
          data={appointmentsPerDate}
          height={400}
          customTooltip={customDateTooltip}
          helperData={helperDataPerDate}
          isCurrency={false}
          tooltipPrefix={t`Št. terminov:`}
          bars={
            <>
              <Bar
                dataKey={t`Izbrano obdobje`}
                fill="#548F68"
                animationEasing="ease-out"
              />

              <Bar
                dataKey={t`Prihodnje obdobje`}
                fill="#7E96CD"
                animationEasing="ease-out"
                hide={!helperDataPerDate.firstFuture}
              />
            </>
          }
        ></BarGraph>
      ),
    },
  ];

  const pieCharts: ChartWithLabel[] = [
    {
      label: t`Tortni prikaz novih strank `,
      chart: (
        <PieGraph
          height={isDesktop ? 230 : 300}
          label={t`Odstotek novih strank:`}
          value={(customers.new / customers.all) * 100 || 0}
          pieOuterRadius={isDesktop ? 100 : 150}
          pieWidth={isDesktop ? 30 : 45}
        ></PieGraph>
      ),
    },
    {
      label: t`Tortni prikaz terminov preko forme`,
      chart: (
        <PieGraph
          height={isDesktop ? 230 : 300}
          label={t`Odstotek terminov preko spletne forme:`}
          value={(appointments.fromForm / appointments.all) * 100 || 0}
          pieOuterRadius={isDesktop ? 100 : 150}
          pieWidth={isDesktop ? 30 : 45}
        ></PieGraph>
      ),
    },
  ];

  return (
    <Wrapper>
      <LoadingOverlay
        visible={isCustomersLoading || isRevenueLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <div className="perDay">
        <div className="perDayInner">
          <AnalyticsCarousel
            indicatorYOffset={-10}
            indicatorPosition="under"
            labelPosition={isDesktop ? "top" : "bottom"}
            charts={pieCharts}
            isLoaded={!!appointments}
          ></AnalyticsCarousel>
        </div>
      </div>
      <div className="perDate">
        <AnalyticsCarousel
          charts={charts}
          isLoaded={appointmentsPerDate && appointmentsPerDate.length > 0}
          indicatorPosition="under"
          gap={isDesktop ? 10 : 0}
        ></AnalyticsCarousel>
      </div>
      <div className="total">
        <Divider opacity={0.5}></Divider>
        <span>
          <Trans>Splošna analitika</Trans>
        </span>
        <BoxValues data={generalData}></BoxValues>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: "perDate" "total" "perDay";
  width: 100%;

  grid-template-columns: 100%;

  column-gap: 50px;
  row-gap: 50px;

  @media (min-width: 1000px) {
    grid-template-areas: "perDay perDate" "total total";

    grid-template-columns: 30% calc(70% - 50px);

    column-gap: 50px;
    row-gap: 25px;
  }

  @media (min-width: 1250px) {
    grid-template-columns: calc(30% - 50px) 70%;
  }

  @media (min-width: 1650px) {
    grid-template-columns: calc(20% - 50px) 80%;
  }

  .perDay {
    grid-area: perDay;

    background-color: white;

    .perDayInner {
      /* height: 350px; */

      padding: 14px;

      padding-bottom: 40px;

      background: #f5f5f5;

      border-radius: 10px;

      ::-webkit-scrollbar {
        display: none;
      }

      @media (min-width: 768px) {
        padding-bottom: 14px;
      }
    }

    @media (min-width: 1000px) {
      .perDayInner {
        /* height: 300px; */
      }
    }
  }

  .perDate {
    grid-area: perDate;

    width: 100%;

    @media (min-width: 1000px) {
      width: 100%;
    }
  }

  .total {
    grid-area: total;

    display: flex;
    flex-direction: column;

    gap: 26px;

    > span {
      font-size: 20px;
      font-weight: 500;

      color: #262626;
    }
  }

  .date_tooltip_wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;

    background-color: white;
    padding: 5px 10px;
    box-shadow: 0px 5px 20px 0px rgba(166, 166, 166, 0.3);

    .date {
      font-size: 12px;
      font-weight: 500;

      color: #8c8c8c;
    }

    .revenue_wrap {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .revenue {
        font-size: 14px;
        font-weight: 500;

        color: #262626;
      }

      .percent_change {
        display: flex;
        align-items: center;
        gap: 2px;
        color: #7e96cd;

        font-size: 12px;
      }
    }
  }
`;
