import { t } from "@lingui/core/macro";
import { api } from "./lib/api-client";
import { useEffect, useRef } from "react";
import { toast } from "sonner";
import * as Sentry from "@sentry/react";

export const AppVersionChecker = () => {
  const {
    data: serverAppVersionData,
    error: serverAppVersionError,
    isSuccess: isServerAppVersionSuccess,
  } = api.app.useGetAppVersion();

  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const versionGracePeriodSeconds = 1 * 60; // 1 minute in seconds

  const calculateVersionGracePeriod = () => {
    const newVersionDetectedTime = localStorage.getItem(
      "newVersionDetectedTime",
    );

    if (!newVersionDetectedTime) return versionGracePeriodSeconds;

    const newVersionDetectedDate = new Date(newVersionDetectedTime);
    const currentDate = new Date();

    const diff = currentDate.getTime() - newVersionDetectedDate.getTime();
    const diffSeconds = Math.floor(diff / 1000);

    console.log("diffSeconds", versionGracePeriodSeconds - diffSeconds);

    return versionGracePeriodSeconds - diffSeconds;
  };

  const setTimers = (gracePeriod: number) => {
    const notificationTimeout = setTimeout(() => {
      toast.warning(
        t`Nova različica aplikacije je na voljo... Stran se bo samodejno osvežila čez 5 sekund`,
      );

      const reloadTimeout = setTimeout(() => {
        localStorage.removeItem("newVersionDetectedTime");
        window.location.reload();
      }, 5000);
      timeoutsRef.current.push(reloadTimeout);
    }, gracePeriod * 1000);

    timeoutsRef.current.push(notificationTimeout);
  };

  const checkVersion = () => {
    const clientVersion = import.meta.env.VITE_APP_VERSION;

    if (serverAppVersionError || !isServerAppVersionSuccess) {
      if (serverAppVersionError) {
        Sentry.captureException(serverAppVersionError);
      }
      return;
    }

    if (serverAppVersionData.version !== clientVersion) {
      if (localStorage.getItem("newVersionDetectedTime")) return;

      console.log("New version available");

      localStorage.setItem("newVersionDetectedTime", new Date().toISOString());

      const gracePeriod = calculateVersionGracePeriod();
      setTimers(gracePeriod);
    } else {
      if (localStorage.getItem("newVersionDetectedTime")) {
        localStorage.removeItem("newVersionDetectedTime");

        timeoutsRef.current.forEach((timeout) => {
          clearTimeout(timeout);
        });

        timeoutsRef.current = [];
      }
    }
  };

  /**
   * Check for new version every minute
   */
  useEffect(() => {
    const newVersionDetectedTime = localStorage.getItem(
      "newVersionDetectedTime",
    );
    if (newVersionDetectedTime) {
      const gracePeriod = calculateVersionGracePeriod();
      console.log("gracePeriod", gracePeriod);
      setTimers(Math.max(gracePeriod, 0));
    }

    checkVersion();

    return () => {
      timeoutsRef.current.forEach((timeout) => {
        clearTimeout(timeout);
      });

      timeoutsRef.current = [];
    };
  }, [serverAppVersionData]);

  return null;
};
