import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { useLingui } from "@lingui/react";
import {
  Box,
  Center,
  Flex,
  Loader,
  LoadingOverlay,
  SegmentedControl,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FiClock } from "react-icons/fi";
import { MdOutlineAdd } from "react-icons/md";
import { RiCalendarTodoLine } from "react-icons/ri";
import styled from "styled-components";
import DatePicker from "../../../../Components/DatePicker";
import { useScheduleStore } from "../Stores/useScheduleStore";
import DayWeekSchedule from "./DayWeekSchedule";
import MonthSchedule from "./MonthSchedule";
import UserScheduleModal from "./UserScheduleModal";
import {
  Button,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { Filter, FilterX } from "lucide-react";

const ScheduleViewWrapper = ({ selectedSchedule }) => {
  const {
    selectSchedule,
    selectedScheduleData,
    selectedScheduleDate,
    setScheduleDate,
    selectedScheduleViewType,
    setScheduleViewType,
    toggleScheduleModal,
    isLoadingSchedule,
  } = useScheduleStore((state) => state);
  useLingui();

  const [scheduleType, setScheduleType] = React.useState("work" || "online");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (selectedScheduleData == null) {
      return;
    }

    setFilteredUsers(
      selectedScheduleData.users.map((user) => ({
        ...user,
        shown: true,
      })),
    );
  }, [selectedScheduleData]);

  if (selectedSchedule == null) {
    return <Loader />;
  }

  let scheduleViewTypes;
  if (!isMobile) {
    scheduleViewTypes = [
      { label: t`Mesec`, value: "month" },
      { label: t`Teden`, value: "week" },
      { label: t`Dan`, value: "day" },
    ];
  } else {
    if (selectedScheduleViewType === "week") {
      setScheduleViewType("day");
    }
    scheduleViewTypes = [
      { label: t`Mesec`, value: "month" },
      { label: t`Dan`, value: "day" },
    ];
  }

  return (
    <>
      <PageHeader
        title={selectedSchedule ? selectedSchedule.name : t`Urniki osebja`}
        actionButton={{
          label: t`Dodaj urnik`,
          icon: <MdOutlineAdd size={"1.3rem"} />,
          onClick: () => {
            toggleScheduleModal({
              date: null,
              userId: null,
              opened: true,
            });
          },
        }}
        subPageHeader={{
          title: selectedSchedule.name,
          onBackButtonClick: () => selectSchedule(null),
        }}
      />

      <Wrapper>
        <ActionRow>
          <Box w={isMobile ? "100%" : undefined}>
            <SegmentedControl
              color={"brand"}
              transitionDuration={200}
              transitionTimingFunction={"cubic-bezier(0.4, 0, 0.2, 1)"}
              data={[
                {
                  label: (
                    <Center>
                      <FiClock />
                      <Box ml={10}>
                        <Trans>Delovni čas</Trans>
                      </Box>
                    </Center>
                  ),
                  value: "work",
                },
                {
                  label: (
                    <Center>
                      <RiCalendarTodoLine />
                      <Box ml={10}>
                        <Trans>Naročanje</Trans>
                      </Box>
                    </Center>
                  ),
                  value: "online",
                },
              ]}
              value={scheduleType}
              onChange={setScheduleType}
              h={isMobile ? 40 : 36}
              w={isMobile ? "100%" : undefined}
            />
          </Box>
          <Flex
            wrap={"wrap"}
            gap={"10px"}
            align={"center"}
            justify={isMobile ? "space-between" : undefined}
            w={isMobile ? "100%" : undefined}
          >
            <DatePicker
              selectedDate={selectedScheduleDate}
              onDateChange={setScheduleDate}
              calendarView={selectedScheduleViewType}
              showTodayButton={false}
              minDate={dayjs(selectedSchedule.date_from).utc(false)}
              maxDate={dayjs(selectedSchedule.date_to).utc(false)}
              overrideStartDate={selectedScheduleData?.date_from}
              overrideEndDate={selectedScheduleData?.date_to}
            />
            <Flex gap={"10px"} wrap={"wrap"} align={"center"}>
              <SegmentedControl
                style={{ height: "34px" }}
                color={"brand"}
                transitionDuration={200}
                transitionTimingFunction={"cubic-bezier(0.4, 0, 0.2, 1)"}
                data={scheduleViewTypes}
                value={selectedScheduleViewType}
                onChange={setScheduleViewType}
              />

              <Popover placement="bottom-end" showArrow>
                <PopoverTrigger>
                  <Button isIconOnly variant="flat">
                    {filteredUsers.every((s) => s.shown) ? (
                      <FilterX />
                    ) : (
                      <Filter />
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="min-w-72 px-1 py-2">
                    <p className="mb-2 text-lg font-medium capitalize">
                      <Trans>Filter</Trans>
                    </p>
                    <div>
                      <div className="mb-4 flex items-center justify-between">
                        <p className="text-base">
                          <Trans>Zaposleni</Trans>
                        </p>

                        <Button
                          color={
                            filteredUsers.every((s) => s.shown)
                              ? "primary"
                              : "default"
                          }
                          onPress={() => {
                            if (filteredUsers.every((s) => s.shown)) {
                              setFilteredUsers(
                                filteredUsers.map((filteredUser) => ({
                                  ...filteredUser,
                                  shown: false,
                                })),
                              );
                            } else {
                              setFilteredUsers(
                                filteredUsers.map((filteredUser) => ({
                                  ...filteredUser,
                                  shown: true,
                                })),
                              );
                            }
                          }}
                        >
                          <Trans>Izberi vse</Trans>
                        </Button>
                      </div>

                      <div className="flex flex-col gap-2 rounded-md bg-[#fafafa] p-2">
                        {filteredUsers?.map((user) => {
                          const data = {
                            label: `${user?.name} ${user?.lastName}`,
                            checked: user.shown,
                            onChange: () => {
                              setFilteredUsers(
                                filteredUsers?.map((filteredUser) => {
                                  if (filteredUser.userId === user.userId) {
                                    return {
                                      ...filteredUser,
                                      shown: !filteredUser.shown,
                                    };
                                  }

                                  return filteredUser;
                                }),
                              );
                            },
                          };

                          return (
                            <Checkbox
                              isSelected={data.checked}
                              onValueChange={data.onChange}
                            >
                              {data.label}
                            </Checkbox>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </Flex>
          </Flex>
        </ActionRow>
        <div style={{ position: "relative" }}>
          <LoadingOverlay
            visible={isLoadingSchedule}
            overlayBlur={2}
            transitionDuration={200}
          />
          {(selectedScheduleViewType === "day" ||
            selectedScheduleViewType === "week") && (
            <DayWeekSchedule
              scheduleType={scheduleType}
              filteredUsers={filteredUsers}
            />
          )}
          {selectedScheduleViewType === "month" && (
            <MonthSchedule
              scheduleType={scheduleType}
              filteredUsers={filteredUsers}
            />
          )}
        </div>

        <UserScheduleModal />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  .holiday_span {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    background-color: #7e96cd;

    color: #fafafa;
    width: 100%;
    border-radius: 12px;

    display: block;
    justify-content: left;
    align-items: center;
    padding: 0 15px;

    cursor: default;
  }
`;

const ActionRow = styled.div`
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;

  flex-wrap: wrap;
  gap: 10px;
`;

export default ScheduleViewWrapper;
