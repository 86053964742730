import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { useForm } from "@mantine/form";
import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
} from "@nextui-org/react";
import { useEffect } from "react";
import { returnCurrencySymbol } from "../../../../../../../shared/utils/utils";

export const DiscountModal = ({
  isOpen,
  existingData,
  handleClose,
  maxDiscountAmountCents,
  currency,
}: {
  isOpen: boolean;
  existingData?: {
    priceCents?: number;
    discountAmountCents?: number;
    discountPercentage?: number;
    article_name?: string;
  };
  maxDiscountAmountCents?: number;
  currency: string;
  handleClose: (data?: { amount: number; type: "amount" | "percent" }) => void;
}) => {
  const currencySymbol = returnCurrencySymbol({ currency });

  const form = useForm<{
    amount: number;
    type: "amount" | "percent";
  }>({
    initialValues: {
      amount: 0,
      type: "amount",
    },

    validate: {
      amount: (value) => {
        if (value == 0) return null;

        if (
          form.getValues().type === "amount" &&
          existingData?.priceCents != null &&
          (maxDiscountAmountCents != null
            ? value * 100 > maxDiscountAmountCents
            : value * 100 > existingData.priceCents)
        ) {
          return t`Popust ne sme biti večji od ${(maxDiscountAmountCents && maxDiscountAmountCents / 100) || (existingData && existingData?.priceCents / 100)} ${currencySymbol}`;
        }

        if (form.getValues().type === "percent" && (value > 100 || value < 0)) {
          return t`Popust mora biti med 0 in 100`;
        }

        // calculate price after percent discount and check if it's greater than maxDiscountAmountCents
        if (
          form.getValues().type === "percent" &&
          maxDiscountAmountCents != null &&
          existingData?.priceCents != null
        ) {
          const priceCentsToDiscount = (existingData.priceCents * value) / 100;

          if (priceCentsToDiscount > Math.max(0, maxDiscountAmountCents)) {
            return t`Popust ne sme biti večji od ${Math.max(0, maxDiscountAmountCents / 100)} ${currencySymbol}`;
          }
        }
      },
    },
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (existingData) {
      const hasDiscount =
        existingData.discountAmountCents != null ||
        existingData.discountPercentage != null;

      if (!hasDiscount) return;

      const isAmountDiscount = existingData.discountAmountCents != null;

      form.setValues({
        amount: isAmountDiscount
          ? existingData.discountAmountCents! / 100
          : existingData.discountPercentage,
        type: isAmountDiscount ? "amount" : "percent",
      });
    }
  }, [existingData, isOpen]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleClose}>
      <ModalContent>
        {(onClose) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              form.validate();

              if (form.isValid()) {
                const values = form.getValues();
                handleClose({
                  ...values,
                  amount:
                    values.type === "amount"
                      ? values.amount * 100
                      : values.amount,
                });
              }
            }}
          >
            <ModalHeader>
              <Trans>Dodaj popust {existingData?.article_name}</Trans>
            </ModalHeader>
            <ModalBody>
              <div className="mb-8 mt-4 flex justify-center">
                <Tabs
                  radius="full"
                  selectedKey={form.getValues().type}
                  onSelectionChange={(type) =>
                    form.setFieldValue("type", type as "amount" | "percent")
                  }
                >
                  <Tab key={"amount"} title={currencySymbol} />
                  <Tab key={"percent"} title={"%"} />
                </Tabs>
              </div>

              <LimeInput
                placeholder="0"
                type="number"
                min={0}
                {...form.getInputProps("amount")}
                isRequired
                step={0.01}
              />
            </ModalBody>
            <ModalFooter className="flex-col p-2">
              <Divider className="mt-2" />

              <Button type="submit" className="h-12 w-full font-semibold">
                <Trans>Dodaj</Trans>
              </Button>
            </ModalFooter>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};
