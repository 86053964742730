import { LimeNumberInput } from "@/Components/LimeNumberInput";
import { LimePagination } from "@/Components/LimePagination";
import { LimeSelect } from "@/Components/LimeSelect";
import PageContentTable from "@/Components/PageContentTable/PageContentTable";
import useAccountInfo from "@/hooks/useAccountInfo";
import { api } from "@/lib/api-client";
import {
  useGetPaymentsList,
  useGetTaxPayments,
} from "@/lib/api-client/paths/stripe";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { toCents } from "@/utils";
import {
  Alert,
  Badge,
  Button,
  Center,
  Divider,
  Flex,
  Loader,
  Modal,
  Skeleton,
  Text,
} from "@mantine/core";
import { MonthPicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { BiExport } from "react-icons/bi";
import { LuTriangleAlert, LuCheck, LuCornerUpLeft } from "react-icons/lu";
import { useOutletContext, useSearchParams } from "react-router-dom";
import type {
  GetPaymentsList,
  GetTaxPayments,
} from "../../../../../server/src/types";
import type { StripeOutletContextProps } from "./Stripe";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Button as NextUIButton,
  Checkbox,
} from "@nextui-org/react";
import { Filter, FilterX } from "lucide-react";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";

const StatusFilter = {
  succeeded: "succeeded",
  failed: "failed",
  processing: "processing",
  refunded: "refunded",
} as const;
type StatusFilter = keyof typeof StatusFilter;
export const PaymentsOverviewTab = () => {
  const { payments } = useOutletContext<StripeOutletContextProps>();
  const { search } = payments;

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [perPage, setPerPage] = useState("10");

  const [paymentsExportDateRange, setPaymentsExportDateRange] =
    useState<Date | null>(null);

  /**
   * FILTERS
   */
  const filterPaymentStatuses = searchParams.getAll("status");
  const setFilterPaymentStatuses = (statuses: StatusFilter[]) => {
    searchParams.delete("status");
    statuses.forEach((status) => {
      searchParams.append("status", status);
    });
    setSearchParams(searchParams);
  };
  const toggleFilterPaymentStatus = (status: StatusFilter) => {
    const statusFilters = searchParams.getAll("status");
    if (statusFilters.includes(status)) {
      statusFilters.splice(statusFilters.indexOf(status), 1);
    } else {
      statusFilters.push(status);
    }

    searchParams.delete("status");
    statusFilters.forEach((status) => {
      searchParams.append("status", status);
    });
    setSearchParams(searchParams);
  };

  const { data, isLoading, isSuccess } = useGetPaymentsList({
    search,
    page,
    perPage: Number.parseInt(perPage),
    statusFilters:
      filterPaymentStatuses as GetPaymentsList["query"]["statusFilters"],
  });

  const { mutateAsync: getTaxPayments, isPending: isGettingTaxPayments } =
    useGetTaxPayments();

  const exportPayments = async ({
    format,
  }: {
    format: GetTaxPayments["query"]["format"];
  }) => {
    if (!paymentsExportDateRange) {
      return;
    }

    const startDate = dayjs(paymentsExportDateRange)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = dayjs(paymentsExportDateRange)
      .endOf("month")
      .format("YYYY-MM-DD");

    const response = await getTaxPayments({
      startDate,
      endDate,
      format,
    });

    const paymentsString = response.payments;
    const feesString = response.fees;

    if (!paymentsString || !feesString) {
      return;
    }

    downloadFile({
      content: paymentsString,
      type: response.type,
      startDate,
      endDate,
      exportType: "payments",
      format,
    });

    downloadFile({
      content: feesString,
      type: response.type,
      startDate,
      endDate,
      exportType: "fees",
      format,
    });
  };

  const downloadFile = ({
    content,
    type,
    format,
    startDate,
    endDate,
    exportType,
  }: {
    content: string;
    type: string;
    format: "csv" | "xml";
    startDate: string;
    endDate: string;
    exportType: "payments" | "fees";
  }) => {
    const fileType = `${type};charset=utf-8;`;
    const blob = new Blob([content], { type: fileType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${exportType}_${startDate}_${endDate}.${format}`,
    );

    document.body.appendChild(link);

    link.click();
    link.parentNode?.removeChild(link);
  };

  const checkIfShouldReturnToFirstPage = () => {
    if (!data) return;

    if (page > 1 && data.pagination.pageCount < page) {
      // setPage(1);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };
  useEffect(() => {
    checkIfShouldReturnToFirstPage();
  }, [data]);

  const nonProcessingPayments = data?.paymentsList.filter(
    (payment) => payment.status !== "requires_payment_method",
  );
  const processingPayments = data?.paymentsList.filter(
    (payment) => payment.status === "requires_payment_method",
  );

  return (
    <>
      <div>
        <Flex direction={"column"} gap={"md"}>
          <Flex justify={"space-between"}>
            <Popover
              showArrow
              shadow="md"
              onChange={(opened) => {
                if (opened) {
                  setPaymentsExportDateRange(null);
                }
              }}
            >
              <PopoverTrigger>
                <Button
                  variant="light"
                  leftSection={<BiExport />}
                  w={"fit-content"}
                >
                  Izvozi plačila za računovodstvo
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Flex direction={"column"} gap={"md"}>
                  <MonthPicker
                    value={paymentsExportDateRange}
                    onChange={setPaymentsExportDateRange}
                  />
                  <Flex gap={"md"}>
                    <Button
                      onClick={() => exportPayments({ format: "csv" })}
                      loading={isGettingTaxPayments}
                      fullWidth
                    >
                      <Trans>Izvozi CSV</Trans>
                    </Button>
                    <Button
                      onClick={() => exportPayments({ format: "xml" })}
                      loading={isGettingTaxPayments}
                      fullWidth
                    >
                      <Trans>Izvozi XML</Trans>
                    </Button>
                  </Flex>
                </Flex>
              </PopoverContent>
            </Popover>

            <Popover placement="bottom-end" showArrow>
              <PopoverTrigger>
                <NextUIButton isIconOnly variant="flat">
                  {filterPaymentStatuses.length !==
                  Object.keys(StatusFilter).length ? (
                    <FilterX />
                  ) : (
                    <Filter />
                  )}
                </NextUIButton>
              </PopoverTrigger>
              <PopoverContent>
                <div className="min-w-72 px-1 py-2">
                  <p className="mb-2 text-lg font-medium capitalize">
                    <Trans>Filter</Trans>
                  </p>
                  <div>
                    <div className="mb-4 flex items-center justify-between">
                      <p className="text-base">
                        <Trans>Tipi</Trans>
                      </p>

                      <NextUIButton
                        color={
                          filterPaymentStatuses.length ===
                          Object.keys(StatusFilter).length
                            ? "primary"
                            : "default"
                        }
                        onPress={() => {
                          if (
                            filterPaymentStatuses.length ===
                            Object.keys(StatusFilter).length
                          ) {
                            setFilterPaymentStatuses([]);
                          } else {
                            setFilterPaymentStatuses(
                              Object.values(StatusFilter),
                            );
                          }
                        }}
                      >
                        <Trans>Izberi vse</Trans>
                      </NextUIButton>
                    </div>

                    <div className="flex flex-col gap-2 rounded-md bg-[#fafafa] p-2">
                      {[
                        {
                          label: t`Uspešno`,
                          value: "succeeded",
                          checked: filterPaymentStatuses.includes("succeeded"),
                          onChange: () =>
                            toggleFilterPaymentStatus("succeeded"),
                        },
                        {
                          label: t`Neuspešno`,
                          value: "failed",
                          checked: filterPaymentStatuses.includes("failed"),
                          onChange: () => toggleFilterPaymentStatus("failed"),
                        },
                        {
                          label: t`V obdelavi`,
                          value: "processing",
                          checked: filterPaymentStatuses.includes("processing"),
                          onChange: () =>
                            toggleFilterPaymentStatus("processing"),
                        },
                        {
                          label: t`Povrnjeno`,
                          value: "refunded",
                          checked: filterPaymentStatuses.includes("refunded"),
                          onChange: () => toggleFilterPaymentStatus("refunded"),
                        },
                      ]?.map((row) => {
                        return (
                          <Checkbox
                            isSelected={row.checked}
                            onValueChange={row.onChange}
                            value={row.value}
                          >
                            {row.label}
                          </Checkbox>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </Flex>

          {isLoading && (
            <Flex direction={"column"} gap={"md"} mt={"45px"}>
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
            </Flex>
          )}

          {isSuccess &&
            filterPaymentStatuses.includes("processing") &&
            processingPayments &&
            processingPayments.length > 0 && (
              <PageContentTable
                checkboxSelectors={false}
                idValue={"date"}
                objects={processingPayments}
                values={
                  processingPayments.map((payment) => [
                    {
                      label: t`Status`,
                      value: <StatusBadge payment={payment} />,
                    },
                    {
                      label: t`Naročilo`,
                      value: payment.payment,
                    },
                    {
                      label: t`Datum`,
                      value: payment.date,
                    },
                    {
                      label: t`Znesek`,
                      value: `${payment.amountFormatted}`,
                    },
                  ]) as never[]
                }
                selectRow={(
                  row: GetPaymentsList["response"]["paymentsList"][0],
                ) => {
                  searchParams.set("uid", row.userAppointmentId.toString());
                  setSearchParams(searchParams);
                }}
                isLoading={false}
                isMobile={false}
                toggleAllSelected={() => {}}
                toggleSelectedObject={() => {}}
                perPage={10}
              />
            )}

          {isSuccess &&
            nonProcessingPayments &&
            nonProcessingPayments.length > 0 && (
              <PageContentTable
                checkboxSelectors={false}
                idValue={"date"}
                objects={nonProcessingPayments}
                values={
                  nonProcessingPayments.map((payment) => [
                    {
                      label: t`Status`,
                      value: <StatusBadge payment={payment} />,
                    },
                    {
                      label: t`Naročilo`,
                      value: payment.payment,
                    },
                    {
                      label: t`Datum plačila`,
                      value: payment.date,
                    },
                    {
                      label: t`Znesek`,
                      value: `${payment.amountFormatted}`,
                    },
                  ]) as never[]
                }
                selectRow={(
                  row: GetPaymentsList["response"]["paymentsList"][0],
                ) => {
                  searchParams.set("uid", row.userAppointmentId.toString());
                  setSearchParams(searchParams, { replace: true });
                }}
                isLoading={false}
                isMobile={false}
                toggleAllSelected={() => {}}
                toggleSelectedObject={() => {}}
                perPage={10}
              />
            )}

          {isSuccess && data.paymentsList.length === 0 && (
            <Text variant={TextVariant.Body} ta={"center"}>
              <Trans>Ni plačil</Trans>
            </Text>
          )}

          {isSuccess && data.includesPaymentsBefore17Oct && (
            <Alert variant="light" color="red" title={t`Obvestilo`}>
              <Trans>
                V seznamu so prikazana plačila od 17. 10. 2023 dalje. Za podatke
                o plačilih pred tem datumom, prosimo, da nas kontaktirate.
              </Trans>
            </Alert>
          )}

          <LimePagination
            page={page}
            setPage={(page) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            }}
            perPage={perPage}
            setPerPage={setPerPage}
            pageCount={isSuccess ? data.pagination.pageCount : 1}
            disabled={isLoading}
            label={t`plačil`}
            maxPerPage={"100"}
          />
        </Flex>
      </div>

      {searchParams.has("uid") && (
        <PaymentDetailsModal
          userAppointmentId={Number(searchParams.get("uid"))}
          onClose={({ refetchData }) => {
            searchParams.delete("uid");
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
};

type RefundForm = {
  amount: number;
  reason: "requested_by_customer" | "duplicate";
};

const PaymentDetailsModal = ({
  userAppointmentId,
  onClose,
}: {
  userAppointmentId?: number;
  onClose: ({ refetchData }: { refetchData?: boolean }) => void;
}) => {
  const {
    data: payment,
    isSuccess,
    isPending,
  } = api.stripe.useGetPaymentForOverview(Number(userAppointmentId));

  const { accountInfo } = useAccountInfo((state) => state);
  const clientId = accountInfo?.clientId;

  const refundForm = useForm<RefundForm>({
    mode: "controlled",
    validate: {
      amount: (value) => {
        if (value < 1) {
          return t`Znesek mora biti večji od 0`;
        }

        if (value > (payment?.amountCents || 0)) {
          return t`Znesek ne sme biti večji od maksimalnega zneska za vračilo`;
        }

        return true;
      },
    },
  });

  const { mutateAsync: refundPayment, isPending: isRefunding } =
    api.stripe.usePostPaymentRefund();

  const handleRefundPayment = async () => {
    if (!payment || !payment.paymentIntentId) return;

    try {
      await refundPayment({
        intentId: payment.paymentIntentId,
        body: {
          ...refundForm.getValues(),
          amountCents: toCents(refundForm.getValues().amount),
        },
      });

      onClose({ refetchData: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!payment) {
      return;
    }

    refundForm.setValues({
      amount: payment.maxNoLossRefundAmountCents / 100,
      reason: "requested_by_customer",
    });
  }, [payment]);

  return (
    <Modal
      opened={true}
      onClose={() => onClose({})}
      title={t`Podrobnosti plačila`}
    >
      {isPending && (
        <Center>
          <Loader />
        </Center>
      )}

      {isSuccess && (
        <Flex direction={"column"}>
          <Text variant={TextVariant.BodyEmphasized} mb={"xs"}>
            <Trans>Podatki termina</Trans>
          </Text>
          <Text variant={TextVariant.Body}>
            <Text variant={TextVariant.Caption} span>
              <Trans>Datum in čas</Trans>:
            </Text>{" "}
            {payment.appointmentStartTime}
          </Text>

          <Text variant={TextVariant.Body}>
            <Text variant={TextVariant.Caption} span>
              <Trans>Lokacija</Trans>:
            </Text>{" "}
            {payment.location.label} - {payment.location.address},{" "}
            {payment.location.city}
          </Text>

          {payment.resources.length > 0 && (
            <>
              <Text variant={TextVariant.Body}>
                <Text variant={TextVariant.Caption} span>
                  <Trans>Sredstva</Trans>:
                </Text>{" "}
                {payment.resources.map((resource) => resource.name).join(", ")}
              </Text>
            </>
          )}

          <Text variant={TextVariant.Body}>
            <Text variant={TextVariant.Caption} span>
              <Trans>Storitev</Trans>:
            </Text>{" "}
            {payment.services.map((service) => (
              <Text key={service.name} variant={TextVariant.Body}>
                {service.name} {service.users.length > 0 && "-"}{" "}
                {service.users.map((user) => `${user.name} ${user.lastName}`)}
              </Text>
            ))}
          </Text>

          <Divider mt={"md"} />

          <Text variant={TextVariant.BodyEmphasized} mb={"xs"} mt={"md"}>
            <Trans>Podatki stranke</Trans>
          </Text>
          <Text variant={TextVariant.Body}>
            {payment.customer.name} {payment.customer.lastName}
          </Text>

          {payment.customer.email && (
            <a href={`mailto:${payment.customer.email}`}>
              <Text variant={TextVariant.Body}>{payment.customer.email}</Text>
            </a>
          )}

          {payment.customer.phone && (
            <a href={`tel:${payment.customer.phone}`}>
              <Text variant={TextVariant.Body}>{payment.customer.phone}</Text>
            </a>
          )}

          <Text variant={TextVariant.BodyEmphasized} mb={"xs"} mt={"md"}>
            <Trans>Plačilo</Trans>
          </Text>
          <Text variant={TextVariant.Body}>
            <Text variant={TextVariant.Caption} span>
              <Trans>Identifikacija</Trans>:
            </Text>{" "}
            {payment.payment}
          </Text>
          <Text variant={TextVariant.Body}>
            <Text variant={TextVariant.Caption} span>
              <Trans>Datum plačila</Trans>:
            </Text>{" "}
            {payment.paymentDate}
          </Text>
          <Flex align={"center"}>
            <Text variant={TextVariant.Caption} span mr={"xs"}>
              <Trans>Status:</Trans>
            </Text>
            <StatusBadge payment={payment} />
            {payment.status !== "succeeded" &&
              !payment.refundAmount &&
              payment.status !== "requires_payment_method" && (
                <Text span ml={"xs"} variant={TextVariant.Caption}>
                  ({payment.status})
                </Text>
              )}
            {payment.refundAmount && (
              <Text span ml={"xs"} variant={TextVariant.Caption}>
                - {payment.refundAmount}
              </Text>
            )}
          </Flex>
          {payment.status === "succeeded" && (
            <Text variant={TextVariant.Body}>
              <Text variant={TextVariant.Caption} span>
                <Trans>Znesek</Trans>:
              </Text>{" "}
              {payment.amountFormatted}
            </Text>
          )}

          {payment.status === "succeeded" && (
            <Flex direction={"column"} gap={"sm"}>
              <Divider mt={"md"} />

              <Text variant={TextVariant.BodyEmphasized} mt={"xs"}>
                <Trans>Vračilo</Trans>
              </Text>

              <LimeNumberInput
                label={t`Znesek`}
                size="sm"
                action={{
                  text: t`Celoten znesek`,
                  onClick: () => {
                    refundForm.setValues({
                      ...refundForm.getValues(),
                      amount: payment.amountCents / 100,
                    });
                  },
                  active:
                    refundForm.getValues().amount === payment.amountCents / 100,
                }}
                {...refundForm.getInputProps("amount")}
                max={payment.amountCents / 100}
                min={0.01}
                disabled={isRefunding}
                allowDecimal
                decimalScale={2}
                fixedDecimalScale
                rightSection={payment.currencySymbol}
              />

              <LimeSelect
                size="sm"
                label={t`Razlog`}
                data={[
                  {
                    label: t`Zahtevano s strani stranke`,
                    value: "requested_by_customer",
                  },
                  {
                    label: t`Podvojeno plačilo`,
                    value: "duplicate",
                  },
                ]}
                {...refundForm.getInputProps("reason")}
                allowDeselect={false}
                disabled={isRefunding}
              />

              {refundForm.getValues().amount >
                payment.maxNoLossRefundAmountCents / 100 && (
                <Text c={Color.Error} variant={TextVariant.Caption} mt={"xs"}>
                  <Trans>
                    Razlika med zneskom vračila in maksimalnim zneskom, ki ga
                    lahko vrnete brez izgube (
                    {payment.maxNoLossRefundAmountCentsFormatted}), bo vzeta iz
                    vašega računa.
                  </Trans>
                </Text>
              )}

              <Button loading={isRefunding} onClick={handleRefundPayment}>
                <Trans>
                  Vračilo{" "}
                  {refundForm.getValues().amount === payment.amountCents / 100
                    ? "celotnega"
                    : "dela"}{" "}
                  zneska
                </Trans>
              </Button>
            </Flex>
          )}
        </Flex>
      )}
    </Modal>
  );
};

const StatusBadge = ({
  payment,
}: {
  payment: {
    status: string | null;
    refundAmount: string | null;
  };
}) => {
  if (payment.refundAmount != null) {
    return (
      <Badge leftSection={<LuCornerUpLeft />} color={"blue"}>
        <Trans>Povrjeno</Trans>
      </Badge>
    );
  }

  if (payment.status === "requires_payment_method") {
    return (
      <Badge leftSection={<LuTriangleAlert />} color={"yellow"}>
        <Trans>V obdelavi</Trans>
      </Badge>
    );
  }

  if (payment.status === "succeeded") {
    return (
      <Badge leftSection={<LuCheck />} color={"green"}>
        <Trans>Uspešno</Trans>
      </Badge>
    );
  }

  return (
    <Badge leftSection={<LuTriangleAlert />} color="red">
      <Trans>Neuspešno</Trans>
    </Badge>
  );
};
