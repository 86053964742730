import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeSwitch } from "@/Components/LimeSwitch";
import { LimeTextInput } from "@/Components/LimeTextInput";
import { api } from "@/lib/api-client";
import { Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";

type Form = {
  enabled: boolean;
  pixelId: string;
  conversionApiAccessToken: string;
};

export const FacebookConversionApi = () => {
  const {
    mutateAsync: postSetupFacebookConversionAPI,
    isPending: isLoadingPostSetupFacebookConversionAPI,
    error: errorPostSetupFacebookConversionAPI,
  } = api.integrations.usePostSetupFacebookConversionAPI();

  const {
    data: conversionApiData,
    isPending: isLoadingConversionApiData,
    error: errorConversionApiData,
  } = api.integrations.useGetFacebookConversionApiData();

  const form = useForm<Form>({
    initialValues: {
      enabled: false,
      pixelId: "",
      conversionApiAccessToken: "",
    },

    validate: {
      pixelId: (value) => {
        const finalValue = value.trim();

        if (finalValue.length === 0) return t`To polje je obvezno`;

        // should only contain numbers and no whitespaces
        if (!/^\d+$/.test(finalValue)) return t`Neveljavna vrednost`;
      },
      conversionApiAccessToken: (value) => {
        const finalValue = value.trim();

        if (finalValue.length === 0) return t`To polje je obvezno`;
      },
    },
  });

  useEffect(() => {
    if (conversionApiData) {
      form.initialize({
        enabled: conversionApiData.isEnabled,
        pixelId: conversionApiData?.pixelId?.trim() || "",
        conversionApiAccessToken: conversionApiData?.accessToken?.trim() || "",
      });
    }
  }, [conversionApiData]);

  const submitForm = async (values: Form) => {
    await postSetupFacebookConversionAPI(values);

    notifications.show({
      message: t`Konfiguracija uspesno shranjena`,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submitForm)}>
      <div className="flex max-w-screen-lg flex-col gap-4 px-4 md:px-8">
        <p className="pt-4 text-xl font-medium">Facebook Conversion API</p>

        {errorConversionApiData && (
          <p className="text-red-500">{errorConversionApiData.message}</p>
        )}

        <div className="max-w-60">
          <LimeSwitch
            label={t`Integracija aktivna`}
            {...form.getInputProps("enabled", { type: "checkbox" })}
          />
        </div>

        <LimeTextInput
          label={t`Facebook Pixel ID`}
          {...form.getInputProps("pixelId")}
          disabled={isLoadingConversionApiData}
        />

        <LimeTextInput
          label={t`Facebook Conversion API Access Token`}
          {...form.getInputProps("conversionApiAccessToken")}
          disabled={isLoadingConversionApiData}
        />

        {errorPostSetupFacebookConversionAPI && (
          <p className="text-red-500">
            {errorPostSetupFacebookConversionAPI.message}
          </p>
        )}

        <Button
          loading={isLoadingPostSetupFacebookConversionAPI}
          disabled={isLoadingConversionApiData}
          type="submit"
        >
          <Trans>Shrani</Trans>
        </Button>
      </div>
    </form>
  );
};
