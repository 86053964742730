import { t } from "@lingui/core/macro";
import { Color } from "@/types/colors";
import { Flex, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const BarGraph = ({
  data,
  helperData,
  customTooltip,
  bars,
  height,
  isCurrency = true,
  tooltipPrefix = "",
  tooltipSuffix = "",
}) => {
  const [activePoint, setActivePoint] = useState(null);

  const isDesktop = useMediaQuery("(min-width:768px)");

  useEffect(() => {
    if (!data || data.length < 1) return;
    if (activePoint) return;

    const firstPoint = data[0];

    let value =
      firstPoint[t`Izbrano obdobje`] ?? firstPoint[t`Prihodnje obdobje`] ?? 0;

    if (isCurrency) {
      value = value.toFixed(2).replace(".", ",");
    }

    const label = firstPoint.date;

    setActivePoint({ value, label });
  }, [data, activePoint, isCurrency]);

  if (!data || data.length < 1) return;

  let yWidth = 25;

  //calculate width of yAxis based on the largest value
  const maxSelected = Math.max(...data.map((d) => d[t`Izbrano obdobje`]));
  const maxPast = Math.max(...data.map((d) => d[t`Preteklo obdobje`]));
  const maxFuture = Math.max(...data.map((d) => d[t`Prihodnje obdobje`]));

  const array = [];
  if (maxSelected) array.push(maxSelected);
  if (maxPast) array.push(maxPast);
  if (maxFuture) array.push(maxFuture);

  const max = Math.max(...array) + 100;

  if (max > 99) yWidth = 30;

  if (max > 999) yWidth = 36;

  if (max > 9999) yWidth = 45;

  return (
    <>
      {!isDesktop ? (
        <Flex direction={"column"} mb={20}>
          <Flex align={"center"} gap={5}>
            {activePoint ? (
              <>
                {tooltipPrefix && (
                  <Text size={"16px"} c={Color.PrimaryText} mih={16 * 1.55}>
                    {tooltipPrefix}
                  </Text>
                )}
                <Text
                  size={"24px"}
                  fw={600}
                  c={Color.PrimaryText}
                  mih={24 * 1.55}
                >
                  {activePoint.value} {tooltipSuffix}
                </Text>
              </>
            ) : (
              ""
            )}
          </Flex>
          <Text size={"14px"} fw={400} c={Color.SecondaryText} mih={14 * 1.55}>
            {activePoint?.label}
          </Text>
        </Flex>
      ) : (
        <></>
      )}
      <ResponsiveContainer width={"100%"} height={height}>
        {data && data.length > 0 ? (
          <BarChart
            data={data}
            margin={{ top: 0 }}
            style={{
              fontSize: "12px",
            }}
            onClick={(e) => {
              if (!e) return;
              let value =
                e?.activePayload?.find((a) => a.dataKey === t`Izbrano obdobje`)
                  ?.value ??
                e?.activePayload?.find(
                  (a) => a.dataKey === t`Prihodnje obdobje`,
                )?.value ??
                0;

              if (isCurrency) {
                value = value.toFixed(2).replace(".", ",");
              }

              const label = e?.activeLabel;

              setActivePoint({ value, label });
            }}
            onMouseMove={(e) => {
              if (!e) return;
              let value =
                e?.activePayload?.find((a) => a.dataKey === t`Izbrano obdobje`)
                  ?.value ??
                e?.activePayload?.find(
                  (a) => a.dataKey === t`Prihodnje obdobje`,
                )?.value ??
                0;
              if (isCurrency) {
                value = value.toFixed(2).replace(".", ",");
              }

              const label = e?.activeLabel;

              setActivePoint({ value, label });
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="date" padding={{ left: 30 }} stroke="#8C8C8C" />
            <YAxis tickLine={false} stroke="#8C8C8C" width={40} />
            <Tooltip
              content={isDesktop ? customTooltip : <></>}
              cursor={{ fill: "#F5F5F5" }}
            />
            <Legend
              align={"left"}
              iconType="plainline"
              wrapperStyle={{
                left: yWidth - 5,
                width: `calc(100% - ${yWidth - 5}px)`,
              }}
            />
            {helperData?.firstFuture && (
              <ReferenceArea
                x1={helperData.firstFuture}
                y1={0}
                y2={1000}
                fill="#7E96CD"
                fillOpacity={0.05}
              ></ReferenceArea>
            )}
            {bars}
          </BarChart>
        ) : (
          <></>
        )}
      </ResponsiveContainer>
    </>
  );
};
