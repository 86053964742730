import { t } from "@lingui/core/macro";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { Flex, Pagination, Tabs } from "@mantine/core";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAdminStore } from "./admin.store";
import { ClientDetails } from "./components/ClientDetails/ClientDetails";
import { ClientsTable } from "./components/ClientsTable";
import { FilterRow } from "./components/FilterRow";
import { useClients } from "./hooks/useClients";
import { LimePageHeader } from "@/Components/LimePageHeader";
import { EracuniIndex } from "./Eracuni";
import PricingTiers from "./PricingTiers";
import React from "react";
import PricingTierEdit from "./PricingTiers/PricingTierEdit";

export const LimeAdmin = () => {
  const { data, handleChangeSalesPersonForClient, isLoading } = useClients();

  const { setPage, page } = useAdminStore((state) => state);

  const location = useLocation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(() => {
    const path = location.pathname.split("/").pop();
    return path || "clients";
  });

  const handleTabChange = (value: string | null) => {
    if (value) {
      if (value === "clients") {
        setTabValue("clients");
        navigate("");
        return;
      }

      setTabValue(value);
      navigate(value);
    }
  };

  return (
    <>
      <PageHeader title={t`Admin`} />
      <Tabs
        defaultValue="clients"
        value={tabValue}
        onChange={handleTabChange}
        keepMounted={false}
        orientation="horizontal"
      >
        <Tabs.List>
          <Tabs.Tab value="clients">{t`Clients`}</Tabs.Tab>
          <Tabs.Tab value="pricing-tiers">{t`Pricing Tiers`}</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Routes>
        <Route
          path="/"
          element={
            <Flex
              gap={10}
              direction={"column"}
              top={0}
              left={0}
              right={0}
              style={{ zIndex: 1 }}
            >
              <FilterRow
                industries={data?.industries || []}
                salesPeople={data?.salesPeople || []}
                pricingTiers={data?.pricingTiers || []}
              ></FilterRow>
              <ClientsTable
                data={data}
                handleChangeSalesPersonForClient={
                  handleChangeSalesPersonForClient
                }
                isLoading={isLoading}
              ></ClientsTable>

              <Pagination
                w={"100%"}
                total={data?.totalPages ?? 10}
                value={page}
                onChange={setPage}
                size={"xs"}
                siblings={1}
                boundaries={1}
              ></Pagination>
            </Flex>
          }
        ></Route>
        <Route
          path="client/:id"
          element={<ClientDetails></ClientDetails>}
        ></Route>

        <Route path="eracuni/*" element={<EracuniIndex></EracuniIndex>}></Route>
        <Route
          path="pricing-tiers"
          element={<PricingTiers></PricingTiers>}
        ></Route>
        <Route
          path="pricing-tiers/:pricingTierId"
          element={<PricingTierEdit></PricingTierEdit>}
        ></Route>
      </Routes>
    </>
  );
};
