import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { getDurationsAndPrice } from "@/Utilities";
import { useLingui } from "@lingui/react";
import { Text, TextInput } from "@mantine/core";
import React from "react";
import { RiSearch2Line } from "react-icons/ri";
import styled from "styled-components";
import ServiceSelectList from "./ServiceSelectList";

const ServiceSelect = ({
  services,
  serviceTags,
  selectedServices,
  setSelectedServices,
  setConfirmedServiceSelection,
}) => {
  useLingui();
  const [filter, setFilter] = React.useState("");

  const filteredServices = React.useMemo(() => {
    if (!services) return null;

    return services.filter((service) => {
      if (!service.permission) return false;
      if (service.isDeleted) return false;

      return service.name.toLowerCase().includes(filter.toLowerCase());
    });
  });

  const onSelectService = (service, isSelected) => {
    if (isSelected) {
      setSelectedServices(
        selectedServices.filter(
          (selectedService) => selectedService.serviceId !== service.serviceId,
        ),
      );
    } else {
      const user = service.users.find(
        (user) => user.userId === service.users[0],
      );
      const { startDuration, timeOffDuration, endDuration, price } =
        getDurationsAndPrice(service, user);

      setSelectedServices([
        ...selectedServices,
        {
          serviceId: service.serviceId,
          userId: null,
          users: [],
          resources: [],
          price,
          startDuration,
          timeOffDuration,
          endDuration,
          shiftWarning: service.shiftWarning,
          overlapWarning: service.overlapWarning,
          relationWarning: service.relationWarning,
          blockBeforeMins: service.blockBeforeMins,
          blockAfterMins: service.blockAfterMins,
        },
      ]);
    }
  };

  return (
    <Wrapper>
      <div className={"header"}>
        <Text fw={500} size={"lg"}>
          <Trans>Izberite storitev</Trans>
        </Text>
        <TextInput
          placeholder={t`Išči storitve`}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          icon={<RiSearch2Line />}
        />
      </div>
      <ServiceSelectList
        serviceTags={serviceTags}
        services={filteredServices}
        selectedServices={selectedServices}
        onSelectService={onSelectService}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .header {
    gap: 2rem;
    padding-bottom: 1rem;

    .mantine-InputWrapper-root {
      flex: 1;
      height: 100%;
    }
  }

  .service-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const ServiceSelectItem = styled.div`
  text-align: left;
  padding: 1rem 1rem;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.42, 0.65, 0.58, 0.95);
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid
    ${(props) => (props.serviceColor ? props.serviceColor : "black")};
  position: relative;

  .warning {
    color: #ff0000;
    flex: 1;
  }

  .name {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selectedCheck {
    position: absolute;
    right: 1rem;
    top: 23%;
    font-size: 1.2rem;
    color: ${(props) => (props.serviceColor ? props.serviceColor : "black")};
  }

  &:hover {
    background: ${(props) =>
      props.serviceColor ? props.serviceColor + "70" : "black"};
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 0.5rem 1rem;
    border-radius: 10px;

    .name {
      flex: 1;
      font-weight: normal;
    }
  }
`;

export default ServiceSelect;
