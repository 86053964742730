import { t } from "@lingui/core/macro";
import {
  LanguageSettingDrawer,
  LocalizedArray,
} from "@/Components/LanguageSettingsDrawer";
import { LimeColorPicker } from "@/Components/LimeColorPicker";
import {
  LimePageHeader,
  LimePageHeaderProps,
} from "@/Components/LimePageHeader";
import { useLimeAlertModal } from "@/Components/Modals/useLimeAlertModal";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeTextarea } from "@/Components/NextBase/LimeTextarea";
import { api } from "@/lib/api-client";
import usePreferredLanguageStore, {
  SUPPORTED_LANGUAGES,
} from "@/stores/usePreferredLanguageStore";
import { Color } from "@/types/colors";
import { Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { Button } from "@nextui-org/react";
import { Languages } from "lucide-react";
import { useEffect } from "react";
import { VscTrash } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

const formSchema = z.object({
  nameLocalized: z.array(
    z.object({
      language: z.enum(SUPPORTED_LANGUAGES),
      value: z.string(),
    }),
  ),
  descriptionLocalized: z.array(
    z.object({
      language: z.enum(SUPPORTED_LANGUAGES),
      value: z.string(),
    }),
  ),
  color: z.string().regex(/^#[0-9a-fA-F]{6}$/),
});

export const ManageProductCategory = () => {
  const { categoryId } = useParams();

  const navigate = useNavigate();

  // APIS
  const { mutateAsync: createCategory, processedErrorMessage } =
    api.product.usePostProductCategory();

  const { data: existingProductCategory, isLoadingError: isGetError } =
    api.product.useGetProductCategoryById(Number(categoryId));

  const { mutateAsync: updateCategory } = api.product.usePutProductCategory();

  const { mutateAsync: deleteCategory } =
    api.product.useDeleteProductCategoryById();

  //

  const [
    nameLocalizationDrawerOpened,
    { open: openNameLocalizationDrawer, close: closeNameLocalizationDrawer },
  ] = useDisclosure(false);
  const [
    descriptionLocalizationDrawerOpened,
    {
      open: openDescriptionLocalizationDrawer,
      close: closeDescriptionLocalizationDrawer,
    },
  ] = useDisclosure(false);

  const form = useForm<z.infer<typeof formSchema>>({
    initialValues: {
      nameLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        value: "",
      })),
      descriptionLocalized: SUPPORTED_LANGUAGES.map((language) => ({
        language,
        value: "",
      })),
      color: "#E9B2BF",
    },
  });

  useEffect(() => {
    if (existingProductCategory) {
      form.initialize({
        nameLocalized: existingProductCategory.category.nameLocalized.map(
          (item) => ({
            language: item.language,
            value: item.name,
          }),
        ),
        descriptionLocalized:
          existingProductCategory.category.descriptionLocalized.map((item) => ({
            language: item.language,
            value: item.description,
          })),
        color: existingProductCategory.category.color,
      });
    }
  }, [existingProductCategory]);

  useEffect(() => {
    if (isGetError) {
      navigate("/dashboard/products/categories/-1");
    }
  }, [isGetError]);

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    if (isEditing) {
      await updateCategory({
        categoryId: Number(categoryId),
        body: values,
      });

      notifications.show({
        message: t`Kategorija je bila posodobljena`,
        color: "green",
      });
      navigate("/dashboard/products/categories");
      return;
    }
    await createCategory(values);

    notifications.show({
      message: t`Kategorija je bila ustvarjena`,
      color: "green",
    });
    navigate("/dashboard/products/categories");
  };

  const isEditing = categoryId != "-1";

  const saveLocalizedNames = (localizedArray: LocalizedArray) => {
    form.setFieldValue("nameLocalized", localizedArray);
    closeNameLocalizationDrawer();
  };

  const saveLocalizedDescriptions = (localizedArray: LocalizedArray) => {
    form.setFieldValue("descriptionLocalized", localizedArray);
    closeDescriptionLocalizationDrawer();
  };

  const { alertModal } = useLimeAlertModal();

  const { preferredLanguage } = usePreferredLanguageStore((state) => state);
  const defaultLanguage =
    preferredLanguage.userPreferredLanguage ||
    preferredLanguage.clientPreferredLanguage ||
    "sl";

  const subPage: LimePageHeaderProps["subPage"] = {
    title: isEditing ? t`Uredi kategorijo` : t`Dodaj kategorijo`,
    onBackButtonClick: () => navigate("/dashboard/products/categories"),

    rightSection: isEditing
      ? {
          options: [
            {
              label: t`Izbriši produkt`,
              color: Color.Error,
              icon: <VscTrash />,
              onClick: async () => {
                if (isEditing) {
                  alertModal({
                    title: t`Ali ste prepričani, da želite izbrisati kategorijo?`,
                    onConfirm: async () => {
                      await deleteCategory(Number(categoryId));

                      notifications.show({
                        message: t`Kategorija je bila izbrisana`,
                        color: "green",
                      });

                      navigate("/dashboard/products/categories");
                    },
                    isDanger: true,
                  });
                }
              },
            },
          ],
        }
      : undefined,
  };

  const defaultLanguageLocalizedNameIndex = form.values.nameLocalized.findIndex(
    (name) => name.language === defaultLanguage,
  );

  const defaultLanguageLocalizedDescriptionIndex =
    form.values.descriptionLocalized.findIndex(
      (desc) => desc.language === defaultLanguage,
    );

  return (
    <>
      <LimePageHeader title={""} subPage={subPage}></LimePageHeader>

      <form
        onSubmit={form.onSubmit(handleSubmit)}
        className="mt-4 flex flex-col gap-4 px-4 pb-6 md:px-8"
      >
        <Box mt={"md"} maw={"600px"} mx={"auto"} pos={"relative"} w={"100%"}>
          <div className="mt-4 flex flex-col gap-4 px-4 pb-6">
            <LimeInput
              size="sm"
              label={t`Ime`}
              endContent={
                <Button
                  isIconOnly
                  className="bg-transparent pt-1.5"
                  onClick={openNameLocalizationDrawer}
                >
                  <Languages size={22} />
                </Button>
              }
              {...form.getInputProps(
                `nameLocalized.${defaultLanguageLocalizedNameIndex}.value`,
              )}
            ></LimeInput>
            <LanguageSettingDrawer
              localized={form.values.nameLocalized}
              opened={nameLocalizationDrawerOpened}
              save={saveLocalizedNames}
              cancel={closeNameLocalizationDrawer}
              inputLabel={t`Ime`}
            ></LanguageSettingDrawer>

            <LimeTextarea
              size="sm"
              label={t`Opis`}
              endContent={
                <Button
                  isIconOnly
                  className="bg-transparent pt-1.5"
                  onClick={openDescriptionLocalizationDrawer}
                >
                  <Languages size={22} />
                </Button>
              }
              {...form.getInputProps(
                `descriptionLocalized.${defaultLanguageLocalizedDescriptionIndex}.value`,
              )}
            ></LimeTextarea>
            <LanguageSettingDrawer
              localized={form.values.descriptionLocalized}
              opened={descriptionLocalizationDrawerOpened}
              save={saveLocalizedDescriptions}
              cancel={closeDescriptionLocalizationDrawer}
              inputLabel={t`Opis`}
            ></LanguageSettingDrawer>

            <LimeColorPicker
              {...form.getInputProps("color")}
              value={form.values.color}
              label={t`Barva`}
            ></LimeColorPicker>
          </div>

          <div className="fixed bottom-0 left-0 z-10 w-full max-w-full border-t-1 border-divider bg-background px-4 py-4 md:left-auto md:max-w-[600px] md:px-8">
            <LimeAlert
              color="danger"
              message={processedErrorMessage}
            ></LimeAlert>
            <Button className="w-full" type="submit" color="primary">
              Shrani
            </Button>
          </div>
        </Box>
      </form>
    </>
  );
};
