import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { api } from "@/lib/api-client";
import { TextVariant } from "@/types/text-variants";
import { Button, Flex, Select, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { TbArrowsRightLeft } from "react-icons/tb";
import { CreateStripeAccount } from "../../../../../server/src/types";

export const CreateAccount = () => {
  const businessTypeOptions = [
    { value: "individual", label: t`Samostojni podjetnik` },
    { value: "company", label: t`Družba z omejeno odgovornostjo` },
    { value: "non_profit", label: t`Ne profitna organizacija` },
  ];

  const [businessType, setBusinessType] =
    useState<CreateStripeAccount["body"]["businessType"]>("individual");

  const {
    mutate: createAccount,
    isPending: isCreatingAccount,
    isSuccess,
    data,
  } = api.stripe.useCreateStripeAccount();

  if (isSuccess) {
    window.location.replace(data.accountRefreshUrl);
  }

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <PageHeader title={t`Spletno plačevanje`} />

      <Flex
        align={"center"}
        justify={"center"}
        direction={"column"}
        px="xs"
        mt={"40px"}
      >
        <div style={{ flex: 1 }}></div>

        <Flex
          justify={"center"}
          align={"center"}
          bg="#F5F5F5"
          gap="lg"
          w={"100%"}
          maw={350}
          h="140px"
          style={{ borderRadius: "8px" }}
          mb="lg"
        >
          <img
            width={"36px"}
            height={"36px"}
            src="/Icons/app_logo_limetka.png"
            alt=""
          />
          <TbArrowsRightLeft />
          <img
            width={"36px"}
            height={"36px"}
            src="/Icons/stripe_icon.png"
            alt=""
          />
        </Flex>
        <Text variant={TextVariant.BodyEmphasized} mb={"xs"}>
          <Trans>Spletno plačevanje ni vzpostavljeno</Trans>
        </Text>
        <Text ta="center" variant={TextVariant.Body} maw={350}>
          <Trans>
            Za uporabo spletnega plačevanja morate povezati vaš uporabniški
            račun z aplikacijo za procesiranje plačil Stripe.
          </Trans>
        </Text>

        {isMobile && <div style={{ flex: 1 }}></div>}

        <Select
          data={businessTypeOptions}
          label={t`Tip podjetja`}
          placeholder={t`Izberite tip podjetja`}
          onChange={(value) => {
            if (!value) return;

            setBusinessType(
              value as CreateStripeAccount["body"]["businessType"],
            );
          }}
          value={businessType}
          w="100%"
          maw={350}
          allowDeselect={false}
        />
        <Button
          mt={"md"}
          onClick={() => createAccount({ businessType })}
          loading={isCreatingAccount}
          w="100%"
          maw={350}
          fw={500}
          rightSection={<HiOutlineExternalLink />}
        >
          <Trans>Povezovanje Stripe računa</Trans>
        </Button>
      </Flex>
    </>
  );
};
