import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  Divider,
  Flex,
  MultiSelect,
  Space,
  Switch,
  Text,
} from "@mantine/core";
import styled from "styled-components";

const getAllNotifications = () => {
  return [
    {
      label: t`Stranka se naroči na termin preko spleta`,
      emailKey: "insert_form_email",
      smsKey: "insert_form_sms",
    },
    {
      label: t`Stranka prenaroči termin preko spleta`,
      emailKey: "update_form_email",
      smsKey: "update_form_sms",
    },
    {
      label: t`Stranka odpove termin preko spleta`,
      emailKey: "delete_form_email",
      smsKey: "delete_form_sms",
    },
    {
      label: t`Termin je bil naročen preko koledarja`,
      emailKey: "insert_calendar_email",
      smsKey: "insert_calendar_sms",
    },
    {
      label: t`Termin je bil prenaročen prek koledarja`,
      emailKey: "update_calendar_email",
      smsKey: "update_calendar_sms",
    },
    {
      label: t`Termin je bil odpovedan preko koledarja`,
      emailKey: "delete_calendar_email",
      smsKey: "delete_calendar_sms",
    },
  ];
};

const ModalManageNotificationsView = ({
  isMobile,
  notifications,
  setIsManagingNotifications,
  setNotifications,
  isBackButton,
  setIsBackButton,
  locations,
  services,
  users,
  resources,
  isResourcesFeatureEnabled,
}) => {
  useLingui();
  const usersList = [
    ...users.map((user) => {
      return {
        label: user?.name + " " + user?.lastName,
        value: user.userId,
        locations: user.locations,
      };
    }),
  ];

  const allNotifications = getAllNotifications();

  return (
    <Wrapper>
      {allNotifications.map((notif) => {
        const emailValue = notifications[notif.emailKey];
        const smsValue = notifications[notif.smsKey];

        return (
          <div key={notif.label} style={{ marginBottom: "15px" }}>
            <Text fw={500} c={"#6C7B86"}>
              {notif.label}
            </Text>
            <Divider />
            <Space h={"0.5rem"} />
            <Flex justify={"space-between"}>
              <Text c={"#424B64"}>E-mail</Text>
              <Switch
                checked={emailValue}
                onChange={(e) => {
                  setNotifications({
                    ...notifications,
                    [notif.emailKey]: e.target.checked,
                  });
                }}
                size={"md"}
              />
            </Flex>
            <Space h={"0.5rem"} />
            <Flex justify={"space-between"}>
              <Text c={"#424B64"}>
                <Trans>SMS</Trans>
              </Text>
              <Switch
                checked={smsValue}
                onChange={(e) => {
                  setNotifications({
                    ...notifications,
                    [notif.smsKey]: e.target.checked,
                  });
                }}
                size={"md"}
              />
            </Flex>
          </div>
        );
      })}

      <AdditionalNotificationContent
        notifications={notifications}
        setNotifications={setNotifications}
        locations={locations}
        services={services}
        users={usersList}
        resources={resources}
        isResourcesFeatureEnabled={isResourcesFeatureEnabled}
      />

      <div
        style={{
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "flex-end",
          flexDirection: isMobile ? "column-reverse" : "row",
          alignItems: isMobile ? "initial" : "flex-end",
          paddingTop: "1rem",
        }}
      >
        <Button
          variant="white"
          onClick={() => {
            setIsManagingNotifications(false);
          }}
        >
          {isBackButton || !isMobile ? (
            <Trans>Nazaj</Trans>
          ) : (
            <Trans>Prekliči</Trans>
          )}
        </Button>
        <Button
          onClick={() => {
            setIsManagingNotifications(false);
            setIsBackButton(false);
          }}
        >
          <Trans>Nadaljuj</Trans>
        </Button>
      </div>
    </Wrapper>
  );
};

const AdditionalNotificationContent = ({
  notifications,
  setNotifications,
  locations,
  services,
  users,
  resources,
  isResourcesFeatureEnabled,
}) => {
  useLingui();

  // filter out users that are not in any of the locations
  if (notifications.all_locations === 0) {
    users = users.filter((u) => {
      const userLocations = u.locations;
      return userLocations.some((location) =>
        notifications.locations.includes(location),
      );
    });
  }

  const updateLocations = (locations) => {
    // delete all users that are not in any of the locations
    const newUser = notifications.users.filter((u) => {
      const userLocations = users.find((s) => s.value === u).locations;
      return userLocations.some((location) => locations.includes(location));
    });

    setNotifications({
      ...notifications,
      users: newUser,
      locations,
    });
  };

  return (
    <div style={{ marginTop: "40px" }}>
      <Flex justify={"space-between"}>
        <Text fw={600} c={"#424B64"}>
          <Trans>Dodatno obveščanje</Trans>
        </Text>
        <Switch
          checked={notifications.additional_notifications_enabled}
          onChange={(e) => {
            setNotifications({
              ...notifications,
              additional_notifications_enabled: e.target.checked,
            });
          }}
          size={"md"}
        />
      </Flex>
      <Text fw={500} size={".9rem"} c={"#6C7B86"}>
        <Trans>
          Uporabnik dobi obvestila za storitve, subjekte in lokacije po meri
        </Trans>
        .
      </Text>

      {notifications.additional_notifications_enabled && (
        <Flex mt={10} gap={10} direction={"column"}>
          <Divider />

          <Flex Flex justify={"space-between"} align={"flex-end"}>
            <Text inline span fw={500} size={".95rem"}>
              <Trans>Lokacije</Trans>
            </Text>
            <Button
              radius={"11px"}
              variant={"light"}
              color={
                notifications.locations.length === locations.length
                  ? "primaryColor"
                  : "gray"
              }
              onClick={() => {
                if (notifications.locations.length === locations.length) {
                  updateLocations([]);
                } else {
                  updateLocations(locations.map((location) => location.value));
                }
              }}
              size={"xs"}
            >
              <Trans>Izberi vse</Trans>
            </Button>
          </Flex>
          <MultiSelect
            data={locations.map((location) => {
              return {
                label: location.label,
                value: location.value.toString(),
              };
            })}
            value={notifications.locations.map((location) =>
              location.toString(),
            )}
            onChange={(vals) => {
              updateLocations(vals.map((v) => Number(v)));
            }}
            placeholder={t`Brez lokacije`}
          />

          <Flex Flex justify={"space-between"} align={"flex-end"}>
            <Text inline span fw={500} size={".95rem"}>
              <Trans>Zaposleni</Trans>
            </Text>
            <Button
              radius={"11px"}
              variant={"light"}
              color={
                notifications.users.length === users.length
                  ? "primaryColor"
                  : "gray"
              }
              onClick={() => {
                if (notifications.users.length === users.length) {
                  setNotifications({
                    ...notifications,
                    users: [],
                  });
                } else {
                  setNotifications({
                    ...notifications,
                    users: users.map((u) => {
                      return u.value;
                    }),
                  });
                }
              }}
              size={"xs"}
            >
              <Trans>Izberi vse</Trans>
            </Button>
          </Flex>
          <MultiSelect
            data={users.map((u) => {
              return {
                label: u.label,
                value: u.value.toString(),
              };
            })}
            value={notifications.users.map((u) => {
              return u.toString();
            })}
            onChange={(vals) => {
              setNotifications({
                ...notifications,
                users: vals.map((v) => Number(v)),
              });
            }}
            placeholder={t`Brez zaposlenih`}
          />

          <Flex Flex justify={"space-between"} align={"flex-end"}>
            <Text inline span fw={500} size={".95rem"}>
              <Trans>Storitve</Trans>
            </Text>
            <Button
              radius={"11px"}
              variant={"light"}
              color={
                notifications.services.length === services.length
                  ? "primaryColor"
                  : "gray"
              }
              onClick={() => {
                if (notifications.services.length === services.length) {
                  setNotifications({
                    ...notifications,
                    services: [],
                  });
                } else {
                  setNotifications({
                    ...notifications,
                    services: services.map((service) => {
                      return service.serviceId;
                    }),
                  });
                }
              }}
              size={"xs"}
            >
              <Trans>Izberi vse</Trans>
            </Button>
          </Flex>
          <MultiSelect
            data={services.map((service) => {
              return {
                label: service.name,
                value: service.serviceId.toString(),
              };
            })}
            value={notifications.services.map((service) => {
              return service.toString();
            })}
            onChange={(vals) => {
              setNotifications({
                ...notifications,
                services: vals.map((v) => Number(v)),
              });
            }}
            placeholder={t`Brez storitev`}
          />

          {isResourcesFeatureEnabled && (
            <>
              <Flex Flex justify={"space-between"} align={"flex-end"}>
                <Text inline span fw={500} size={".95rem"}>
                  <Trans>Sredstva</Trans>
                </Text>
                <Button
                  radius={"11px"}
                  variant={"light"}
                  color={
                    notifications.resources.length === resources.length
                      ? "primaryColor"
                      : "gray"
                  }
                  onClick={() => {
                    if (notifications.resources.length === resources.length) {
                      setNotifications({
                        ...notifications,
                        resources: [],
                      });
                    } else {
                      setNotifications({
                        ...notifications,
                        resources: resources.map((resource) => {
                          return resource.id;
                        }),
                      });
                    }
                  }}
                  size={"xs"}
                >
                  <Trans>Izberi vse</Trans>
                </Button>
              </Flex>
              <MultiSelect
                data={resources.map((resource) => {
                  return {
                    label: resource.label,
                    value: resource.id.toString(),
                  };
                })}
                value={notifications.resources.map((resource) =>
                  resource.toString(),
                )}
                onChange={(vals) => {
                  setNotifications({
                    ...notifications,
                    resources: vals.map((v) => Number(v)),
                  });
                }}
                placeholder={t`Brez sredstev`}
              />
            </>
          )}
        </Flex>
      )}
    </div>
  );
};

const Wrapper = styled.div`
  .mantine-MultiSelect-searchInput {
    font-size: 16px;
  }
`;

export default ModalManageNotificationsView;
