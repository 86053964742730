import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { api } from "@/lib/api-client";
import { useForm } from "@mantine/form";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useEffect } from "react";

type Form = {
  businessPremiseId: string;
  electronicDeviceId: string;
  startNumberingWith: number;
};

export const ManageElectronicDeviceModal = ({
  isManageModalOpen,
  onOpenCreateModalChange,
  electronicDevice_id,
}: {
  isManageModalOpen: boolean;
  onOpenCreateModalChange: (deviceId?: string) => void;
  electronicDevice_id?: string;
}) => {
  const { data: organization, isPending: isOrganizationPending } =
    api.taxRegister.useGetOrganization({});

  const {
    data: businessPremises,
    isPending: isBusinessPremisesPending,
    isSuccess: isBusinessPremisesSuccess,
  } = api.taxRegister.useGetOrganizationBusinessPremise({});

  const {
    data: electronicDevices,
    processedErrorMessage: electronicDevicesErrorMessage,
  } = api.taxRegister.useGetOrganizationElectronicDevice({});

  const {
    mutateAsync: createElectronicDevice,
    isPending: isCreatingElectronicDevice,
    processedErrorMessage: createErrorMessage,
  } = api.taxRegister.usePostOrganizationElectronicDevice();

  const {
    mutateAsync: updateElectronicDevice,
    isPending: isUpdatingElectronicDevice,
    processedErrorMessage: updateErrorMessage,
  } = api.taxRegister.useUpdateElectronicDevice();

  const form = useForm<Form>({
    initialValues: {
      businessPremiseId: "",
      electronicDeviceId: "",
      startNumberingWith: 1,
    },

    validate: {
      businessPremiseId: (value) =>
        value === "" ? t`Izberite poslovni prostor naprave` : null,
      electronicDeviceId: (value) => {
        if (value === "") return t`Izberite elektronsko napravo`;

        // Cant contain spaces or have leading 0
        if (!/^[0-9a-zA-Z]*$/.test(value)) {
          return t`Elektronska naprava lahko vsebuje samo črke in številke brez presledkov`;
        }
      },
    },
  });

  useEffect(() => {
    if (!electronicDevice_id || !isManageModalOpen) return;

    const existingElectronicDevice = electronicDevices?.find(
      (device) => device.id === electronicDevice_id,
    );

    if (!existingElectronicDevice) return;

    form.setValues({
      businessPremiseId: existingElectronicDevice.businessPremiseId,
      electronicDeviceId: existingElectronicDevice.electronicDeviceId,
      startNumberingWith: 1,
    });
  }, [electronicDevices, electronicDevice_id]);

  const handleSubmitForm = async (values: Form) => {
    const response = await (async () => {
      if (electronicDevice_id == null) {
        const response = await createElectronicDevice(values);
        return response;
      } else {
        const response = await updateElectronicDevice({
          electronicDeviceId: electronicDevice_id,
          body: values,
        });
        return response;
      }
    })();

    if (response != null) {
      onOpenCreateModalChange(response.id);

      form.reset();
    }
  };

  const fursStrategy = organization?._defaults.find(
    (d) => d.name === "furs_strategy",
  )?.value as string | undefined;

  return (
    <Modal
      isOpen={isManageModalOpen}
      onOpenChange={(isOpen) => {
        onOpenCreateModalChange();

        if (!isOpen) {
          form.reset();
        }
      }}
      scrollBehavior="outside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <form onSubmit={form.onSubmit(handleSubmitForm)}>
              <ModalHeader>
                {electronicDevice_id ? (
                  <Trans>Uredi elektronsko napravo</Trans>
                ) : (
                  <Trans>Dodaj elektronsko napravo</Trans>
                )}
              </ModalHeader>
              <ModalBody>
                <LimeAlert
                  color="danger"
                  message={electronicDevicesErrorMessage}
                />

                <LimeSelect
                  label={t`Pripada k poslovnem prostoru`}
                  isLoading={isBusinessPremisesPending}
                  isRequired
                  selectedKeys={[form.values.businessPremiseId]}
                  onChange={(value: string) => {
                    form.setFieldValue("businessPremiseId", value);
                  }}
                  items={
                    isBusinessPremisesSuccess
                      ? businessPremises.map((businessPremise) => ({
                          label: businessPremise.businessPremiseId,
                          key: businessPremise.id,
                        }))
                      : []
                  }
                />

                <LimeInput
                  label={t`Oznaka naprave`}
                  {...form.getInputProps("electronicDeviceId")}
                  isRequired
                />

                {fursStrategy == "B" ? (
                  <LimeInput
                    label={t`Začni številčenje z`}
                    {...form.getInputProps("startNumberingWith")}
                    type="number"
                  />
                ) : undefined}

                <LimeAlert color="danger" message={createErrorMessage} />
                <LimeAlert color="danger" message={updateErrorMessage} />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={onClose}
                  isDisabled={
                    isCreatingElectronicDevice || isUpdatingElectronicDevice
                  }
                >
                  <Trans>Prekliči</Trans>
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  isLoading={
                    isCreatingElectronicDevice || isUpdatingElectronicDevice
                  }
                >
                  {electronicDevice_id ? (
                    <Trans>Uredi</Trans>
                  ) : (
                    <Trans>Dodaj</Trans>
                  )}
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
